const ChartSkeleton = ({ addAxisRight }) => {
    return (<>
        <div className="row g-0 px-3 pt-1" style={{ height: 280 }}>
            <div className="col-6 d-flex align-items-center placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 px-3"></div>
                <div className="w-100 line ms-2"></div>
            </div>
            <div className="col-6 d-flex align-items-center placeholder-glow text-end mb-3">
                <div className="w-100 line me-2"></div>
                <div className={`placeholder c-grey-300 rounded-4 px-3 ${!addAxisRight && 'd-none'}`}></div>
            </div>
            <div className="col-6 d-flex align-items-center placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 px-3 "></div>
                <div className="w-100 line ms-2"></div>
            </div>
            <div className="col-6 d-flex align-items-center placeholder-glow text-end mb-3 ">
                <div className="w-100 line me-2"></div>
                <div className={`placeholder c-grey-300 rounded-4 px-3 ${!addAxisRight && 'd-none'}`}></div>
            </div>
            <div className="col-6 d-flex align-items-center placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 px-3"></div>
                <div className="w-100 line ms-2"></div>
            </div>
            <div className="col-6 d-flex align-items-center placeholder-glow text-end mb-3 ">
                <div className="w-100 line me-2"></div>
                <div className={`placeholder c-grey-300 rounded-4 px-3 ${!addAxisRight && 'd-none'}`}></div>
            </div>
            <div className="col-6 d-flex align-items-center placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 px-3"></div>
                <div className="w-100 line ms-2"></div>
            </div>
            <div className="col-6 d-flex align-items-center placeholder-glow text-end mb-3 ">
                <div className="w-100 line me-2"></div>
                <div className={`placeholder c-grey-300 rounded-4 px-3 ${!addAxisRight && 'd-none'}`}></div>
            </div>
            <div className="col-6 d-flex align-items-center placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 px-3"></div>
                <div className="w-100 line ms-2"></div>
            </div>
            <div className="col-6 d-flex align-items-center placeholder-glow text-end mb-3 ">
                <div className="w-100 line me-2"></div>
                <div className={`placeholder c-grey-300 rounded-4 px-3 ${!addAxisRight && 'd-none'}`}></div>
            </div>
            <div className="col placeholder-glow text-center">
                <span className="placeholder c-grey-300 rounded-4 w-25"></span>
            </div>
            <div className="col placeholder-glow text-center">
                <span className="placeholder c-grey-300 rounded-4 px-4"></span>
            </div>
            <div className="col placeholder-glow text-center">
                <span className="placeholder c-grey-300 rounded-4 px-4"></span>
            </div>
            <div className="col placeholder-glow text-center">
                <span className="placeholder c-grey-300 rounded-4 px-4"></span>
            </div>
            <div className="col placeholder-glow text-center">
                <span className="placeholder c-grey-300 rounded-4 px-4"></span>
            </div>
        </div>
    </>);
}

export default ChartSkeleton;