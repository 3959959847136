import { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import LineChart from "./LineChart";
import ExportFile from '../molecules/ExportFile';

import chartTheme from '../../helpers/constants/chartTheme';
import selectDate from '../../helpers/formatting/selectDate';
import formatLargeNumber from "../../helpers/formatting/formatLargeNumber";
import getYearTransitions from "../../helpers/formatting/getYearTransitions";
import getTooltipLocation from "../../helpers/responsive/getTooltipLocation";
import getTooltipDateFormat from "../../helpers/formatting/getTooltipDateFormat";
import calculateDaysBetween from "../../helpers/formatting/calculateDaysBetween";

const ComparisonLineChart = ({ data, isLoading, title, tooltip, lineKey1, lineKey2, exportFile }) => {
    const { t } = useTranslation();

    const labels1Ref = useRef(null);
    const labels2Ref = useRef(null);

    const emptyMin = 0;
    const emptyMax = 2500;
    const emptyGrid = [emptyMin, 500, 1000, 1500, 2000, emptyMax];

    const defaultMin = 0;
    const defaultMax = 5;
    const defaultGrid = [defaultMin, 1, 2, 3, 4, defaultMax];

    //Lines color codes  yellow     cyan
    const lineColors = ['#F1C85E', '#0B8ABD'];
    const [dataSet, setData] = useState([]);
    const [endDate, setEndDate] = useState();
    const [min, setMin] = useState(emptyMin);
    const [max, setMax] = useState(emptyMax);
    const [interval, setInterval] = useState();
    const [startDate, setStartDate] = useState();
    const [filteredDataSet, setFilteredData] = useState([]);
    const [gridYValues, setGridYValues] = useState(emptyGrid);
    const [yearTransitions, setYearTransitions] = useState([]);
    const [lineKeys, setLineKeys] = useState([lineKey1, lineKey2]);
    const [isChartEmpty, setChartEmpty] = useState(true)

    const generateIntervals = (min, max, count) => {
        const intervalSize = Math.ceil((max - min) / (count - 1));
        const result = [];
        for (let i = 0; i < count; i++)
            result.push(min + i * intervalSize);
        return result;
    }

    useEffect(() => {
        const dataList = [];
        const sum = data?.views?.reduce((sum, item) => sum + item?.totalViews, 0);

        setChartEmpty(sum === 0);
        if (!data?.views?.length || sum === 0) {
            dataList.push({
                id: lineKey1,
                color: lineColors[0],
                data: [],
                labelsRef: labels1Ref
            });
            dataList.push({
                id: lineKey2,
                color: lineColors[1],
                data: [],
                labelsRef: labels2Ref
            });
            setData(dataList);
            setFilteredData([]);
            return;
        }

        setStartDate(data?.views[0]?.intervalStart);
        setEndDate(data?.views[data.views.length - 1]?.intervalEnd);

        dataList.push({
            id: lineKey1,
            color: lineColors[0],
            data: data?.views.map((m, index) => ({
                "x": selectDate(m.intervalStart, m.intervalEnd, index === data?.views?.length - 1),
                "y": m.totalViews
            })),
            labelsRef: labels1Ref
        });
        dataList.push({
            id: lineKey2,
            color: lineColors[1],
            data: data?.views.map((m, index) => ({
                "x": selectDate(m.intervalStart, m.intervalEnd, index === data?.views?.length - 1),
                "y": m.uniqueViews
            })),
            labelsRef: labels2Ref
        });
        setData(dataList);
        setFilteredData(dataList);
        setLineKeys(dataList.map(d => d.id));
        setInterval(calculateDaysBetween(data?.views?.[0]?.intervalStart, data?.views?.[0]?.intervalEnd));
        setYearTransitions(getYearTransitions(data?.views).map(y => t('date:day_month_year', { date: new Date(y) })));

        const combinedArray = [...data?.views.map(v => v.totalViews), ...data?.views.map(v => v.uniqueViews)];
        const { smallestInt, largestInt } = combinedArray.reduce(
            (acc, obj) => ({
                smallestInt: Math.min(acc.smallestInt, obj),
                largestInt: Math.max(acc.largestInt, obj),
            }),
            { smallestInt: Number.MAX_SAFE_INTEGER, largestInt: Number.MIN_SAFE_INTEGER }
        );

        if (smallestInt !== Number.MAX_SAFE_INTEGER && largestInt !== Number.MIN_SAFE_INTEGER) {
            if (largestInt > 5) {
                const intervals = generateIntervals(smallestInt, largestInt, 5)
                setGridYValues(intervals);
                setMin(smallestInt);
                setMax(intervals[4]);
            } else {
                setGridYValues(defaultGrid);
                setMin(defaultMin);
                setMax(defaultMax);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const tooltipRender = (slice) => {
        if (!slice)
            return;

        let pointTotal = slice.points.find(p => p.serieId === lineKey1);
        let pointUnique = slice.points.find(p => p.serieId === lineKey2);
        let views = data?.views?.find(v =>
            v.intervalStart.getTime() === pointUnique?.data?.x?.getTime()
            || data?.views[data?.views.length - 1].intervalEnd.getTime() === v.intervalEnd.getTime()
        );

        const calcRange = getTooltipDateFormat(views, interval, yearTransitions);
        let dateRange;
        if (calcRange?.hours)
            dateRange = calcRange?.hours;
        else if (calcRange?.to)
            if (t('date:' + calcRange?.from?.[0], { date: new Date(calcRange.from?.[1]) }) === t('date:' + calcRange?.to?.[0], { date: new Date(calcRange.to?.[1]) }))
                dateRange = t('date:' + calcRange.from?.[0], { date: new Date(calcRange.from?.[1]) });
            else
                dateRange = t('date:' + calcRange.from?.[0], { date: new Date(calcRange.from?.[1]) }) + " - " + t('date:' + calcRange.to?.[0], { date: new Date(calcRange.to[1]) });
        else
            dateRange = t('date:' + calcRange.from?.[0], { date: new Date(calcRange.from?.[1]) });

        return (
            <div className="bc-white border-radius box-shadow chart-tooltip-md">
                <div className="p-4">
                    <div className="c-grey-800 text-sm">{dateRange}</div>
                    {lineKeys.some(k => k === lineKey1) &&
                        <div className="d-flex justify-content-between">
                            <span>
                                <span className="dot" style={{ backgroundColor: pointTotal?.serieColor }}></span>
                                <span className='ps-2'>{t(lineKey1)}</span>
                            </span>
                            <span>{pointTotal?.data?.y?.toLocaleString("en-US") ?? 0}</span>
                        </div>
                    }
                    {lineKeys.some(k => k === lineKey2) &&
                        <div className="d-flex justify-content-between">
                            <span>
                                <span className="dot" style={{ backgroundColor: pointUnique?.serieColor }}></span>
                                <span className='ps-2'>{t(lineKey2)}</span>
                            </span>
                            <span>{pointUnique?.data?.y?.toLocaleString("en-US") ?? 0}</span>
                        </div>
                    }
                </div>
            </div>
        );
    }

    const handleChange = (id) => {
        if (lineKeys.includes(id)) {
            if (lineKeys.length === 1 && dataSet?.[0])
                setFilteredData([{
                    ...dataSet[0],
                    data: dataSet[0]?.data?.map(item => ({ ...item, y: null }))
                }]);
            else
                setFilteredData(prev => [...prev?.filter(d => d.id !== id)]);

            setLineKeys(prev => [...prev?.filter(x => x !== id)]);
        }
        else {
            setLineKeys(prev => [...prev, id]);
            if (dataSet?.[0]?.data?.length > 0)
                setFilteredData(dataSet?.filter(d => [...lineKeys, id]?.includes(d.id)));
        }
    };

    const exportChart = (format) => {
        if (!lineKeys.includes(dataSet?.[0]?.id)) labels1Ref?.current.classList.add('d-none');
        if (!lineKeys.includes(dataSet?.[1]?.id)) labels2Ref?.current.classList.add('d-none');

        exportFile(format);
        setTimeout(() => {
            if (!lineKeys.includes(dataSet?.[0]?.id)) labels1Ref?.current.classList.remove('d-none');
            if (!lineKeys.includes(dataSet?.[1]?.id)) labels2Ref?.current.classList.remove('d-none');
        }, 1);
    }

    return (<>
        <div className="bc-white p-4 rounded-4 exportable" style={{ maxWidth: '100%' }}>
            <div className="row g-0">
                <div className="col-12 col-xxl-4 d-flex align-items-center justify-content-between" style={{ height: 40 }}>
                    {!isLoading &&
                        <>
                            <div className="text-nowrap">
                                <span className="fw-semi-bold">{title}</span>
                                <img
                                    data-html2canvas-ignore
                                    alt="info-circle"
                                    style={{ height: 24 }}
                                    className="px-1 tooltip-anchor-title"
                                    src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                                <Tooltip className="text-wrap" anchorSelect={`.tooltip-anchor-title`} place={getTooltipLocation()}>
                                    {tooltip}
                                </Tooltip>
                            </div>
                            {!isChartEmpty &&
                                <span data-html2canvas-ignore className="d-xxl-none">
                                    <ExportFile
                                        exportFile={exportChart} />
                                </span>
                            }
                        </>
                    }
                    {
                        isLoading &&
                        <div className="d-flex align-items-center placeholder-glow w-50">
                            <div className="placeholder w-100 c-grey-300 rounded-4 px-3 me-3"></div>
                        </div>
                    }
                </div>

                {
                    !isLoading && filteredDataSet?.length > 0 &&
                    <div className="col-12 col-xxl-8">
                        <div className="d-md-flex align-items-center justify-content-xxl-end">
                            {dataSet?.map((label) =>
                            (
                                <div ref={label.labelsRef} className="d-flex align-items-center py-2 py-xl-0  me-4 me-xl-0" key={label.id}>
                                    <div className="dot me-2 ms-xl-2" style={{ backgroundColor: label.color }}></div>
                                    <span className="c-black-100 fw-light">{t(label.id)}</span>
                                    <div className="form-check ms-2">
                                        <input
                                            data-html2canvas-ignore
                                            className="form-check-input checkbox"
                                            onChange={() => handleChange(label.id)}
                                            type="checkbox"
                                            checked={lineKeys.includes(label.id)}
                                        />
                                    </div>
                                </div>
                            ))}
                            <span data-html2canvas-ignore className="d-none d-xxl-block">
                                <ExportFile
                                    exportFile={exportChart} />
                            </span>
                        </div>
                    </div>
                }
                <div className="col-12 c-black-100 d-none exportable-label">
                    {t('date:day_month_year', { date: new Date(data?.views?.[0]?.intervalStart) })}
                    <span> - </span>
                    {t('date:day_month_year', { date: new Date(data?.views?.[data?.views?.length - 1]?.intervalEnd) })}
                </div>
            </div>

            <LineChart
                theme={chartTheme}
                filteredDataSet={filteredDataSet}
                isLoading={isLoading}
                startDate={startDate}
                endDate={endDate}
                min={min}
                max={max}
                gridYValues={gridYValues}
                tooltipRender={tooltipRender}
                interval={interval}
                yearTransitions={yearTransitions}
                axisLeft={{
                    yFormat: ">-.2f",
                    tickSize: 0,
                    tickPadding: 8,
                    tickRotation: 0,
                    tickValues: gridYValues,
                    legend: '',
                    format: function (value) {
                        return formatLargeNumber(value);
                    }
                }} />

            {
                !isLoading && filteredDataSet?.length === 0 &&
                <>
                    <div className="position-relative" style={{ height: 280 }}>
                        <div
                            className="d-flex flex-column align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
                            <img
                                alt="no-results-found"
                                src={`${process.env.PUBLIC_URL}/images/empty/line.svg`} />
                            <span className="d-block text-md py-2 fw-bold">{t("common:no_info")}</span>
                        </div>
                    </div>
                </>
            }

        </div >
    </>
    );
}

export default ComparisonLineChart;