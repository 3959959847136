const getYearTransitions = (data) => {
    if(!data || data.length === 0) return [];
    let dates = data.map(d => new Date(d?.intervalStart));
    dates.push(new Date(data[data[0]?.data?.length - 1]?.intervalEnd));
    let currentYear = dates[0].getFullYear();
    let result = [];
    dates.forEach(e => {
        if(e.getFullYear() > currentYear)
        {
            result.push(e);
            currentYear = e.getFullYear();
        }
    });
    return result;
}
    
export default getYearTransitions; 