function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
        return str;
    }

    const startLength = Math.ceil(maxLength / 2) - 2;
    const endLength = maxLength - startLength - 3;

    const start = str.substring(0, startLength);
    const end = str.substring(str.length - endLength);

    return `${start}...${end}`;
}

export default truncateString;