import { useTooltip } from "@nivo/tooltip";
import { useTranslation } from "react-i18next";

import CustomTooltipRow from "./CustomTooltipRow";
import { BAR_LINE_CHART_ALPHA } from "../../helpers/constants/constants";

const CustomTooltipLayer = ({ bars, xScale, yScale, margin }) => {
    const { t } = useTranslation(['email']);
    const { showTooltipAt, hideTooltip } = useTooltip();

    const handleMouseEnter = (bar) => {
        showTooltipAt(
            <div className="bc-white border-radius p-3 chart-tooltip-lg" style={{ boxShadow: '0px 0px 24px 0px rgba(26, 38, 63, 0.10)' }}>
                <div className="row">
                    <div className="col-12 fw-light">{bar.data.data.v5}</div>
                    <div className="col-2"><img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/chart.svg`} /></div>
                    <div className="col-6 text-nowrap">{t('open_count')}</div>
                    <div className="col-4 text-end"> {bar.data.data?.v6?.toLocaleString("en-US") ?? 0}</div>
                    <div className="col-2"><img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/diagram.svg`} /></div>
                    <div className="col-6 text-nowrap">{t('open_rate')}</div>
                    <div className="col-4 text-end">{((Math.floor(bar.data.data.v4 - BAR_LINE_CHART_ALPHA)).toFixed() ?? 0) + '%'}</div>
                    <CustomTooltipRow
                        color={bar.data.data?.v0c}
                        name={bar.data.data?.v0n}
                        count={bar.data.data?.v0 ?? 0}
                        date={bar.data.data?.v0r}
                    />
                    <CustomTooltipRow
                        color={bar.data.data?.v1c}
                        name={bar.data.data?.v1n}
                        count={bar.data.data?.v1 ?? 0}
                        date={bar.data.data?.v1r}
                    />
                    <CustomTooltipRow
                        color={bar.data.data?.v2c}
                        name={bar.data.data?.v2n}
                        count={bar.data.data?.v2 ?? 0}
                        date={bar.data.data?.v2r}
                    />
                    <CustomTooltipRow
                        color={bar.data.data?.v3c}
                        name={bar.data.data?.v3n}
                        count={bar.data.data?.v3 ?? 0}
                        date={bar.data.data?.v3r}
                    />
                </div>
            </div>,
            getPosition(bar, margin),
            'top'
        )
    }

    const getPosition = (bar, margin) => {
        if (bar.data.data?.position?.first)
            return [bar.x + margin.left + 100, bar.y + margin.top];
        if (bar.data.data?.position?.second)
            return [bar.x + margin.left + 50, bar.y + margin.top]
        if (bar.data.data?.position?.last)
            return [bar.x + margin.left - 60, bar.y + margin.top]
        return [bar.x + margin.left, bar.y + margin.top]
    }

    const handleMouseLeave = () => {
        hideTooltip()
    }

    return (
        <>
            {bars.map((bar, idx) => (
                <rect
                    key={idx}
                    x={xScale(bar.data.indexValue)}
                    y={0}
                    width={bar.width}
                    height={margin.top + margin.bottom + yScale(0)}
                    fill="transparent"
                    onMouseEnter={() => handleMouseEnter(bar)}
                    onMouseLeave={handleMouseLeave}
                />
            ))}
        </>
    )
}
export default CustomTooltipLayer;