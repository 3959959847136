import axios from "axios";
import qs from "qs";

function WavesService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const get = (selectedCustomerIds) => axios
        .get(`/waves?${qs.stringify({ ids: [...selectedCustomerIds] })}`, config())
        .catch(error => error);

    const getEvents = (campaignId, waveId, interval, eventTypeId, range, unique) => axios
        .get('/waves/events/', {
            params: {
                campaignId: campaignId,
                waveId: waveId,
                interval: interval,
                eventTypeId: eventTypeId,
                range: range,
                unique: unique
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    const getOpensByLocation = (campaignId, waveId, interval, unique) => axios
        .get('/waves/events/location-opens', {
            params: {
                campaignId: campaignId,
                waveId: waveId,
                interval: interval,
                unique: unique
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    return {
        init,
        dispose,
        get,
        getEvents,
        getOpensByLocation
    };
}

export default WavesService;