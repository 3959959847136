import { useState } from "react";
import { Modal } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from "react-i18next";

import { JPEG, PNG, CSV } from "../../helpers/constants/constants";

const ExportFile = ({ exportFile, csvEnabled }) => {
    const { t } = useTranslation(['common']);

    const [show, setShow] = useState(false);

    const [selectedOption, setSelectedOption] = useState("");

    const selectOption = (option) => {
        setSelectedOption(option);
        exportFile(option);
        setTimeout(() => { setShow(false); setSelectedOption(""); }, 200)
    }

    return (
        <Dropdown autoClose={false} show={show}>
            <Dropdown.Toggle className="border-0 btn-link bg-transparent text-decoration-none p-0">
                <div
                    onClick={() => setShow(true)}
                    className="btn-transparent d-flex align-items-center px-0 px-md-3 py-2">
                    <img
                        alt="download"
                        className="pe-md-2"
                        src={csvEnabled
                            ? `${process.env.PUBLIC_URL}/images/icons/export.svg`
                            : `${process.env.PUBLIC_URL}/images/icons/image-download.svg`} />
                    <span className="d-none d-lg-block">{t('export')}</span>
                </div>
            </Dropdown.Toggle>
            <Modal show={show} onHide={() => setShow(false)}>
                <Dropdown.Menu className="rounded-4 dropdown-menu-width p-4">
                    <div>
                        <h6 className="mb-4 fw-semi-bold">{t("selected_file_format")}</h6>
                        <Dropdown.Item
                            disabled={!!selectedOption}
                            className="bg-transparent text-decoration-none modal-link p-0"
                            onClick={() => { selectOption(PNG) }}>
                            <div className="d-flex">
                                <div className="row align-items-center mb-3 g-0 px-3 list-item">
                                    <div className="col-2">
                                        <img alt={PNG} src={`${process.env.PUBLIC_URL}/images/png-image.svg`} />
                                    </div>
                                    <div className="col-9 py-3 ps-3 modal-link">
                                        <h6 className="fw-semi-bold">{PNG}</h6>
                                        <div className="text-wrap fw-light pe-2">
                                            {t("png_format_message")}
                                        </div>
                                    </div>
                                    <div className="d-flex col-1">
                                        <div className="d-flex align-items-center justify-content-center form-check">
                                            <input
                                                className={`form-check-input ${selectedOption === PNG && 'selected'}`}
                                                type="radio"
                                                name="fileFormat"
                                                id={PNG}
                                            />
                                            <label className="form-check-label" htmlFor={PNG}></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                            className="bg-transparent text-decoration-none modal-link p-0"
                            disabled={!!selectedOption}
                            onClick={() => { selectOption(JPEG) }}>
                            <div className="d-flex">
                                <div className="row align-items-lg-center g-0 px-3 list-item">
                                    <div className="col-2">
                                        <img alt={JPEG} src={`${process.env.PUBLIC_URL}/images/jpeg-image.svg`} />
                                    </div>
                                    <div className="col-9 py-2 ps-3 modal-link">
                                        <h6 className="fw-semi-bold">{JPEG}</h6>
                                        <div className="text-wrap fw-light pe-2">
                                            {t("jpeg_format_message")}
                                        </div>
                                    </div>
                                    <div className="d-flex col-1">
                                        <div className="d-flex align-items-center justify-content-center form-check">
                                            <input
                                                className={`form-check-input ${selectedOption === JPEG && 'selected'}`}
                                                type="radio"
                                                name="fileFormat"
                                                id={JPEG}
                                            />
                                            <label className="form-check-label" htmlFor={JPEG}></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dropdown.Item>
                        {csvEnabled &&
                            <Dropdown.Item
                                disabled={!!selectedOption}
                                className="bg-transparent text-decoration-none modal-link mt-3 p-0"
                                onClick={() => { selectOption(CSV) }}>
                                <div className="d-flex">
                                    <div className="row align-items-lg-center g-0 px-3 list-item">
                                        <div className="col-2 d-flex justify-content-center">
                                            <img alt={CSV} src={`${process.env.PUBLIC_URL}/images/csv-icon.svg`} />
                                        </div>
                                        <div className="col-9 py-2 ps-3 modal-link">
                                            <h6 className="fw-semi-bold">{CSV}</h6>
                                            <div className="text-wrap fw-light pe-2">
                                                {t("csv_format_message")}
                                            </div>
                                        </div>
                                        <div className="d-flex col-1">
                                            <div className="d-flex align-items-center justify-content-center form-check">
                                                <input
                                                    className={`form-check-input ${selectedOption === CSV && 'selected'}`}
                                                    type="radio"
                                                    name="fileFormat"
                                                    id={CSV}
                                                />
                                                <label className="form-check-label" htmlFor={CSV}></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dropdown.Item>
                        }
                    </div>
                </Dropdown.Menu>
            </Modal>
        </Dropdown >
    );
}

export default ExportFile;