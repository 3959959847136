import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveBar } from "@nivo/bar";
import { Tooltip } from "react-tooltip";

import ExportFile from '../molecules/ExportFile';
import ChartSkeleton from "../atoms/ChartSkeleton";
import getTooltipLocation from "../../helpers/responsive/getTooltipLocation";
import generateIntervals from "../../helpers/formatting/generateIntervals";

const BarChart = ({ data, headerText, index, keys, colors, name, description, tooltipDescription, isLoading, exportFile, currentRange }) => {
    const { t } = useTranslation('video');

    const labels1Ref = useRef(null);
    const labels2Ref = useRef(null);

    const [barKeys, setBarKeys] = useState(keys);
    const [ticks, setTicks] = useState([]);
    const [labels, setLabels] = useState([]);
    const [maxValue, setMaxValue] = useState(20);
    const [isChartEmpty, setChartEmpty] = useState(true)

    const chartTheme = {
        fontSize: '14px',
        fontFamily: 'Source Sans Pro',
        axis: {
            ticks: {
                line: {
                    strokeWidth: '0'
                },
                text: {
                    fontSize: '14px',
                    fontWeight: 300,
                    fill: '#1C1C1C'
                }
            }
        },
        grid: {
            line: {
                strokeWidth: '1px',
                stroke: '#F6F5F8'
            }
        },
        tooltip: { container: { position: "fixed" } }
    };

    useEffect(() => {
        if (!data?.length) return;
        setChartEmpty(data?.reduce((sum, item) => sum + item?.views, 0) === 0);

        let min = 0;
        let max = 0;

        data?.forEach((item) => {
            if (item.views > max) max = item.views;
            if (item.uniqueViews > max) max = item.uniqueViews;
        });

        if (max === 0) max = 50;

        let innerTicks = generateIntervals([min, max]);

        setTicks(innerTicks);

        setLabels([{
            key: 'views', value: 'total_views', color: '#F1C85E', labelsRef: labels1Ref
        }, {
            key: 'uniqueViews', value: 'unique_views', color: '#0B8ABD', labelsRef: labels2Ref
        }]);

        setTimeout(() => setMaxValue(innerTicks[innerTicks.length - 1]), 400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const handleChange = (label) => {
        let newArray = barKeys;
        if (barKeys.includes(label.key)) {
            newArray = barKeys.filter(key => key !== label.key);
        } else {
            newArray = [...newArray, label.key];
        }

        if (newArray.length > 1) {
            newArray = keys.map(key => newArray.find(k => k === key));
        }

        setBarKeys(newArray);
    };

    const getPadding = () => {
        if (barKeys.length === 2) {
            return 0.3;
        }
        else return 0.2;
    }

    const exportChart = (format) => {
        if (!barKeys.includes('views')) labels1Ref?.current.classList.add('d-none');
        if (!barKeys.includes('uniqueViews')) labels2Ref?.current.classList.add('d-none');

        exportFile(format);
        setTimeout(() => {
            if (!barKeys.includes('views')) labels1Ref?.current.classList.remove('d-none');
            if (!barKeys.includes('uniqueViews')) labels2Ref?.current.classList.remove('d-none');
        }, 1);
    }

    return (
        <>
            <div className="bc-white p-4 rounded-4 exportable">
                <div className="row g-0">
                    <div className="col-12 col-xxl-4 d-flex align-items-center justify-content-between" style={{ height: 40 }}>
                        {!isLoading &&
                            <>
                                <div>
                                    <span className="fw-semi-bold text-nowrap">{headerText}</span>
                                    <img
                                        data-html2canvas-ignore
                                        alt="info-circle"
                                        style={{ height: 24 }}
                                        className={`px-1 tooltip-anchor-${name}`}
                                        src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                                    <Tooltip anchorSelect={`.tooltip-anchor-${name}`} place={getTooltipLocation()}>{description}</Tooltip>
                                </div>
                                {data?.length > 0 && !isChartEmpty &&
                                    <span className="d-xxl-none" data-html2canvas-ignore>
                                        <ExportFile
                                            exportFile={exportChart} />
                                    </span>
                                }
                            </>
                        }
                        {
                            isLoading &&
                            <div className="d-flex align-items-center placeholder-glow w-50">
                                <div className="placeholder w-100 c-grey-300 rounded-4 px-3 me-3"></div>
                            </div>
                        }
                    </div>
                    {!isLoading && data?.length > 0 && !isChartEmpty &&
                        <div className="col-12 col-xxl-8">
                            <div className="d-md-flex align-items-center justify-content-xxl-end">
                                {labels?.map((label) =>
                                (
                                    <div ref={label.labelsRef}
                                        className="d-flex align-items-center py-2 py-lx-0 me-4 me-xl-0"
                                        key={label.key}>
                                        <div className="dot me-2 ms-md-2" style={{ backgroundColor: label.color }}></div>
                                        <span className="c-black-100 fw-light">{t(label.value)}</span>
                                        <div className="form-check ms-2">
                                            <input ref={label.checkboxRef}
                                                data-html2canvas-ignore
                                                className="form-check-input checkbox"
                                                onChange={() => handleChange(label)}
                                                type="checkbox"
                                                checked={barKeys.includes(label.key)} />
                                        </div>
                                    </div>
                                ))}
                                <span className="d-none d-xxl-block" data-html2canvas-ignore>
                                    <ExportFile
                                        exportFile={exportChart} />
                                </span>
                            </div>
                        </div>
                    }
                    <div className="col-12 c-black-100 exportable-label d-none">
                        {currentRange}
                    </div>
                </div>

                <div style={{ position: 'relative' }}>
                    <div style={{ height: '300px' }}>
                        {!isLoading && data?.length > 0 && !isChartEmpty &&
                            <ResponsiveBar
                                data={data}
                                keys={barKeys}
                                indexBy={index}
                                margin={{ top: 30, right: 0, bottom: 40, left: 34 }}
                                padding={getPadding()}
                                valueScale={{
                                    type: 'linear',
                                    nice: true,
                                    clamp: true,
                                    min: 0,
                                    max: maxValue
                                }}
                                indexScale={{ type: 'band', round: true }}
                                animate={true}
                                innerPadding={10}
                                reverse={false}
                                tooltip={(input) => {
                                    return (
                                        <div className="bc-white border-radius p-4 chart-tooltip-md"
                                            style={{ boxShadow: '0px 0px 24px 0px rgba(26, 38, 63, 0.10)', minWidth: 230 }}>
                                            <div className="d-flex align-items-center mb-2">
                                                <span className="fw-bold c-black-100">
                                                    {input?.indexValue}&nbsp;{tooltipDescription}
                                                </span>
                                            </div>

                                            {
                                                barKeys.map((barKey) => {
                                                    return (
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <div className="dot me-2" style={{ backgroundColor: labels.find(label => label.key === barKey)?.color }}></div>
                                                                <div>{t(labels.find(label => label.key === barKey)?.value)}</div>
                                                            </div>

                                                            <span className="c-grey-800 ps-2">
                                                                {input.data?.[barKey]}
                                                            </span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    )
                                }}
                                theme={chartTheme}
                                groupMode="grouped"
                                enableLabel={false}
                                enableGridY={true}
                                layout="vertical"
                                borderWidth={0}
                                colors={colors}
                                gridYValues={ticks}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 0,
                                    tickPadding: 8,
                                    tickRotation: 0,
                                    legend: '',
                                }}
                                axisLeft={{
                                    tickSize: 0,
                                    tickValues: ticks,
                                    tickPadding: 8,
                                    tickRotation: 0,
                                    legend: '',
                                }}
                                layers={[
                                    "grid",
                                    "axes",
                                    "bars",
                                    "markers",
                                    "legends",
                                    "annotations"
                                ]}
                            />
                        }
                        {
                            !isLoading && (data?.length === 0 || isChartEmpty) &&
                            <>
                                <div className="position-relative" style={{ height: 300 }}>
                                    <div
                                        className="d-flex flex-column align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
                                        <img
                                            alt="no-results-found"
                                            src={`${process.env.PUBLIC_URL}/images/empty/bar.svg`} />
                                        <span className="d-block text-md py-2 fw-bold">{t("common:no_info")}</span>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            isLoading && <ChartSkeleton />
                        }
                    </div>
                </div>
            </div >
        </>
    );

}

export default BarChart;