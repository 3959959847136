import { useState, useEffect } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { line } from "d3-shape";
import * as d3 from "d3-shape";
import { ResponsiveBar } from "@nivo/bar";

import CustomTooltipLayer from "../molecules/CustomTooltipLayer";

import chartTheme from "../../helpers/constants/chartTheme";
import calcLinePosition from "../../helpers/calculations/calcLinePosition";
import formatLargeNumber from "../../helpers/formatting/formatLargeNumber";
import {
    BAR_LINE_CHART_BAR_COLORS,
    BAR_LINE_CHART_LINE_COLOR,
    MAX_MOBILE_SCREEN_WIDTH,
    BAR_LINE_CHART_ALPHA
} from "../../helpers/constants/constants";

const BarLineChart = ({ data, scale, min, max, gridValues, interval }) => {
    const { t } = useTranslation(['email']);
    const [showMobileTicks, setShowMobileTicks] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', handleScreenWidth);
        handleScreenWidth();
        return () => {
            document.removeEventListener('resize', handleScreenWidth);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const handleScreenWidth = () => {
        if (window.innerWidth < MAX_MOBILE_SCREEN_WIDTH)
            return setShowMobileTicks(true);
        setShowMobileTicks(false);
    }

    const Line = ({ bars, xScale, yScale }) => {
        bars = bars.filter(b => b.key.includes('v0'));
        const lineGenerator = line()
            .x(bar => calcLinePosition(bar.data.indexValue, bar.width, bar.data.index, data.length, xScale))
            .y(bar => yScale(bar.data.data.v4 * scale))
            .curve(d3.curveCatmullRom.alpha(0.5));

        return (
            <Fragment>
                <path
                    d={lineGenerator(bars)}
                    fill="none"
                    stroke={BAR_LINE_CHART_LINE_COLOR}
                    strokeWidth={3}
                    style={{ pointerEvents: "none" }}
                />
            </Fragment>
        );
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ height: '280px' }}>
                <ResponsiveBar
                    data={data}
                    margin={{ top: 30, right: 44, bottom: 40, left: 45 }}
                    style={{ maxWidth: '100%' }}
                    keys={["v0", "v1", "v2", "v3"]}
                    maxValue={max * BAR_LINE_CHART_ALPHA}
                    padding={0.5}
                    indexBy="x"
                    enableLabel={false}
                    colors={BAR_LINE_CHART_BAR_COLORS}
                    borderRadius={0}
                    gridYValues={gridValues}
                    theme={chartTheme}
                    axisLeft={{
                        min: min,
                        max: max,
                        yFormat: ">-.2f",
                        tickSize: 0,
                        tickPadding: 8,
                        tickRotation: 0,
                        tickValues: gridValues,
                        legend: '',
                        format: function (value) {
                            return formatLargeNumber(value);
                        }
                    }}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 20,
                        tickRotation: 0,
                        legend: '',
                        legendOffset: -40,
                        legendPosition: 'middle',
                        tickValues: showMobileTicks && data?.length > 0 ? [data[0]?.x, data[data?.length - 1]?.x] : null,
                        format: function (value) {
                            let pointsCount = Math.floor(interval.value / interval.range) * interval.range + 1;

                            if (pointsCount === +value) {
                                value = interval.value;
                            }

                            if (value === "9 PM") {
                                value = "12 AM";
                            }

                            return interval.value > 1 ? `${t('common:day')} ${value}` : value;
                        }
                    }}
                    axisRight={{
                        min: min,
                        max: max,
                        yFormat: ">-.2f",
                        tickSize: 0,
                        tickPadding: 8,
                        tickRotation: 0,
                        legend: '',
                        tickValues: gridValues,
                        legendOffset: 0,
                        format: function (value) {
                            return Math.round(value / scale) + '%';
                        }
                    }}
                    defs={[
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: BAR_LINE_CHART_BAR_COLORS[0],
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        },
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: BAR_LINE_CHART_BAR_COLORS[2],
                            size: 4,
                            padding: 1,
                            stagger: true
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'v1'
                            },
                            id: 'lines'
                        },
                        {
                            match: {
                                id: 'v3'
                            },
                            id: 'dots'
                        },
                    ]}
                    layers={["grid", "axes", "bars", Line, CustomTooltipLayer, "markers", "legends"]}//
                />
            </div>
        </div>
    );
}

export default BarLineChart;