export const defaultState = [
    {
        "percent": "10%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "20%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "30%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "40%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "50%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "60%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "70%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "80%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "90%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "100%",
        "views": 0,
        "uniqueViews": 0
    }
];

export const defaultStateMobile = [
    {
        "percent": "10%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "30%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "60%",
        "views": 0,
        "uniqueViews": 0
    },
    {
        "percent": "100%",
        "views": 0,
        "uniqueViews": 0
    }
];