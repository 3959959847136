import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useEffect, useState } from "react";

import AuthHandler from "../services/common/auth-handler";
import Settings from "../components/organisms/Settings";
import LanguageSelector from "../components/molecules/LanguageSelector";
import { LANGUAGE_KEY } from "../helpers/constants/constants";

const DEFAULT_ROUTE = '/';
const LANDING_REPORTING_ROUTE = '/web-reporting';
const VIDEO_REPORTING_ROUTE = '/video-reporting';
const ENGLISH_LANGUAGE = 'en';

const authHandler = AuthHandler();

const Header = ({ showLoading }) => {
    const { t, i18n } = useTranslation('header');
    const location = useLocation();
    const navigate = useNavigate();

    const [showToggle, setShowToggle] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem(LANGUAGE_KEY) || "en");

    useEffect(() => {
        let language = localStorage.getItem(LANGUAGE_KEY);
        if (!language) setSelectedLanguage(authHandler.getCurrentLanguage() || "en");
        if (language) {
            setSelectedLanguage(language);
        }
    }, [])

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem(LANGUAGE_KEY, selectedLanguage);
    }, [i18n, selectedLanguage])

    const changeLanguageHandler = (lang) => {
        setSelectedLanguage(lang);
    }

    useEffect(() => {
        if (showLoading) setAnimation('fade-in');
    }, [showLoading])

    const [animation, setAnimation] = useState('');

    const toggle = () => {
        // if (showToggle) setShowToggle(!showToggle);
    }

    return (
        <Navbar className={`${showToggle ? 'show' : ''} p-2`} expand="lg">
            <div className="d-flex justify-content-between header-width py-1" >
                <Navbar.Brand onClick={() => navigate('', { replace: false })}>
                    <img
                        alt="dcm-logo"
                        role="button"
                        className="ps-4"
                        style={{ height: 33 }}
                        src={`${process.env.PUBLIC_URL}/images/logo.svg`} />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setShowToggle(!showToggle)} className="border-0" />

            </div>
            <Navbar.Collapse className="mt-3 mt-md-0 justify-content-between" id="basic-navbar-nav">
                {
                    authHandler.isAuthenticated() &&
                    <>
                        <Nav className="ms-4 ms-lg-0 ps-3 ps-lg-0 mt-5 mt-lg-0">
                            <Nav.Link eventKey="1"
                                style={{ width: selectedLanguage === ENGLISH_LANGUAGE ? 140 : 170 }}
                                className={`text-lg-center py-3 py-lg-2 ${animation} ${location.pathname === DEFAULT_ROUTE ? 'active' : ''}`}
                                onClick={() => { navigate('', { replace: false }); toggle(); }}>
                                {t('email_reporting')}
                            </Nav.Link>
                            <Nav.Link eventKey="2"
                                style={{ width: selectedLanguage === ENGLISH_LANGUAGE ? 160 : 200 }}
                                className={`text-lg-center py-3 py-lg-2 ${animation} ${location.pathname === LANDING_REPORTING_ROUTE ? 'active' : ''}`}
                                onClick={() => { navigate('/web-reporting', { replace: false }); toggle(); }}>
                                {t('landing_reporting')}
                            </Nav.Link>
                            <Nav.Link eventKey="3"
                                style={{ width: selectedLanguage === ENGLISH_LANGUAGE ? 120 : 140 }}
                                className={`text-lg-center py-3 py-lg-2 ${animation} ${location.pathname === VIDEO_REPORTING_ROUTE ? 'active' : ''}`}
                                onClick={() => { navigate('/video-reporting', { replace: false }); toggle(); }}>{t('video_reporting')}
                            </Nav.Link>
                        </Nav>

                        <div className={`flex-column d-flex flex-lg-row ${animation} justify-content-end mx-4 mx-lg-0`}>
                            <Settings />
                            <LanguageSelector
                                selectedLanguage={selectedLanguage}
                                changeLanguageHandler={changeLanguageHandler} />
                            <Nav className="ms-3 ms-lg-0 py-2 me-3">
                                <Nav.Link
                                    className="d-flex"
                                    eventKey="4"
                                    href="#"
                                    onClick={() => { navigate('/logout', { replace: false }); toggle(); authHandler.reset(); }}>
                                    <img
                                        alt="sign-out"
                                        style={{ height: 20, width: 20 }}
                                        src={`${process.env.PUBLIC_URL}/images/icons/sign-out.svg`} />
                                    <span className="ps-2 d-none d-xl-flex">{t('logout')}</span>
                                </Nav.Link>
                            </Nav>
                        </div>
                    </>
                }
            </Navbar.Collapse>
        </Navbar >);
}

export default Header;