const PanelSkeleton = () => {
    return (
        <div className="row g-0 overflow-hidden px-3 px-md-1">
            <div className="col-5 placeholder-glow pt-4 pb-3 py-md-3">
                <span className="placeholder c-grey-300 rounded-4 w-100"></span>
            </div>
            <div className="col-1 offset-6 placeholder-glow pt-4 pb-3 py-md-3">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mt-3 mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-12 placeholder-glow mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-12 placeholder-glow mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-12 placeholder-glow mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-12 placeholder-glow mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-12 placeholder-glow mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-12 placeholder-glow mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-12 placeholder-glow mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-12 placeholder-glow mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-12 placeholder-glow mb-2">
                <div className="placeholder c-grey-300 rounded-4 w-100"></div>
            </div>
            <div className="col-12 placeholder-glow mb-4">
                <div className="placeholder c-grey-300 rounded-4 w-50"></div>
            </div>
            <div className="col-8 placeholder-glow">
                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
            </div>
            <div className="col-1 placeholder-glow">
            </div>
            <div className="col-3 placeholder-glow">
                <div className="placeholder c-grey-300 rounded-4 w-100 mb-2"></div>
            </div>
        </div>);
}

export default PanelSkeleton;