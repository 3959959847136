import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import OpenedLinksTableSkeleton from "../../../components/atoms/OpenedLinksTableSkeleton";

import getTooltipLocation from "../../../helpers/responsive/getTooltipLocation";

import { CSVLink } from "react-csv";

const ClickThroughRateTable = ({ data, isLoading, fileName }) => {
    const { t } = useTranslation([]);
    const csvLink = useRef();

    const headers = [
        {
            label: t('landing:link'),
            key: "linkName"
        },
        {
            label: t('common:clicks'),
            key: "clicks"
        },
        {
            label: t('landing:ctr'),
            key: "ctr"
        }
    ];

    const onExportClick = () => {
        if (data.length > 0) {
            csvLink.current.link.click();
        }
    }

    return (
        <div className="bc-white py-4 ps-4 pe-2 rounded-4" style={{ maxWidth: '100%' }}>
            {!isLoading && data && <>
                <div className="row g-0">
                    <div className="col-12 d-flex text-no-wrap justify-content-between" style={{ height: 40 }}>
                        <div className="d-flex align-items-center">
                            <div className="fw-semi-bold">{t('landing:click_through_rate')}</div>
                            <img
                                alt="info-circle"
                                className="px-1 opened_links_tooltip-anchor"
                                src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                            <Tooltip anchorSelect={`.opened_links_tooltip-anchor`} place={getTooltipLocation()}>{t('landing:click_through_rate_tooltip')}</Tooltip>
                        </div>

                        {
                            (data?.length > 0 && fileName) &&
                            <button
                                className="btn-transparent d-flex align-items-center"
                                disabled={isLoading}
                                onClick={() => onExportClick()}>
                                <img
                                    alt="download"
                                    className={`pe-2`}
                                    style={{ width: 25 }}
                                    src={`${process.env.PUBLIC_URL}/images/icons/${isLoading ? 'loader' : 'download'}.svg`} />
                                <span className="c-dark-blue d-none d-md-block">{t('common:export')}</span>
                            </button>
                        }
                    </div>
                    {
                        !isLoading && data?.length > 0 &&
                        <>
                            <div className="d-flex" style={{ height: 48 }}>
                                <div className="col-6 fw-light py-2 my-1">
                                    {t('landing:link')}
                                </div>
                                <div className="col-3 fw-light text-end pe-4 py-2 my-1">
                                    {t('common:clicks')}
                                </div>
                                <div className="col-3 fw-light text-end pe-4 py-2 my-1">
                                    {t('landing:ctr')}
                                </div>
                            </div>

                            <div className="overflow-x-hidden pe-2 col-12" style={{ height: '278px' }}>
                                {data?.map((value, index) => {
                                    return (
                                        <div className={`row g-0 py-2 my-1 ${index === 0 && "border-top-grey-200"} border-bottom-grey-200`}
                                            key={'open-link-' + index}>
                                            <div className="col-6 ps-2">{value.linkName}</div>
                                            <div className="col-3 text-end pe-2">{value.clicks.toLocaleString("en-US")}</div>
                                            <div className="col-3 text-end pe-2">{value.ctr}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    }

                    {
                        !isLoading && data?.length === 0 &&
                        <>
                            <div className="position-relative" style={{ height: 278 }}>
                                <div
                                    style={{ top: '50%' }}
                                    className="d-flex flex-column align-items-center justify-content-center position-absolute start-50 translate-middle">
                                    <img
                                        alt="no-results-found"
                                        src={`${process.env.PUBLIC_URL}/images/empty/links.svg`} />
                                    <span className="d-block text-md py-2 fw-bold">{t("common:no_info")}</span>
                                </div>
                            </div>
                            <div style={{ height: 48 }}>

                            </div>
                        </>
                    }


                </div>
            </>}
            {isLoading &&
                <OpenedLinksTableSkeleton />
            }

            <CSVLink
                data={data}
                filename={`${t('landing:click_through_rate')} ${fileName}.csv`}
                className="hidden"
                ref={csvLink}
                headers={headers}
                enclosingCharacter=""
                target="_blank" />
        </div >
    );
}

export default ClickThroughRateTable;