import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PanelSkeleton from "../../../components/atoms/PanelSkeleton";
import { MAX_TABLET_SCREEN_WIDTH } from "../../../helpers/constants/constants";

const VideosSearchPanel = ({
    isLoading,
    campaigns,
    selectedAccount,
    setSelectedAccount,
    filteredAccounts,
    setSelectedCampaign,
    selectedCampaign,
    videos,
    selectedVideo,
    setSelectedVideo,
    toggle,
    onSearch }) => {

    const { t } = useTranslation(['common', 'panel']);
    const [searchTerm, setSearchTerm] = useState('');

    const waveClicked = (wave) => {
        setSelectedVideo(wave);
        if (window.screen.width <= MAX_TABLET_SCREEN_WIDTH)
            toggle(false);
    }

    useEffect(() => {
        onSearch(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const selectCampaign = (campaign) => {
        if (campaign?.id === selectedCampaign?.id) {
            setSelectedVideo();
            setSelectedCampaign();
        }
        else
            setSelectedCampaign(campaign);
    }

    const selectAccount = (account) => {
        if (selectedAccount?.id !== account?.id) {
            setSelectedVideo();
            setSelectedCampaign();
        }
        setSelectedAccount(account);
    }

    return (
        <div className="height-100">
            {filteredAccounts && !isLoading &&
                <div className="d-flex h-100 flex-column px-3 px-md-1">
                    <div className="d-flex justify-content-between pt-4 pb-3 py-md-3">
                        <span className="c-dark-blue fw-bold">{t('panel:select_video')}</span>
                        <img
                            alt="close"
                            role="button"
                            style={{ height: 24, width: 24 }}
                            onClick={() => toggle(false)}
                            src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                    </div>
                    <div className="d-flex pb-2 pe-1 me-3">
                        <input
                            type="text"
                            className="dcm-input"
                            value={searchTerm}
                            name="search-input"
                            placeholder={t('panel:search_placeholder')}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            aria-label="search" />
                        {
                            searchTerm &&
                            <button type="button" className="btn bg-transparent p-0" style={{ marginLeft: "-25px", zIndex: 100 }}>
                                <img
                                    alt="close"
                                    className="px-1"
                                    onClick={() => setSearchTerm('')}
                                    src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                            </button>
                        }
                    </div>
                    <div className="pb-3 overflow-y-scroll">
                        {filteredAccounts.map((account) => <>
                            <div onClick={() => selectAccount(account)}
                                className={`py-2 ps-2 mb-2 cursor-pointer panel-item ${selectedAccount?.id === account.id && 'bc-grey-200 fw-bold me-2'}`}
                                key={account.id}>
                                <img
                                    alt="arrow"
                                    role="button"
                                    className={`m-0 ${selectedAccount?.id !== account.id && 'rotate-90'} `}
                                    src={`${process.env.PUBLIC_URL}/images/icons/arrow.svg`} />
                                <span className="pt-1">{account.name}</span>
                            </div>
                            {selectedAccount?.id === account.id && campaigns.filter(campaign => campaign.accountId === selectedAccount.id).map((campaign) =>
                                <>
                                    <div onClick={() => selectCampaign(campaign)}
                                        className={`py-2 ps-4 panel-item cursor-pointer me-2 ${selectedCampaign?.id === campaign.id && 'bc-grey-200'}`}
                                        key={campaign.id}>
                                        <img
                                            alt="arrow"
                                            role="button"
                                            className={`pe-1 ${selectedCampaign?.id !== campaign.id && 'rotate-90'} `}
                                            src={`${process.env.PUBLIC_URL}/images/icons/arrow.svg`} />
                                        <span className="pt-1">{campaign.name}</span>
                                    </div>
                                    {selectedCampaign?.id === campaign.id && (videos?.length > 0 && videos.filter(wave => wave.campaignId === selectedCampaign.id).map((wave) =>
                                        <div className={`d-flex panel-item ps-5 me-2 cursor-pointer ${selectedVideo?.id === wave?.id && 'selected'}`}>
                                            <div onClick={() => waveClicked(wave)}
                                                className={`w-100 ps-4 py-2 pe-3 cursor-pointer`}
                                                key={wave?.id}>
                                                <div className="d-flex justify-content-between align-items-center py-2">
                                                    <span title={wave.name} className="item-name" style={{ width: '90%' }}>{wave.name}</span>

                                                    <img
                                                        alt="check"
                                                        style={{ height: 16, width: 16 }}
                                                        hidden={selectedVideo?.id !== wave?.id}
                                                        className={`pe-1`}
                                                        src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </>)}
                        {!isLoading && filteredAccounts.length === 0 &&
                            <div className="text-center">
                                <img
                                    alt="no-results-found"
                                    src={`${process.env.PUBLIC_URL}/images/no-results.svg`} />
                                <span className="d-block py-2">{t("no_results")}</span>
                                <span className="d-block fw-light px-2">{t("no_results_disclaimer_1")}</span>
                                <span className="d-block fw-light px-2">{t("no_results_disclaimer_2")}</span>
                            </div>
                        }
                    </div>
                </div>
            }
            {isLoading &&
                <PanelSkeleton />
            }
        </div>
    );
}

export default VideosSearchPanel;