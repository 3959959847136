import dateTo12Hour from "./dateTo12Hour";
import { CHART_INTERVAL_DAY, CHART_INTERVAL_MONTHS, CHART_INTERVAL_6_HOURS } from "../constants/constants";

const getDateFormat = (date, isFirst, isLast, interval, yearTransitions, hours) => {
    if (!date) return['',''];

    if (interval < CHART_INTERVAL_6_HOURS)
        if (isFirst || hours === 0) return ['day_month_time', date];
        else return ['',dateTo12Hour(date)];
    
    if (interval <= CHART_INTERVAL_DAY )
        if(yearTransitions?.some(d => new Date(d).getTime() === new Date(date).getTime())
            || (yearTransitions?.length > 0 && isFirst))
            return ['day_month_year', (date)];
        else return ['day_month', date];

    if (interval < CHART_INTERVAL_MONTHS)
        if (yearTransitions?.some(d => new Date(d).getTime() === new Date(date).getTime())
            || (yearTransitions?.length > 0 && isFirst))
            return ['day_month_year', date];
        else return ['day_month', (date)];

    if (interval >= CHART_INTERVAL_MONTHS)
        if (yearTransitions?.some(d => new Date(d).getTime() === new Date(date).getTime())
            || (yearTransitions?.length > 0 && isFirst))
            return ['day_month_year',  date];
        else
            if (isLast) return ['day_month_year',  date]
            else return ['month', date];
    return ['',''];
}
    
export default getDateFormat;