import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { ResponsiveLine } from '@nivo/line';
import ChartSkeleton from "../atoms/ChartSkeleton";
import getDateFormat from "../../helpers/formatting/getDateFormat";
import chartTheme from '../../helpers/constants/chartTheme';
import {
    MAX_TABLET_HORIZONTAL_SCREEN_WIDTH,
    CHART_INTERVAL_6_HOURS,
    MAX_MOBILE_SCREEN_WIDTH,
    CHART_INTERVAL_DAY,
    CHART_MARGIN_LG,
    CHART_MARGIN_XL,
    CHART_MAX_MD,
    CHART_INTERVAL_YEAR,
    CHART_MAX
} from "../../helpers/constants/constants";

const LineChart = ({
    filteredDataSet,
    isLoading,
    startDate,
    endDate,
    min,
    max,
    gridYValues,
    tooltipRender,
    interval,
    yearTransitions }) => {

    const { t } = useTranslation(['date']);

    const [chartMarginRight, setChartMarginRight] = useState();
    const [chartMarginLeft, setChartMarginLeft] = useState();

    useEffect(() => {
        window.addEventListener('resize', handleScreenWidth);
        handleScreenWidth();
        return () => {
            document.removeEventListener('resize', handleScreenWidth);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredDataSet])

    const handleScreenWidth = () => {
        setRightMargin();
        setLeftMargin();
    }
    const setRightMargin = () => {
        if (window.innerWidth < MAX_MOBILE_SCREEN_WIDTH) return setChartMarginRight(CHART_MARGIN_LG);
        if (interval <= CHART_INTERVAL_DAY || interval >= CHART_INTERVAL_YEAR) return setChartMarginRight(CHART_MARGIN_XL);
        setChartMarginRight(CHART_MARGIN_LG);
    }

    const setLeftMargin = () => {
        if (window.innerWidth < MAX_MOBILE_SCREEN_WIDTH)
            return max >= CHART_MAX
                ? setChartMarginLeft(max >= CHART_MAX_MD ? CHART_MARGIN_XL : CHART_MARGIN_LG)
                : setChartMarginLeft(interval < CHART_INTERVAL_6_HOURS || yearTransitions?.length > 0 ? CHART_MARGIN_XL : CHART_MARGIN_LG);
        if (interval <= CHART_INTERVAL_DAY || interval >= CHART_INTERVAL_YEAR || yearTransitions?.length > 0 || max >= CHART_MAX)
            return setChartMarginLeft(CHART_MARGIN_XL);
        setChartMarginLeft(CHART_MARGIN_LG);
    }

    const dateToString = (value) => {
        const index = filteredDataSet[0]?.data?.findIndex(d => d.x === value);
        if (window.innerWidth > MAX_TABLET_HORIZONTAL_SCREEN_WIDTH || index === 0 || index === (filteredDataSet[0]?.data?.length - 1)) {
            const hours = new Date(value).getHours();
            let result = getDateFormat(
                value,
                index === 0,
                index === filteredDataSet[0]?.data?.length - 1,
                interval,
                yearTransitions,
                hours
            );

            if (interval > CHART_INTERVAL_6_HOURS || hours === 0 || index === 0)
                return t(result[0], { date: new Date(result[1]) });
            return result[1];
        }
        return '';
    }

    return (<>
        {!isLoading && filteredDataSet?.length > 0 && <>
            {<div style={{ height: 280 }}>
                <ResponsiveLine
                    data={filteredDataSet}
                    style={{ maxWidth: '100%' }}
                    margin={{ top: 20, right: chartMarginRight, bottom: 50, left: chartMarginLeft }}
                    xScale={{ type: 'point' }}
                    colors={filteredDataSet.map(m => m.color)}
                    yScale={{
                        type: 'linear',
                        min: min,
                        max: max,
                        stacked: false,
                        reverse: false
                    }}
                    gridYValues={gridYValues}
                    theme={chartTheme}
                    axisLeft={{
                        yFormat: ">-.2f",
                        tickSize: 0,
                        tickPadding: 8,
                        tickRotation: 0,
                        legend: '',
                        tickValues: gridYValues,
                        legendOffset: 0,
                        legendPosition: 'middle',
                        format: function (value) {
                            if (value > 999) {
                                return (Math.round(value / 1000) * 1000).toLocaleString('en-US');
                            } else {
                                return value;
                            }
                        }
                    }}
                    yFormat=".0s"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 20,
                        tickRotation: 0,
                        legend: '',
                        legendOffset: -40,
                        legendPosition: 'middle',
                        format: (value) => dateToString(value)
                    }}
                    maxWidth={'100%'}
                    lineWidth={3}
                    enableGridX={false}
                    curve="monotoneX"
                    pointSize={2}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    enableSlices="x"
                    enablePoints={false}
                    useMesh={true}
                    motionConfig="wobbly"
                    sliceTooltip={({ slice }) => {
                        return <>
                            {tooltipRender(slice)}
                        </>
                    }}
                />
            </div>}
        </>
        }

        {
            isLoading && <ChartSkeleton />
        }
    </>
    );
}

export default LineChart;