import calcRate from "./calcRate";
import { PIE_CHART_COLORS } from "../constants/constants";

const filterDevices = (devices, filters) => {
    if (!devices?.length) return;
    if (filters?.length > 0)
        devices = devices.filter(device => {
            return filters.some(filter => filter?.value === device?.activityId);
        });

    let browsers = devices.map(p => p?.browser);
    browsers = [...new Set(browsers)].filter(device => device);;
    if (browsers.length === 0 || !browsers[0]) return;
    let total = devices.reduce((sum, item) => sum + (item?.count || 0), 0);
    let eventsByBrowser = browsers.map((browser, index) => (
        {
            id: index,
            label: browser,
            realValue: devices
                .filter(item => item?.browser === browser)
                .reduce((sum, item) => sum + item?.count, 0),
            details: devices.filter(item => item?.browser === browser)
        })).sort((a, b) => b.realValue - a.realValue);

    eventsByBrowser.forEach((browser, index) => {
        browser.color = PIE_CHART_COLORS[index];
        browser.percentage = calcRate(browser.realValue, total);
        browser.value = browser.percentage < 2 ? 2 : browser.percentage;
    });

    const events = browsers.map(browser => {
        let desktop = devices
            .filter(item => item?.device === 'Desktop' && item?.browser === browser)
            .reduce((sum, item) => sum + item?.count, 0);
        let mobile = devices
            .filter(item => item?.device === 'Mobile' && item?.browser === browser)
            .reduce((sum, item) => sum + item?.count, 0);
        let other = devices
            .filter(item => item?.device === 'Other' && item?.browser === browser)
            .reduce((sum, item) => sum + item?.count, 0)
        let total = desktop + mobile + other;
        
        return {
            id: browser,
            desktopTotal: desktop,
            mobileTotal: mobile,
            otherTotal: other,
            total: total
        };
    })

    return { eventsByBrowser, events };
}
export default filterDevices;