import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import Arrow from "../atoms/Arrow";

const LanguageSelector = ({ selectedLanguage, changeLanguageHandler }) => {
    const [showLanguageToggle, setLanguageToggle] = useState(false);

    return (<>
        <Dropdown  className="ms-2 py-0 ms-lg-0 d-none d-lg-flex align-items-center me-4">
            <Dropdown.Toggle className="border-0 btn-link bg-transparent text-decoration-none p-0 ">
                <span className="d-flex">
                    <img
                        alt="info-circle"
                        className="px-1"
                        src={`${process.env.PUBLIC_URL}/images/icons/global.svg`} />
                   <span className="d-none d-xl-block">{selectedLanguage.toUpperCase()}</span> 
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu  className="px-2 dropdown-menu-end" >
                <Dropdown.Item onClick={() => changeLanguageHandler("en")}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            English
                        </div>
                        {selectedLanguage === "en" &&
                            <img
                                alt="selected"
                                style={{ height: 16, width: 16 }}
                                src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                        }
                    </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguageHandler("fr")}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            Française
                        </div>
                        {selectedLanguage !== "en" &&
                            <img
                                alt="selected"
                                style={{ height: 16, width: 16 }}
                                src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                        }
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        <div className="d-lg-none">
            <div onClick={() => setLanguageToggle(!showLanguageToggle)}
                className={`d-flex cursor-pointer justify-content-between p-3 ${showLanguageToggle && 'bc-grey-200 rounded-3'}`}>
                <span>
                    <img
                        alt="language"
                        className="pe-1"
                        src={`${process.env.PUBLIC_URL}/images/icons/global.svg`} />
                    <span className="ps-3">{selectedLanguage.toUpperCase()}</span>
                </span>
                <Arrow turnOn={showLanguageToggle} />
            </div>
            {showLanguageToggle &&
                <>
                    <div className={`mt-1 cursor-pointer p-3 mb-3 ${selectedLanguage === "en" && 'bc-grey-200 rounded-3'}`}>
                        <div onClick={() => changeLanguageHandler("en")}
                            className="d-flex align-items-center justify-content-between">
                            <div>
                                English
                            </div>
                            {selectedLanguage === "en" &&
                                <img
                                    alt="selected"
                                    style={{ height: 16, width: 16 }}
                                    src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                            }
                        </div>
                    </div>

                    <div className={`mt-1 cursor-pointer p-3 ${selectedLanguage !== "en" && 'bc-grey-200 rounded-3'}`}>
                        <div onClick={() => changeLanguageHandler("fr")}
                            className="d-flex align-items-center justify-content-between">
                            <div>
                                Française
                            </div>
                            {selectedLanguage !== "en" &&
                                <img
                                    alt="selected"
                                    style={{ height: 16, width: 16 }}
                                    src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                            }
                        </div>
                    </div>
                </>

            }
        </div>
    </>
    );
}

export default LanguageSelector;