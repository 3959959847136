import { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

import Arrow from "../atoms/Arrow";
import { UniqueRecipientsContext } from "../../contexts/UniqueRecipientsContextProvider";
import { MAX_TABLET_HORIZONTAL_SCREEN_WIDTH } from "../../helpers/constants/constants";

const Settings = () => {
    const { t } = useTranslation(['common']);

    const [showUniqueRecipients, setShowUniqueRecipients] = useContext(UniqueRecipientsContext);
    const [show, setShow] = useState(false);

    return (<>
        <Dropdown className="d-none d-lg-flex">
            <Dropdown.Toggle className="border-0 btn-link bg-transparent text-decoration-none d-flex align-items-center p-0 me-4">
                <span className="d-flex" onClick={() => setShow(true)}>
                    <img
                        alt="settings"
                        src={`${process.env.PUBLIC_URL}/images/icons/settings.svg`} />
                    <span className="ps-2 d-none d-xl-flex">{showUniqueRecipients ? t('unique_interactions') : t('all_interactions')}</span>
                </span>
            </Dropdown.Toggle>
            <Modal show={show && window.innerWidth > MAX_TABLET_HORIZONTAL_SCREEN_WIDTH} onHide={() => setShow(false)}>
                <Dropdown.Menu className="rounded-4 settings p-4">
                    <h6 className="c-black-100 mb-3">{t("settings")}</h6>
                    <div className="form-check form-switch mb-2">
                        <input className="form-check-input"
                            checked={!showUniqueRecipients}
                            onChange={() => { setShowUniqueRecipients(!showUniqueRecipients) }}
                            type="checkbox"
                            role="switch" />
                        <span className="brand-dark-blue">{t('include_unique')}</span>
                    </div>
                    <div className="text-sm c-dark-blue">
                        {t('include_unique_description')}
                    </div>
                </Dropdown.Menu>
            </Modal>
        </Dropdown >
        <div className="d-lg-none">
            <div onClick={() => setShow(!show)}
                className={`d-flex cursor-pointer justify-content-between rounded-3 p-3 ${show && 'bc-grey-200'}`}>
                <span>
                    <img
                        alt="settings"
                        src={`${process.env.PUBLIC_URL}/images/icons/settings.svg`} />
                    <span className="ps-3">{showUniqueRecipients ? t('unique_interactions') : t('all_interactions')}</span>
                </span>
                <Arrow turnOn={show} />
            </div>
            {show &&
                <div className="py-4 px-3">
                    <h6 className="c-black-100 mb-3">{t("settings")}</h6>
                    <div className="form-check form-switch mb-2">
                        <input className="form-check-input"
                            checked={!showUniqueRecipients}
                            onChange={() => { setShowUniqueRecipients(!showUniqueRecipients) }}
                            type="checkbox"
                            role="switch" />
                        <span className="brand-dark-blue">{t('include_unique')}</span>
                    </div>
                    <div className="text-sm c-dark-blue">
                        {t('include_unique_description')}
                    </div>
                </div>
            }
        </div>
    </>
    );
}

export default Settings;