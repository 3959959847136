import axios from "axios";
import qs from 'qs';

function EventsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getCount = (waveId, interval, eventType, from, showUnique, first) => axios
        .get('/events/count-email', {
            params: {
                waveId: waveId,
                interval: interval,
                eventType: eventType,
                from: from,
                unique: showUnique,
                first: first
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    const getCountByDevices = (id, interval, from, isLanding, showUnique, first) => axios
        .get('/events/count-by-devices', {
            params: {
                id: id,
                interval: interval,
                from: from,
                landing: isLanding,
                unique: showUnique,
                first: first
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    const getCountByLinks = (id, interval, from, isLanding, showUnique, first) => axios
        .get('/events/count-by-links', {
            params: {
                id: id,
                interval: interval,
                from: from,
                landing: isLanding,
                unique: showUnique,
                first: first
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    const getCountLending = (id, interval, from, eventType, showUnique, first) => axios
        .get('/events/count-landing', {
            params: {
                id: id,
                interval: interval,
                from: from,
                eventType: eventType,
                unique: showUnique,
                first: first
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    const getLending = (id, interval, from, eventType, showUnique, first) => axios
        .get('/events/landing', {
            params: {
                id: id,
                interval: interval,
                eventType: eventType,
                from: from,
                unique: showUnique,
                first: first
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    return {
        init,
        dispose,
        getCount,
        getCountByDevices,
        getCountByLinks,
        getCountLending,
        getLending
    };
}

export default EventsService;