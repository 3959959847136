const filerOpenEvents = (openEvents, selectedActivities, selectedWaves) => {
    if(!openEvents?.length || !selectedWaves?.length) return;

    openEvents = openEvents.filter(element => element !== undefined);
    let periods = openEvents.map(p => p?.period);
    periods = [...new Set(periods)];

    return periods.map(period => {
        let result = {};
        result.from = period;
        if (selectedActivities?.length)
            selectedActivities?.forEach((a, index) => {
                result['v' + index + 'd'] = a.deploymentDate;
                result['v' + index + 'n'] = a.label;
                result['v' + index] = openEvents
                    .filter(item => item?.period === period && item.activityId === a.value)
                    .reduce((sum, item) => sum + item?.count, 0);
            });
        else
            selectedWaves?.forEach((w, index) => {
                result['v' + index + 'd'] = w.deploymentDate;
                result['v' + index + 'n'] = w.waveName;
                result['v' + index] = openEvents // eslint-disable-next-line 
                    .filter(item => item?.period == period && item.waveId === w.id)
                    .reduce((sum, item) => sum + item?.count, 0);
            });
        return result;
    });
}
export default filerOpenEvents;