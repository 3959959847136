import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import ViewProgressChart from "./ViewProgressChart";

import VideosService from "../../../services/videos-service";

import Tile from "../../../components/molecules/Tile";
import ComparisonLineChart from "../../../components/organisms/ComparisonLineChart";

import { WarningContext } from "../../../contexts/WarningContext";

import { VIDEO_REPORTING_DATA_KEY } from "../../../helpers/constants/constants";
import isValidDate from "../../../helpers/date/isValidDate";
import exportAsImage from "../../../helpers/export/exportAsImage";

const videosService = VideosService();
const VideosTab = ({
    videoDeployment,
    videoViews,
    isTotalViewsLoading,
    setIsTotalViewsLoading,
    videoProgressStats,
    isVideoProgressStatsLoading,
    setIsVideoProgressStatsLoading,
    selectedVideo,
    currentRange,
    setIsVideoDeploymentLoading }) => {

    const { t } = useTranslation();

    const videoViewsRef = useRef(null);
    const videoProgressRef = useRef(null);

    const [totalPublished, setTotalPublished] = useState();
    const [isTotalPublishedLoading, setIsTotalPublishedLoading] = useState(true);

    const [showError, setShowError] = useState(false);

    const [, setState] = useContext(WarningContext);

    useEffect(() => {
        videosService.init();

        let prevOptions = window.localStorage.getItem(VIDEO_REPORTING_DATA_KEY);
        if (!prevOptions) {
            setIsTotalViewsLoading(false);
            setIsVideoProgressStatsLoading(false);
            setIsTotalPublishedLoading(false);
            setIsVideoDeploymentLoading(false);
        }

        return () => {
            videosService.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!selectedVideo) return;

        setIsTotalPublishedLoading(true);

        videosService.getTotalPublished(selectedVideo.id)
            .then((response) => {
                if (response?.data)
                    setTotalPublished(response.data);
            })
            .finally(() => setTimeout(() => setIsTotalPublishedLoading(false), 500));

    }, [selectedVideo])

    useEffect(() => {
        setState({
            message: t('common:file_export_error'),
            bc_color: '#F8D7DA',
            show: showError,
            close: closeError
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showError]);

    const closeError = () => setShowError(false);

    const exportVideoViews = (format) => {
        exportAsImage(videoViewsRef, format, t('video:video_performance_chart'));
    }

    const exportVideoProgress = (format) => {
        exportAsImage(videoProgressRef, format, t('video:view_progress_chart'));
    }

    return (
        <div className="row">
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="published"
                    title={t('video:published')}
                    value={totalPublished?.published?.toLocaleString("en-US") || 0}
                    description={t('video:published_tooltip')}
                    isLoading={isTotalPublishedLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="views"
                    title={t('video:total_views')}
                    value={videoViews?.totalViews?.toLocaleString("en-US") || 0}
                    description={t('video:total_views_tooltip')}
                    isLoading={isTotalViewsLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="deployed"
                    title={t('video:deployment_date')}
                    value={isValidDate(new Date(videoDeployment?.deployedDate)) ? t('common:long_date', { date: new Date(videoDeployment?.deployedDate) }) : t('common:empty_date')}
                    description={t('video:deployment_description')}
                    size="sm"
                    isLoading={isTotalViewsLoading} />
            </div>

            <div ref={videoViewsRef} className="col-12 mb-4">
                <ComparisonLineChart
                    data={videoViews}
                    title={t("video:video_performance")}
                    tooltip={t('video:video_performance_description')}
                    lineKey1={"video:total_views"}
                    lineKey2={"video:unique_views"}
                    isLoading={isTotalViewsLoading}
                    exportFile={exportVideoViews}
                />
            </div>

            <div ref={videoProgressRef} className="col-12">
                <ViewProgressChart
                    stats={videoProgressStats?.videoProgress}
                    isLoading={isVideoProgressStatsLoading}
                    exportFile={exportVideoProgress}
                    currentRange={`${t('date:day_month_year', { date: new Date(currentRange[0]) })} - 
                        ${t('date:day_month_year', { date: new Date(currentRange[1]) })}`} />
            </div>

        </div>
    );
}

export default VideosTab;