import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import PanelSkeleton from "../../../../components/atoms/PanelSkeleton";
import getTooltipLocation from "../../../../helpers/responsive/getTooltipLocation";

const SearchPanel = ({
    isLoading,
    campaigns,
    selectedAccount,
    setSelectedAccount,
    filteredAccounts,
    setSelectedCampaign,
    selectedCampaign,
    waves,
    selectedWaves,
    setSelectedWaves,
    appliedWaves,
    toggle,
    onSearch,
    showError,
    onApply }) => {

    const { t } = useTranslation(['common', 'panel']);
    const [searchTerm, setSearchTerm] = useState('');

    const waveClicked = (wave) => {
        let selectedWavesCopy = selectedWaves;

        if (selectedWaves?.some(w => w.campaignId !== wave.campaignId)) {
            setSelectedWaves([]);
            selectedWavesCopy = [];
        }
        if (isChecked(wave)) {
            showError(false);
            return setSelectedWaves(selectedWavesCopy => selectedWavesCopy.filter(w => w.id !== wave.id));
        }
        if (selectedWavesCopy.length >= 4) return showError(true);
        setSelectedWaves(selectedWavesCopy => [...selectedWavesCopy, wave]);
    }

    const isChecked = (wave) => {
        return selectedWaves.some(w => w.id === wave.id);
    }

    useEffect(() => {
        onSearch(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const selectCampaign = (campaign) => {
        if (campaign?.id === selectedCampaign?.id) {
            setSelectedWaves([]);
            setSelectedCampaign();
        }
        else
            setSelectedCampaign(campaign);
    }

    const selectAccount = (account) => {
        if (selectedAccount?.id !== account?.id) {
            setSelectedWaves([]);
            setSelectedCampaign();
        }
        setSelectedAccount(account);
    }

    const compareWaves = () => {
        if (selectedWaves.length !== appliedWaves.length)
            return false;

        const arr1 = selectedWaves.concat().sort();
        const arr2 = appliedWaves.concat().sort();

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    }

    const disableWaves = (wave) => {
        return selectedWaves?.length === 4 && !isChecked(wave) && !selectedWaves?.some(w => w.campaignId !== wave.campaignId);
    }

    return (
        <div className="height-100">
            {filteredAccounts && !isLoading &&
                <div className="d-flex h-100 flex-column px-3 px-md-1">
                    <div className="d-flex justify-content-between pt-4 pb-3 py-md-3">
                        <div className="d-flex">
                            <span className="c-dark-blue fw-semi-bold">{t('panel:select_emails')}</span>
                            <img
                                alt="info-circle"
                                className={`px-1 tooltip-anchor-search-panel`}
                                src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                            <Tooltip anchorSelect={`.tooltip-anchor-search-panel`} place={getTooltipLocation()}>{t("max_waves_tooltip")}</Tooltip>
                        </div>
                        <img
                            alt="close"
                            role="button"
                            style={{ height: 24, width: 24 }}
                            onClick={() => toggle(false)}
                            src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                    </div>
                    <div className="d-flex pb-2 pe-1 me-3">
                        <input
                            type="text"
                            className="dcm-input"
                            value={searchTerm}
                            name="search-input"
                            placeholder={t('panel:search_placeholder')}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            aria-label="search" />
                        {
                            searchTerm &&
                            <button type="button" className="btn bg-transparent p-0" style={{ marginLeft: "-25px", zIndex: 100 }}>
                                <img
                                    alt="close"
                                    className="px-1"
                                    onClick={() => setSearchTerm('')}
                                    src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                            </button>
                        }
                    </div>

                    <div className="pb-3 overflow-y-scroll">
                        {filteredAccounts?.map((account) => <>
                            <div onClick={() => selectAccount(account)}
                                className={`py-2 ps-2 mb-2 panel-item me-2 ${selectedAccount?.id === account?.id && 'bc-grey-200'}`}
                                key={account?.id}>
                                <img
                                    alt="arrow"
                                    role="button"
                                    className={`m-0 ${selectedAccount?.id !== account?.id && 'rotate-90'} `}
                                    src={`${process.env.PUBLIC_URL}/images/icons/arrow.svg`} />
                                <span className="pt-1">{account?.name}</span>
                            </div>
                            {selectedAccount?.id === account?.id && campaigns?.filter(campaign => campaign.accountId === selectedAccount.id).map((campaign) =>
                                <>
                                    <div onClick={() => selectCampaign(campaign)}
                                        className={`py-2 ps-4 me-2 panel-item ${selectedCampaign?.id === campaign?.id && 'bc-grey-200'}`}
                                        key={campaign?.id}>
                                        <div className="ps-2">
                                            <img
                                                alt="arrow"
                                                role="button"
                                                className={`m-0 ${selectedCampaign?.id !== campaign?.id && 'rotate-90'} `}
                                                src={`${process.env.PUBLIC_URL}/images/icons/arrow.svg`} />
                                            <span className="pt-1">{campaign?.name}</span>
                                        </div>
                                    </div>

                                    {waves?.length > 0 &&
                                        <>
                                            {selectedCampaign?.id === campaign?.id && waves?.filter(wave => wave?.campaignId === selectedCampaign?.id).map((wave) =>
                                                <div onClick={() => waveClicked(wave)}
                                                    className={`d-flex me-2 ps-5 panel-item ${selectedWaves?.includes(wave) && 'bc-grey-200'}`}>
                                                    <div className={`py-2 ps-4 ${disableWaves(wave) && 'c-grey-400'}`}
                                                        key={wave?.id}>
                                                        <input
                                                            className="form-check-input checkbox"
                                                            type="checkbox"
                                                            disabled={disableWaves(wave)}
                                                            checked={isChecked(wave)}
                                                            onChange={() => {}} />
                                                        <span className="ps-2">{wave?.waveName.replace(/_/g, ' ')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    }

                                    {selectedCampaign?.id === campaign.id && waves?.filter(wave => wave.campaignId === selectedCampaign.id).length === 0 &&
                                        <div className="d-flex me-2 py-1 ps-5">
                                            <div className="ps-3 py-2 c-grey-800">
                                                {t('panel:no_waves')}
                                            </div>
                                        </div>
                                    }
                                </>
                            )}
                        </>)}
                    </div>
                    {!isLoading && filteredAccounts?.length === 0 &&
                        <div className="text-center">
                            <img
                                alt="no-results-found"
                                src={`${process.env.PUBLIC_URL}/images/no-results.svg`} />
                            <span className="d-block py-2">{t("no_results")}</span>
                            <span className="d-block fw-light px-2">{t("no_results_disclaimer_1")}</span>
                            <span className="d-block fw-light px-2">{t("no_results_disclaimer_2")}</span>
                        </div>
                    }
                    <div className="mt-auto me-2">
                        <div className="border-top py-3">
                            <button
                                disabled={selectedWaves?.length === 0 || compareWaves()}
                                onClick={() => onApply()}
                                className="btn-regular fw-semi-bold py-2">
                                {t('common:apply')}
                            </button>
                        </div>
                    </div>
                </div>
            }
            {isLoading &&
                <PanelSkeleton />
            }
        </div>
    );
}

export default SearchPanel;