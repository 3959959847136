import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import ExportFile from "../../../../components/molecules/ExportFile";
import WaveLineChart from "../../../../components/organisms/WaveLineChart";
import SelectDropdown from "../../../../components/molecules/SelectDropdown";

import EmailEvents from "../../../../helpers/enums/EmailEvents";
import generateIntervals from "../../../../helpers/formatting/generateIntervals";
import emailEventsOptions from "../../../../helpers/constants/emailEventsOptions";
import getTooltipLocation from "../../../../helpers/responsive/getTooltipLocation";

import { CHART_LEGEND_MAX_LENGTH } from "../../../../helpers/constants/constants";

const WavePerformanceChart = ({
    title,
    wavesEvents,
    isLoading,
    interval,
    selectedWaves,
    setSelectedEventType,
    exportFile
}) => {
    const { t } = useTranslation();

    const defaultMin = 0;
    const defaultMax = 2500;
    const defaultGrid = [defaultMin, 500, 1000, 1500, 2000, defaultMax];

    const lineColors = ['#F1C85E', '#ADB54F', '#0B8ABD', '#1B598E'];
    const [filteredDataSet, setFilteredData] = useState([]);
    const [min, setMin] = useState(defaultMin);
    const [max, setMax] = useState(defaultMax);
    const [gridYValues, setGridYValues] = useState(defaultGrid);

    const [currentEventsOption, setCurrentEventsOption] = useState(emailEventsOptions[0]);

    useEffect(() => {
        if (!wavesEvents.length) return;
        const isEmpty = wavesEvents.every(waveEvent => waveEvent?.events?.every(event => event.count === 0));
        if (isEmpty) {
            setFilteredData([]);
            return;
        }

        const wavesData = [];
        const combinedWavesData = [0];
        for (let i = 0; i < wavesEvents.length; i++) {
            let aggregatedEvents = Object.values(wavesEvents[i].events?.reduce((acc, obj) => {
                let key = obj.period;
                if (!acc[key]) {
                    acc[key] = { ...obj, count: 0 };
                }
                acc[key].count += obj.count;
                return acc;
            }, {}));

            const waveData = {
                id: wavesEvents[i].id,
                waveName: wavesEvents[i].waveName,
                deploymentDate: wavesEvents[i].deploymentDate,
                color: lineColors[i],
                data: aggregatedEvents.map(m => ({ "x": m.period, "y": m.count }))
            };
            combinedWavesData.push(...aggregatedEvents.map(m => m.count));
            wavesData.push(waveData);
        }

        setFilteredData(wavesData);

        if (wavesData.length > 0) {
            const intervals = generateIntervals(combinedWavesData);
            setMin(Math.min(...intervals));
            setMax(Math.max(...intervals));
            setGridYValues(intervals);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wavesEvents])

    const exportChart = (format) => {
        exportFile(format);
    }

    return (<>
        <div className="bc-white p-4 rounded-4 mb-4 exportable" style={{ maxWidth: '100%' }}>
            <div className="row justify-content-between">
                <div className="col-12 d-flex align-items-center justify-content-between" style={{ height: 40 }}>
                    {
                        !isLoading &&
                        <>
                            <div className="text-nowrap">
                                <span className="c-dark-blue fw-semi-bold">{title}</span>
                                <img
                                    data-html2canvas-ignore
                                    alt="info-circle"
                                    className={`px-1 tooltip-anchor-wave-performance`}
                                    src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                                <Tooltip className="text-wrap" anchorSelect={`.tooltip-anchor-wave-performance`} place={getTooltipLocation()}>
                                    {t('wave_performance:description')}
                                </Tooltip>
                            </div>
                            {
                                filteredDataSet.length !== 0 &&
                                <span data-html2canvas-ignore>
                                    <ExportFile
                                        exportFile={exportChart} />
                                </span>
                            }
                        </>
                    }

                    {
                        isLoading &&
                        <div className="d-flex align-items-center placeholder-glow w-50">
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                        </div>
                    }

                </div>
                <div className="col-12 d-flex flex-wrap">
                    {!isLoading && filteredDataSet.length !== 0 &&
                        selectedWaves?.sort((a, b) => a.waveName.localeCompare(b.waveName))?.map((waves, index) =>
                        (
                            <div key={`selected-waves-list-${index}`}
                                style={{ height: 32, flexBasis: waves?.waveName?.length > CHART_LEGEND_MAX_LENGTH ? '50%' : 'auto' }}
                                className={`legend-item text-truncate d-flex tooltip-anchor-wave-legend-${index} cursor-default py-1 align-items-center ${index + 1 !== selectedWaves.length && 'pe-3'}`}>
                                <div className="dot me-2 flex-shrink-0" style={{ backgroundColor: lineColors[index] }}></div>
                                <span className="text-truncate">{waves?.waveName}</span>
                                {
                                    interval.first && <span className="text-nowrap">:&nbsp;{t('common:short_date', { date: new Date(waves?.deploymentDate) })}</span>
                                }

                                {
                                    waves?.waveName.length > CHART_LEGEND_MAX_LENGTH &&
                                    <Tooltip className="legend-tooltip" anchorSelect={`.tooltip-anchor-wave-legend-${index}`} place="top">{waves?.waveName}</Tooltip>
                                }
                            </div>
                        ))
                    }

                    {
                        !isLoading && filteredDataSet.length === 0 &&
                        <div style={{ height: 32 }}></div>
                    }

                    {
                        isLoading &&
                        <div className="d-flex align-items-center placeholder-glow w-50" style={{ height: 32 }}>
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                        </div>
                    }
                </div>
            </div>
            <div style={{ height: 42 }} className="mt-2 mb-3">
                {
                    <>
                        <div className="d-none d-md-block" style={{ height: '100%' }}>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button
                                        style={{ backgroundColor: 'transparent' }}
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                        type="button"
                                        role="tab"
                                        onClick={() => setSelectedEventType(EmailEvents.Deployed)}
                                        aria-selected="true">
                                        {t('wave_performance:deployed')}
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        style={{ backgroundColor: 'transparent' }}
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        type="button"
                                        role="tab"
                                        onClick={() => setSelectedEventType(EmailEvents.Opened)}
                                        aria-selected="false">
                                        {t('wave_performance:opened')}
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        style={{ backgroundColor: 'transparent' }}
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        type="button"
                                        role="tab"
                                        onClick={() => setSelectedEventType(EmailEvents.Clicked)}
                                        aria-selected="false">
                                        {t('wave_performance:clicked')}
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        style={{ backgroundColor: 'transparent' }}
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        type="button"
                                        role="tab"
                                        onClick={() => setSelectedEventType(EmailEvents.Bounced)}
                                        aria-selected="false">
                                        {t('wave_performance:bounced')}
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        style={{ backgroundColor: 'transparent' }}
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        type="button"
                                        role="tab"
                                        onClick={() => setSelectedEventType(EmailEvents.Unsubscribed)}
                                        aria-selected="false">
                                        {t('wave_performance:unsubscribed')}
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="d-block d-md-none">
                            <div className="row">
                                <div className="col-5">
                                    {t('common:show_activity')}
                                </div>
                                <div className="col-7">
                                    <SelectDropdown
                                        options={emailEventsOptions}
                                        selectedOption={currentEventsOption}
                                        setSelectedOption={(option) => { setCurrentEventsOption(option); setSelectedEventType(option?.value) }} />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <WaveLineChart
                filteredDataSet={filteredDataSet}
                isLoading={isLoading}
                min={min}
                max={max}
                gridYValues={gridYValues}
                interval={interval}
            />
        </div >
    </>
    );
}

export default WavePerformanceChart;