import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import PanelSkeleton from "../../../components/atoms/PanelSkeleton";

import getTooltipLocation from "../../../helpers/responsive/getTooltipLocation";
import { MAX_TABLET_SCREEN_WIDTH } from "../../../helpers/constants/constants";

const LandingSearchPanel = ({
    isLoading,
    filteredAccounts,
    selectedAccount,
    campaigns,
    setSelectedCampaign,
    selectedCampaign,
    landings,
    selectedLanding,
    setSelectedLanding,
    toggle,
    setSelectedAccount,
    onSearch }) => {

    const { t } = useTranslation(['common']);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        onSearch(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const selectCampaign = (campaign) => {
        if (campaign?.id === selectedCampaign?.id) {
            setSelectedCampaign();
        }
        else
            setSelectedCampaign(campaign);
    }

    const landingClicked = (landing) => {
        setSelectedLanding(landing);
        if (window.screen.width <= MAX_TABLET_SCREEN_WIDTH)
            toggle(false);
    }

    const selectAccount = (account) => {
        if (selectedAccount?.id !== account?.id) {
            setSelectedCampaign();
            setSelectedAccount();
        }
        setSelectedAccount(account);
    }

    return (
        <div className="height-100">
            {filteredAccounts && !isLoading &&
                <div className="d-flex h-100 flex-column px-3 px-md-1">
                    <div className="d-flex justify-content-between pt-4 pb-3 py-md-3">
                        <div className="d-flex">
                            <span className="c-dark-blue fw-semi-bold">{t('panel:select_landing_page')}</span>
                            <img
                                alt="info-circle"
                                className={`px-1 tooltip-anchor-landing-search-panel`}
                                src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                            <Tooltip anchorSelect={`.tooltip-anchor-landing-search-panel`} place={getTooltipLocation()}>{t("panel:landing_tooltip")}</Tooltip>
                        </div>
                        <img
                            alt="close-panel"
                            role="button"
                            style={{ height: 24, width: 24 }}
                            onClick={() => toggle(false)}
                            src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                    </div>
                    <div className="d-flex pb-2 pe-1 me-3">
                        <input
                            type="text"
                            className="dcm-input"
                            value={searchTerm}
                            name="search-input"
                            placeholder={t('panel:search_placeholder')}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            aria-label="search" />
                        {searchTerm &&
                            <button type="button" className="btn bg-transparent p-0" style={{ marginLeft: "-25px", zIndex: 100 }}>
                                <img
                                    alt="clean-input"
                                    className="px-1"
                                    onClick={() => setSearchTerm('')}
                                    src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                            </button>
                        }
                    </div>

                    <div className="pb-3 overflow-y-scroll">
                        {filteredAccounts.map((account) => <>
                            <div onClick={() => selectAccount(account)}
                                className={`py-2 ps-2 pb-2 panel-item me-2 ${selectedAccount?.id === account.id && 'bc-grey-200'}`}
                                key={'accounts-landing-' + account.id}>
                                <img
                                    alt="arrow"
                                    role="button"
                                    className={`m-0 ${selectedAccount?.id !== account.id && 'rotate-90'} `}
                                    src={`${process.env.PUBLIC_URL}/images/icons/arrow.svg`} />
                                <span className="pt-1">{account.name}</span>
                            </div>
                            {selectedAccount?.id === account.id && campaigns.filter(campaign => campaign.accountId === selectedAccount.id).map((campaign) =>
                                <>
                                    <div onClick={() => selectCampaign(campaign)}
                                        className={`py-2 ps-4 panel-item cursor-pointer me-2 ${selectedCampaign?.id === campaign.id && 'bc-grey-200'}`}
                                        key={'campaigns-landing-' + campaign.id}>
                                        <img
                                            alt="arrow"
                                            role="button"
                                            className={`pe-1 ${selectedCampaign?.id !== campaign.id && 'rotate-90'} `}
                                            src={`${process.env.PUBLIC_URL}/images/icons/arrow.svg`} />
                                        <span className="pt-1">{campaign.name}</span>
                                    </div>
                                    {selectedCampaign?.id === campaign.id && (landings?.length > 0 && landings?.filter(wave => wave.campaignId === selectedCampaign.id)?.map((landing) =>
                                        <div key={'campaigns-landing-' + landing?.name + campaign.id}
                                            className={`d-flex panel-item ps-5 me-2 cursor-pointer ${selectedLanding?.id === landing?.id && 'selected'}`}>
                                            <div onClick={() => landingClicked(landing)}
                                                className={`w-100 ps-4 py-2 pe-3 cursor-pointer`}>
                                                <div className="d-flex justify-content-between align-items-center py-2">
                                                    <span title={landing.name} className="item-name" style={{ width: '90%' }}>{landing.name}</span>
                                                    <img
                                                        alt="check"
                                                        style={{ height: 16, width: 16 }}
                                                        hidden={selectedLanding?.id !== landing?.id}
                                                        className={`pe-1`}
                                                        src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </>)}
                    </div>
                    {!isLoading && filteredAccounts.length === 0 &&
                        <div className="text-center">
                            <img
                                alt="no-results-found"
                                src={`${process.env.PUBLIC_URL}/images/no-results.svg`} />
                            <span className="d-block py-2">{t("no_results")}</span>
                            <span className="d-block fw-light px-2">{t("no_results_disclaimer_1")}</span>
                            <span className="d-block fw-light px-2">{t("no_results_disclaimer_2")}</span>
                        </div>
                    }
                </div>
            }
            {isLoading &&
                <PanelSkeleton />
            }
        </div>
    );
}

export default LandingSearchPanel;