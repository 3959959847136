const chartTheme = {
    fontSize: '14px',
    fontFamily: 'Source Sans Pro',
    axis: {
        ticks: {
            line: {
                strokeWidth: '0'
            },
            text: {
                fontSize: '14px',
                fontWeight: 300,
                fill: '#1C1C1C'
            }
        },
        domain: {
            line: {
                strokeWidth: '10px'
            }
        }
    },
    grid: {
        line: {
            strokeWidth: '1px',
            stroke: '#F6F5F8'
        }
    }
};

export default chartTheme;