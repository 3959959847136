import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import VideosTab from "./components/VideosTab";
import DateRange from "../../components/atoms/DateRange";
import VideosSearchPanel from "./components/VideosSearchPanel";
import WarningBanner from "../../components/atoms/WarningBanner";
import DateSelectDropdown from "../../components/molecules/DateSelectDropdown";

import AccountsService from "../../services/accounts-service";
import VideosService from "../../services/videos-service";

import Footer from "../../core/Footer";

import addDays from "../../helpers/date/addDays";
import dateOptions from "../../helpers/constants/dateOptions";
import { VIDEO_REPORTING_DATA_KEY } from "../../helpers/constants/constants";

const accountsService = AccountsService();
const videosService = VideosService();

const VideoReportingLayoutPage = () => {

    const { t } = useTranslation('common');

    const [accounts, setAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState(accounts);
    const [selectedAccount, setSelectedAccount] = useState();

    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState();

    const [videos, setVideos] = useState([]);

    const [selectedVideo, setSelectedVideo] = useState();

    const [showPanel, setShowPanel] = useState(true);

    const [videoProgressStats, setVideoProgressStats] = useState();
    const [isVideoProgressStatsLoading, setIsVideoProgressStatsLoading] = useState(true);

    const [videoDeployment, setVideoDeployment] = useState();
    const [isVideoDeploymentLoading, setIsVideoDeploymentLoading] = useState(true);
    const [videoViews, setVideoViews] = useState();
    const [isTotalViewsLoading, setIsTotalViewsLoading] = useState(true);

    const [selectedOption, setSelectedOption] = useState(dateOptions[2]);

    const [searchPanelLoading, setSearchPanelLoading] = useState(true);

    useEffect(() => {
        accountsService.init();
        videosService.init();
        let prevOptions = window.localStorage.getItem(VIDEO_REPORTING_DATA_KEY);
        if (prevOptions) {
            prevOptions = JSON.parse(prevOptions);
            setSelectedOption(prevOptions?.interval);
        }

        Promise.all([
            videosService.getAccounts(),
            videosService.getCampaigns(),
        ]).then(
            ([
                accounts,
                campaigns
            ]) => {
                if (accounts?.data) {
                    setAccounts(accounts.data);
                    setFilteredAccounts(accounts.data);
                }

                if (accounts?.data && campaigns?.data) {
                    setCampaigns(campaigns.data);
                    videosService.get()
                        .then((response) => {
                            setVideos(response?.data);
                            if (prevOptions) {
                                setSelectedCampaign(prevOptions?.campaign);
                                setSelectedVideo(prevOptions?.video);
                                setSelectedAccount(prevOptions?.account)
                            }
                        });
                }
            }).finally(() => setTimeout(() => setSearchPanelLoading(false), 500));

        return () => {
            accountsService.dispose();
            videosService.dispose();
        };
    }, []);

    useEffect(() => {
        if (!selectedVideo) return;

        setIsVideoDeploymentLoading(true);
        videosService.getDeploymentDate(selectedVideo.id)
            .then((response) => {
                if (response?.data)
                    setVideoDeployment(response.data);
            })
            .finally(() => setTimeout(() => setIsVideoDeploymentLoading(false), 500));
        // eslint-disable-next-line
    }, [selectedVideo?.id])

    useEffect(() => {
        if (!selectedVideo || !videoDeployment?.deployedDate) return;

        setIsVideoProgressStatsLoading(true);
        videosService
            .getProgressStats(selectedVideo.id, videoDeployment?.deployedDate, selectedOption.value, selectedOption.first)
            .then((response) => { setVideoProgressStats(response?.data) })
            .finally(() => setTimeout(() => setIsVideoProgressStatsLoading(false), 500));

        setIsTotalViewsLoading(true);
        setVideoViews();
        videosService
            .getViews(selectedVideo.id, videoDeployment?.deployedDate, selectedOption.value, selectedOption.first)
            .then((response) => {
                let result = response?.data;
                result.views = result.views = response?.data?.views.map(v => ({
                    intervalStart: new Date(new Date(v.intervalStart).toISOString()),
                    intervalEnd: new Date(new Date(v.intervalEnd).toISOString()),
                    totalViews: v.totalViews,
                    uniqueViews: v.uniqueViews,
                }))
                setVideoViews(result);
            })
            .finally(() => setTimeout(() => setIsTotalViewsLoading(false), 500));

        window.localStorage.setItem(VIDEO_REPORTING_DATA_KEY,
            JSON.stringify({
                interval: selectedOption,
                account: selectedAccount,
                campaign: selectedCampaign,
                video: selectedVideo
            }));
        // eslint-disable-next-line
    }, [selectedOption, selectedVideo?.id, videoDeployment?.deployedDate])

    const onSearch = (value) => {
        const filtered = accounts.filter(function (accounts) {
            return accounts?.name?.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredAccounts([...filtered]);
    }

    const getDateRange = () => {
        return selectedOption.first
            ? [videoDeployment?.deployedDate, addDays(videoDeployment?.deployedDate, selectedOption.value - 1)]
            : [addDays(videoViews?.targetDate, -selectedOption.value), videoViews?.targetDate]
    }

    return (
        <>
            <div className="d-flex align-items-start">
                <div className={`overlay wider bc-white ${showPanel ? '' : 'd-none'}`}>
                    <div className="height-100 px-2">
                        <VideosSearchPanel
                            isLoading={searchPanelLoading}
                            filteredAccounts={filteredAccounts}
                            selectedAccount={selectedAccount}
                            campaigns={campaigns}
                            setSelectedCampaign={setSelectedCampaign}
                            selectedCampaign={selectedCampaign}
                            videos={videos}
                            selectedVideo={selectedVideo}
                            setSelectedVideo={setSelectedVideo}
                            toggle={setShowPanel}
                            setSelectedAccount={setSelectedAccount}
                            onSearch={onSearch}
                        />
                    </div>
                </div>
                <div className="w-100 height-md-100 d-flex flex-column" style={{ overflowY: "auto", overflowX: "hidden" }}>
                    <div className="container-fluid bc-grey-200 flex-grow-1 mb-4 px-4 page-max-width">
                        <div className="row">
                            <div className="col-12"><WarningBanner /></div>
                            <div className="col-9 pt-3">
                                <button
                                    style={{ height: 31 }}
                                    className="bc-grey-200 border-0 d-flex align-items-center"
                                    onClick={() => setShowPanel(!showPanel)}>
                                    <img alt="menu" src={`${process.env.PUBLIC_URL}/images/icons/menu.svg`} />
                                    <span className="d-none c-dark-blue fw-semi-bold d-md-inline ps-2">{selectedVideo?.name || t("video_name")}  </span>

                                    <DateRange
                                        dateRange={getDateRange()}
                                        isLoading={isVideoDeploymentLoading || isTotalViewsLoading} />
                                </button>
                            </div>
                            <div className="col-3 pt-3">
                                <DateSelectDropdown
                                    dateOptions={dateOptions}
                                    selectedOption={selectedOption}
                                    setSelectedOption={setSelectedOption} />
                            </div>
                        </div>

                        <VideosTab
                            videoDeployment={videoDeployment}
                            videoViews={videoViews}
                            isTotalViewsLoading={isTotalViewsLoading}
                            setIsTotalViewsLoading={setIsTotalViewsLoading}
                            videoProgressStats={videoProgressStats}
                            isVideoProgressStatsLoading={isVideoProgressStatsLoading}
                            setIsVideoProgressStatsLoading={setIsVideoProgressStatsLoading}
                            selectedVideo={selectedVideo}
                            currentRange={getDateRange()}
                            setIsVideoDeploymentLoading={setIsVideoDeploymentLoading} />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default VideoReportingLayoutPage;