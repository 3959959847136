import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { ResponsivePie } from '@nivo/pie';
import { CSVLink } from "react-csv";
import { useInView } from "react-intersection-observer";

import ExportFile from "../molecules/ExportFile";

import { formatNumber } from "../../helpers/formatting/formatNumber";
import getTooltipLocation from "../../helpers/responsive/getTooltipLocation";
import calcRate from "../../helpers/calculations/calcRate";
import { CSV } from "../../helpers/constants/constants";

const EngagementByDeviceChart = ({ data, dataExport, isLoading, tooltip, fileName, setIsInView, exportFile }) => {
    const { t } = useTranslation(['devices']);
    const csvLink = useRef();

    const { ref, inView } = useInView({ threshold: 0 });
    useEffect(() => {
        setIsInView(inView);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView])

    const headers = [
        {
            label: t('application'),
            key: "id"
        },
        {
            label: t('desktop'),
            key: "desktopTotal"
        },
        {
            label: t('mobile'),
            key: "mobileTotal"
        },
        {
            label: t('other'),
            key: "otherTotal"
        },
        {
            label: t('opens_count'),
            key: "total"
        }
    ];

    const emptyState = {
        colors: ['#F1F1F1'],
        data: [{ id: "No data", value: 1, color: '#F1F1F1' }]
    }

    const getTotalByDevice = (devices, deviceType) => {
        return devices
            .filter(item => item?.device === deviceType)
            .reduce((sum, item) => sum + item?.count, 0);
    }

    const exportChart = (format) => {
        if (format === CSV && dataExport.length > 0)
            return csvLink.current.link.click();

        exportFile(format);
    }

    return (
        <div className="bc-white p-4 rounded-4 exportable" ref={ref} style={{ maxWidth: '100%' }}>
            {!isLoading && <>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between text-no-wrap" style={{ height: 40 }}>
                        <div className="d-flex align-items-center">
                            <div className="fw-semi-bold">{t('engagement_by_device_platforms')}</div>
                            <img
                                data-html2canvas-ignore
                                alt="info-circle"
                                className="px-1 engagement-tooltip-anchor"
                                src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                            <Tooltip anchorSelect={`.engagement-tooltip-anchor`} place={getTooltipLocation()}>{tooltip}</Tooltip>
                        </div>
                        {(data?.length > 0 && fileName) &&
                            <span data-html2canvas-ignore>
                                <ExportFile
                                    exportFile={exportChart}
                                    csvEnabled={true} />
                            </span>
                        }
                    </div>
                </div>
                {data?.length &&
                    <>
                        <div className="d-flex align-items-center h-100 row">
                            <div className="col-12 col-md-6" style={{ height: 330 }}>
                                <ResponsivePie
                                    data={data}
                                    margin={{ top: 24, right: 6, bottom: 34, left: 6 }}
                                    innerRadius={0.65}
                                    padAngle={2}
                                    cornerRadius={8}
                                    sortByValue={true}
                                    enableArcLinkLabels={false}
                                    colors={{
                                        datum: 'data.color'
                                    }}
                                    enableArcLabels={false}
                                    activeOuterRadiusOffset={6}
                                    tooltip={(input) => {
                                        let item = input.datum.data;
                                        return (
                                            <>
                                                {data?.length > 0 &&
                                                    <div className="bc-white rounded-4 chart-tooltip-md p-3"
                                                        style={{ boxShadow: '0px 0px 24px 0px rgba(26, 38, 63, 0.10)', minWidth: '225px' }}>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="row align-items-center justify-content-between g-0">
                                                                <div className="col-8 d-flex align-items-center">
                                                                    <div className="dot me-2" style={{ backgroundColor: item.color, width: 12 }}></div>
                                                                    <span className="c-black-100 fw-semi-bold">{item.label}</span>
                                                                </div>
                                                                <div className="col-4 text-end pe-2">
                                                                    <span className="c-dark-blue">{formatNumber(item.realValue)}</span>
                                                                </div>
                                                                <div className="col-8 d-flex align-items-center">
                                                                    <span className="c-black-100">{t('desktop')}</span>
                                                                </div>
                                                                <div className="col-4 text-end pe-2">
                                                                    <span className="c-grey-800">
                                                                        {calcRate(getTotalByDevice(item.details, "Desktop"), item.realValue)}%
                                                                    </span>
                                                                </div>

                                                                <div className="col-8 d-flex align-items-center">
                                                                    <span className="c-black-100">{t('mobile')}</span>
                                                                </div>
                                                                <div className="col-4 text-end pe-2">
                                                                    <span className="c-grey-800">
                                                                        {calcRate(getTotalByDevice(item.details, "Mobile"), item.realValue)}%
                                                                    </span>
                                                                </div>

                                                                <div className="col-8 d-flex align-items-center">
                                                                    <span className="c-black-100">{t('other')}</span>
                                                                </div>
                                                                <div className="col-4 text-end pe-2">
                                                                    <span className="c-grey-800">
                                                                        {calcRate(getTotalByDevice(item.details, "Other"), item.realValue)}%
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    }}
                                />
                            </div>

                            <div className="col-12 col-md-6 d-flex flex-column">
                                {data?.map((item, index) => (
                                    <div key={"pi_chart" + index}>
                                        {
                                            <div className="d-flex align-items-center justify-content-between pb-2 mb-1">
                                                <div className="d-flex align-items-center">
                                                    <div className="dot" style={{ backgroundColor: item.color }}></div>
                                                    <span className="c-black-100 ps-2">{item.label}</span>
                                                </div>
                                                <div className="text-end">
                                                    <span className="dark-blue ps-1">{formatNumber(item.percentage)}%</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                }
                {!data?.length &&
                    <>
                        <div className="position-relative" style={{ height: 278 }}>
                            <div
                                style={{ top: '50%' }}
                                className="d-flex flex-column align-items-center justify-content-center position-absolute start-50 translate-middle">
                                <img
                                    alt="no-results-found"
                                    src={`${process.env.PUBLIC_URL}/images/empty/devices.svg`} />
                                <span className="d-block text-md py-2 fw-bold">{t("common:no_info")}</span>
                            </div>
                        </div>
                        <div style={{ height: 48 }}></div>
                    </>
                }
            </>}
            {isLoading &&
                <div className="d-flex align-items-center h-100 row">
                    <div className="col-12 col-md-6" style={{ height: 370 }}>
                        <ResponsivePie
                            data={emptyState.data}
                            margin={{ top: 24, right: 0, bottom: 34, left: 0 }}
                            innerRadius={0.65}
                            padAngle={2}
                            cornerRadius={8}
                            sortByValue={true}
                            enableArcLinkLabels={false}
                            colors={{
                                datum: 'data.color'
                            }}
                            enableArcLabels={false}
                            activeOuterRadiusOffset={6}
                            tooltip={() => (<></>)}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                        <div className="row">
                            <div className="col-12 placeholder-glow mb-4">
                                <div className="placeholder c-grey-300 rounded-4 w-50 mb-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                data?.length > 0 &&
                <CSVLink
                    data={dataExport?.sort((a, b) =>
                        b.total - a.total
                    )}
                    filename={`${t('engagement_by_device_platforms')} ${fileName}.csv`}
                    className="hidden"
                    ref={csvLink}
                    headers={headers}
                    enclosingCharacter=""
                    target="_blank" />
            }

        </div>
    );
}

export default EngagementByDeviceChart;