import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import ClickThroughRateTable from "./ClickThroughRateTable";

import EventsService from "../../../services/events-service";
import LandingService from "../../../services/landing-service";

import Tile from "../../../components/molecules/Tile";
import ComparisonLineChart from "../../../components/organisms/ComparisonLineChart";
import EngagementByDeviceChart from "../../../components/organisms/EngagementByDeviceChart";

import { WarningContext } from "../../../contexts/WarningContext";
import { UniqueRecipientsContext } from "../../../contexts/UniqueRecipientsContextProvider";

import calcRate from "../../../helpers/calculations/calcRate";
import filterLinks from "../../../helpers/calculations/filterLinks";
import filterDevices from "../../../helpers/calculations/filterDevices";
import {
    LANDING_VISIT,
    LANDING_PERFORMED_ACTION,
    LANDING_REPORTING_DATA_KEY
} from "../../../helpers/constants/constants";
import exportAsImage from "../../../helpers/export/exportAsImage";

const landingService = LandingService();
const eventsService = EventsService();
const LandingTab = ({ selectedLanding, interval, dateRange, setDateRange, setIsDateRangeLoading }) => {
    const { t } = useTranslation(['landing']);

    const [landingStatistics, setLandingStatistics] = useState({});
    const [landingStatisticsIsLoading, setLandingStatisticsIsLoading] = useState(true);
    const [clickThroughRate, setClickThroughRate] = useState([]);

    const [engagementByDevice, setEngagementByDevice] = useState([]);
    const [engagementByDeviceIsLoading, setEngagementByDeviceIsLoading] = useState(true);
    const engagementByDeviceRef = useRef(null);

    const [viewsAndInteractions, setViewsAndInteractions] = useState();
    const [viewsAndInteractionsLoading, setViewsAndInteractionsIsLoading] = useState(true);
    const viewsAndInteractionsRef = useRef(null);

    const [showError, setShowError] = useState(false);

    const [, setState] = useContext(WarningContext);
    const [showUniqueRecipients, ,] = useContext(UniqueRecipientsContext);

    const [csvFileName, setCsvFileName] = useState('');

    useEffect(() => {
        landingService.init();
        eventsService.init();

        let prevOptions = window.localStorage.getItem(LANDING_REPORTING_DATA_KEY);
        if (!prevOptions) {
            setLandingStatisticsIsLoading(false);
            setEngagementByDeviceIsLoading(false);
            setViewsAndInteractionsIsLoading(false);
            setIsDateRangeLoading(false);
        }

        return () => {
            landingService.dispose();
            eventsService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!selectedLanding || !selectedLanding.creationDate || !interval) {
            return;
        }
        setLandingStatisticsIsLoading(true);
        setViewsAndInteractionsIsLoading(true);
        setEngagementByDeviceIsLoading(true);
        setIsDateRangeLoading(true);
        setCsvFileName('');

        Promise.all([
            eventsService.getCountLending(selectedLanding.id, interval.value, selectedLanding.creationDate, LANDING_VISIT, showUniqueRecipients, interval.first),
            eventsService.getCountLending(selectedLanding.id, interval.value, selectedLanding.creationDate, LANDING_PERFORMED_ACTION, showUniqueRecipients, interval.first),
            eventsService.getCountByLinks(selectedLanding.id, interval.value, selectedLanding.creationDate, true, showUniqueRecipients, interval.first),
        ]).then(
            ([
                visits,
                action,
                links
            ]) => {
                setClickThroughRate(
                    (filterLinks(links.data))
                        .map(value => (
                            { ...value, ctr: calcRate(value.clicks, visits.data) + '%' }
                        ))
                );
                setLandingStatistics({
                    visits: +visits?.data,
                    action: action.data,
                    interactionRate: calcRate(action.data, visits.data),
                    bounced: visits.data - action.data,
                    bouncedRate: calcRate(visits.data - action.data, visits.data)
                });
            }
        ).finally(() => setTimeout(() => setLandingStatisticsIsLoading(false), 500));

        Promise.all([
            eventsService.getLending(selectedLanding.id, interval.value, selectedLanding.creationDate, LANDING_VISIT, showUniqueRecipients, interval.first),
            eventsService.getLending(selectedLanding.id, interval.value, selectedLanding.creationDate, LANDING_PERFORMED_ACTION, showUniqueRecipients, interval.first)
        ]).then(
            ([
                visits,
                action
            ]) => {
                setTimeout(() => setDateRange([visits?.data?.[0]?.intervalStart, visits?.data?.[visits?.data?.length - 1]?.intervalEnd]), 500);
                setViewsAndInteractions({
                    views: visits?.data?.map(v => ({
                        intervalStart: new Date(new Date(v?.intervalStart).toISOString()),
                        intervalEnd: new Date(new Date(v?.intervalEnd).toISOString()),
                        totalViews: v?.count,
                        uniqueViews: action.data.find(a => a?.intervalStart === v?.intervalStart).count
                    }))
                });
            }
        ).finally(() => setTimeout(() => { setViewsAndInteractionsIsLoading(false); setIsDateRangeLoading(false); }, 500));

        setEngagementByDevice([]);
        eventsService.getCountByDevices(selectedLanding.id, interval.value, selectedLanding.creationDate, true, showUniqueRecipients, interval.first)
            .then(response => setEngagementByDevice(filterDevices(response.data)))
            .finally(() => setTimeout(() => setEngagementByDeviceIsLoading(false), 500));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval, selectedLanding, showUniqueRecipients])

    useEffect(() => {
        setState({
            message: t('common:file_export_error'),
            bc_color: '#F8D7DA',
            show: showError,
            close: closeError
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showError]);

    useEffect(() => {
        if (!(dateRange?.length > 0 && !!dateRange[0] && !!dateRange[1])) return;

        let date = '';
        let endDate = '';
        date += t('date:full_date', { date: new Date(dateRange[0]) });
        endDate += ' - ' + t('date:full_date', { date: new Date(dateRange[1]) });

        setCsvFileName(`${date}${endDate}`);
    }, [dateRange, t])

    const closeError = () => setShowError(false);

    const exportLineChart = (format) => {
        exportAsImage(viewsAndInteractionsRef, format, t('views_and_interactions_chart'))
    }

    const exportEngagementByDevice = (format) => {
        exportAsImage(engagementByDeviceRef, format, t('devices:engagement_by_device_platforms'));
    }

    return (
        <div className="row">
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="pageVisits"
                    title={t("page_visits")}
                    value={landingStatistics?.visits?.toLocaleString("en-US") ?? 0}
                    description={t('page_visits_tooltip')}
                    isLoading={landingStatisticsIsLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="interactionRate"
                    title={t("interaction_rate")}
                    value={`${landingStatistics?.interactionRate ?? 0}%`}
                    value2={landingStatistics?.action?.toLocaleString("en-US") ?? 0}
                    description={t('interaction_rate_tooltip')}
                    isLoading={landingStatisticsIsLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="bouncedRate"
                    title={t("bounced_rate")}
                    value={`${landingStatistics?.bouncedRate ?? 0}%`}
                    value2={landingStatistics?.bounced?.toLocaleString("en-US") ?? 0}
                    description={t("bounced_rate_tooltip")}
                    isLoading={landingStatisticsIsLoading} />
            </div>
            <div ref={viewsAndInteractionsRef} className="col-12 mb-4">
                <ComparisonLineChart
                    data={viewsAndInteractions}
                    title={t("views_and_interactions")}
                    tooltip={t("views_and_interactions_tooltip")}
                    lineKey1={"landing:views"}
                    lineKey2={"landing:interactions"}
                    isLoading={viewsAndInteractionsLoading}
                    exportFile={exportLineChart}
                />
            </div>
            <div className="col-12 col-lg-6 mb-4">
                <ClickThroughRateTable
                    data={clickThroughRate}
                    fileName={csvFileName}
                    isLoading={landingStatisticsIsLoading} />
            </div>
            <div ref={engagementByDeviceRef} className="col-12 col-lg-6 mb-4">
                <EngagementByDeviceChart
                    data={engagementByDevice?.eventsByBrowser}
                    dataExport={engagementByDevice?.events}
                    isLoading={engagementByDeviceIsLoading}
                    fileName={csvFileName}
                    tooltip={t('engagement_by_device_platforms_tooltip')}
                    setIsInView={() => { }}
                    exportFile={exportEngagementByDevice} />
            </div>
        </div>
    )
}
export default LandingTab;