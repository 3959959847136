import axios from "axios";
import qs from "qs";

function LandingService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const get = (selectedMarketingIds) => axios
        .get(`/landing?${qs.stringify({ ids: [...selectedMarketingIds] })}`, config())
        .catch(error => error);

    return {
        init,
        dispose,
        get
    };
}

export default LandingService;