import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import Tile from "../../../../components/molecules/Tile";
import Locations from "../../../../components/organisms/Locations";
import ActivitiesSelector from "../../../../components/organisms/ActivitiesSelector";
import EngagementByDeviceChart from "../../../../components/organisms/EngagementByDeviceChart";

import { WarningContext } from "../../../../contexts/WarningContext";
import { UniqueRecipientsContext } from "../../../../contexts/UniqueRecipientsContextProvider";

import EventsService from "../../../../services/events-service";
import WavesService from "../../../../services/waves-service";
import EmailActivitiesService from "../../../../services/activities-service";

import OpenedLinksTable from "../charts/OpenedLinksTable";
import WavePerformanceChart from "../charts/WavePerformanceChart";
import OpensPerformanceChart from "../charts/OpensPerformanceChart";

import EmailEvents from "../../../../helpers/enums/EmailEvents";
import calcRate from "../../../../helpers/calculations/calcRate";
import filerLinks from "../../../../helpers/calculations/filterLinks";
import filterEvents from "../../../../helpers/calculations/filterEvents";
import filterDevices from "../../../../helpers/calculations/filterDevices";
import filerOpenEvents from "../../../../helpers/calculations/filerOpenEvents";
import addDays from "../../../../helpers/date/addDays";
import {
    EMAIL_SENT,
    EMAIL_SENT_FAILED,
    EMAIL_OPENED,
    EMAIL_UNSUBSCRIBED,
    EMAIL_LINK_CLICKED,
    EMAIL_REPORTING_DATA_KEY
} from "../../../../helpers/constants/constants";
import exportAsImage from "../../../../helpers/export/exportAsImage";

const eventsService = EventsService();
const wavesService = WavesService();
const emailActivitiesService = EmailActivitiesService();
const EmailsTab = ({ selectedWaves, interval, selectedCampaign }) => {
    const { t } = useTranslation(['email']);

    const wavePerformanceRef = useRef(null);
    const openPerformanceRef = useRef(null);
    const engagementByDeviceRef = useRef(null);

    const [emailStatistics, setEmailStatistics] = useState({});
    const [filteredEmailStatistics, setFilteredEmailStatistics] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    const [wavesEvents, setWaveEvents] = useState([]);
    const [selectedEventType, setSelectedEventType] = useState(EmailEvents.Deployed);
    const [isWavesEventsLoading, setIsWaveEventsLoading] = useState(true);

    const [isEmailActivitiesLoading, setIsEmailActivitiesLoading] = useState(true);
    const [emailActivities, setEmailActivities] = useState([]);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [filteredActivities, setFilteredActivities] = useState([]);
    const [filteredSelectedWaves, setFilteredSelectedWaves] = useState(selectedWaves)

    const [emailOpens, setEmailOpens] = useState([]);
    const [filteredEmailOpens, setFilteredEmailOpens] = useState([]);

    const [engagementByDevice, setEngagementByDevice] = useState([]);
    const [filteredEngagementByDevice, setFilteredEngagementByDevice] = useState([]);
    //const [engagementByDevice, setEngagementByDevice] = useState([]);
    const [engagementByDeviceIsLoading, setEngagementByDeviceIsLoading] = useState(true);

    const [linkOpens, setLinkOpens] = useState([]);
    const [filteredLinkOpens, setFilteredLinkOpens] = useState([]);
    const [linkIsLoading, setLinkIsLoading] = useState(true);

    const [opensByLocation, setOpensByLocation] = useState([]);
    const [filteredOpensByLocation, setFilteredOpensByLocation] = useState([]);
    const [isOpensByLocationLoading, setIsOpensByLocationLoading] = useState(true);

    const [showError, setShowError] = useState(false);
    const [, setState] = useContext(WarningContext);
    const [showUniqueRecipients, ,] = useContext(UniqueRecipientsContext);

    useEffect(() => {
        eventsService.init();
        emailActivitiesService.init();
        wavesService.init();

        let prevOptions = window.localStorage.getItem(EMAIL_REPORTING_DATA_KEY);
        if (!prevOptions) {
            setIsLoading(false);
            setIsWaveEventsLoading(false);
            setIsEmailActivitiesLoading(false);
            setEngagementByDeviceIsLoading(false);
            setLinkIsLoading(false);
            setIsOpensByLocationLoading(false);
        }

        return () => {
            eventsService.dispose();
            emailActivitiesService.dispose();
            wavesService.dispose();
        }
    }, [])

    useEffect(() => {
        if (!selectedWaves || selectedWaves.length === 0 || !interval) return;

        setLinkIsLoading(true);
        setIsOpensByLocationLoading(true);

        let promisesArray = [];
        selectedWaves.forEach(w => {
            promisesArray.push(eventsService.getCount(w.id, interval.value, EMAIL_SENT, w.deploymentDate, showUniqueRecipients, interval.first));
            promisesArray.push(eventsService.getCount(w.id, interval.value, EMAIL_SENT_FAILED, w.deploymentDate, showUniqueRecipients, interval.first));
            promisesArray.push(eventsService.getCount(w.id, interval.value, EMAIL_OPENED, w.deploymentDate, showUniqueRecipients, interval.first));
            promisesArray.push(eventsService.getCount(w.id, interval.value, EMAIL_UNSUBSCRIBED, w.deploymentDate, showUniqueRecipients, interval.first));
            promisesArray.push(eventsService.getCount(w.id, interval.value, EMAIL_LINK_CLICKED, w.deploymentDate, showUniqueRecipients, interval.first));
            promisesArray.push(wavesService.getEvents(w.campaignId, w.id, interval.first ? interval.value : interval.value * -1, EMAIL_OPENED, interval?.range, showUniqueRecipients));
        });

        setIsLoading(true);
        setFilteredEmailOpens([]);
        Promise.all(promisesArray)
            .then(
                (results) => {
                    let deployed = results.filter((_, index) => index % 6 === 0)?.map(a => a.data)?.flat()?.filter(b => b);
                    let bounced = results.filter((_, index) => index % 6 === 1)?.map(a => a.data)?.flat()?.filter(b => b);
                    let open = results.filter((_, index) => index % 6 === 2)?.map(a => a.data)?.flat()?.filter(b => b);
                    let unsubscribed = results.filter((_, index) => index % 6 === 3)?.map(a => a.data)?.flat()?.filter(b => b);
                    let clicked = results.filter((_, index) => index % 6 === 4)?.map(a => a.data)?.flat()?.filter(b => b);
                    let openEvents = results.filter((_, index) => index % 6 === 5)?.map(a => a.data)?.map(w => {
                        return w?.events?.map(e => {
                            return {
                                waveId: w.waveId,
                                period: e.period,
                                count: e.count,
                                activityId: e.activityId
                            }
                        })
                    }).flat()

                    setEmailOpens(openEvents);
                    setFilteredEmailOpens(filerOpenEvents(openEvents, selectedActivities, selectedWaves));
                    setEmailStatistics({
                        deployed: deployed,
                        bounced: bounced,
                        open: open,
                        unsubscribed: unsubscribed,
                        clicked: clicked
                    });

                    deployed = filterEvents(deployed, selectedActivities);
                    bounced = filterEvents(bounced, selectedActivities);
                    open = filterEvents(open, selectedActivities);
                    unsubscribed = filterEvents(unsubscribed, selectedActivities);
                    clicked = filterEvents(clicked, selectedActivities);

                    const delivered = deployed === 0 ? 0 : deployed - bounced;
                    setFilteredEmailStatistics({
                        totalDeployed: deployed,
                        totalDelivered: delivered,
                        deliveryRate: calcRate(delivered, deployed),
                        totalOpened: open,
                        openRate: calcRate(open, delivered),
                        clickRate: calcRate(clicked, open),
                        clickThrough: calcRate(clicked, delivered),
                        totalUnsubscribe: unsubscribed,
                        unsubscribeRate: calcRate(unsubscribed, deployed),
                        totalBounced: bounced,
                        bouncedRate: calcRate(bounced, deployed)
                    });
                })
            .finally(() => setTimeout(() => setIsLoading(false), 500));

        setFilteredEngagementByDevice([]);
        setEngagementByDeviceIsLoading(true);

        setOpensByLocation([]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval, selectedWaves, showUniqueRecipients])

    const [isLinksDirty, setLinksIsDirty] = useState(true);
    const [isLocationsDirty, setLocationsIsDirty] = useState(true);
    const [isDevicesDirty, setDevicesIsDirty] = useState(true);
    useEffect(() => {
        if (!isLinksDirty) {
            setLinksIsDirty(true);
            setLocationsIsDirty(true);
            setDevicesIsDirty(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval, showUniqueRecipients, selectedWaves])

    const [linksInView, setLinksInView] = useState(false);
    useEffect(() => {
        if (linksInView && isLinksDirty) {
            setLinksIsDirty(false);
            Promise.all(
                selectedWaves.map(wave =>
                    eventsService.getCountByLinks(wave.id, interval.value, wave.deploymentDate, false, showUniqueRecipients, interval.first)
                ))
                .then(responses => {
                    const links = responses.map(r => r.data).flat();
                    setLinkOpens(links);
                    setFilteredLinkOpens(filerLinks(links, selectedActivities));
                })
                .finally(() => { setTimeout(() => setLinkIsLoading(false), 500); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLinksDirty, linksInView])

    const [locationsInView, setLocationsInView] = useState(false);
    useEffect(() => {
        if (locationsInView && isLocationsDirty) {
            setLocationsIsDirty(false);
            Promise.all(
                selectedWaves.map(wave =>
                    wavesService.getOpensByLocation(wave.campaignId, wave.id, interval.first ? interval.value : interval.value * -1, showUniqueRecipients)
                ))
                .then(responses => {
                    const opensByLocations = [];
                    for (let i = 0; i < responses.length; i++) {
                        const response = responses[i];
                        if (response.status === 204) continue;
                        opensByLocations.push(...response.data.events);
                    }
                    setOpensByLocation(opensByLocations);
                })
                .finally(() => { setTimeout(() => setIsOpensByLocationLoading(false), 500); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLocationsDirty, locationsInView])

    const [devicesInView, setDevicesInView] = useState(false);
    useEffect(() => {
        if (devicesInView && isDevicesDirty) {
            setDevicesIsDirty(false);
            setFilteredEngagementByDevice([]);
            Promise.all(
                selectedWaves.map(wave =>
                    eventsService.getCountByDevices(wave.id, interval.value, wave.deploymentDate, false, showUniqueRecipients, interval.first)
                ))
                .then(responses => {
                    const engagementByDevice = responses.map(r => r.data).flat();
                    setEngagementByDevice(engagementByDevice);
                    setFilteredEngagementByDevice(filterDevices(engagementByDevice, selectedActivities));
                })
                .finally(() => { setTimeout(() => setEngagementByDeviceIsLoading(false), 500); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDevicesDirty, devicesInView])

    useEffect(() => {
        if (!selectedWaves.length) return;

        setWaveEvents([]);
        setIsWaveEventsLoading(true);

        const promises = selectedWaves.map(wave =>
            wavesService.getEvents(
                wave.campaignId,
                wave.id,
                interval.first ? interval.value : interval.value * -1,
                selectedEventType,
                interval?.range,
                showUniqueRecipients)
        );

        Promise
            .all(
                promises
            )
            .then(responses => {
                const wavesPerformance = [];
                for (let i = 0; i < responses.length; i++) {
                    if (responses?.[i]?.data?.events) {
                        const waveId = responses?.[i]?.data.waveId;
                        const wave = selectedWaves.find(w => waveId === w.id);
                        wavesPerformance.push({
                            id: waveId,
                            waveName: wave?.waveName,
                            deploymentDate: wave?.deploymentDate,
                            events: responses?.[i]?.data.events
                        });
                    }
                }
                wavesPerformance.sort((a, b) => a.waveName.localeCompare(b.waveName))
                setWaveEvents(wavesPerformance);
            })
            .finally(() => setTimeout(() => setIsWaveEventsLoading(false), 500));
    }, [interval, selectedWaves, selectedEventType, showUniqueRecipients])

    useEffect(() => {
        const activitiesPromises = selectedWaves.map(wave => emailActivitiesService.get(wave.id));
        setIsEmailActivitiesLoading(true);
        Promise
            .all(
                activitiesPromises
            )
            .then(responses => {
                const activities = [];
                for (let i = 0; i < responses.length; i++) {
                    activities.push(...responses?.[i]?.data);
                }
                setEmailActivities(activities);
            })
            .finally(() => setTimeout(() => setIsEmailActivitiesLoading(false), 1000));
    }, [selectedWaves])

    useEffect(() => {
        const filteredOpens = [];
        if (selectedActivities.length > 0) {
            filteredOpens.push(opensByLocation.filter(open => selectedActivities.some(a => a.value === open.activityId)));
            setFilteredOpensByLocation(...filteredOpens);
        } else {
            setFilteredOpensByLocation(opensByLocation);
        }

    }, [selectedActivities, opensByLocation])

    useEffect(() => {
        if (!wavesEvents.length) return;

        const deployed = filterEvents(emailStatistics.deployed, selectedActivities);
        const bounced = filterEvents(emailStatistics.bounced, selectedActivities);
        const open = filterEvents(emailStatistics.open, selectedActivities);
        const unsubscribed = filterEvents(emailStatistics.unsubscribed, selectedActivities);
        const clicked = filterEvents(emailStatistics.clicked, selectedActivities);
        const delivered = deployed === 0 ? 0 : deployed - bounced;
        setFilteredEmailStatistics({
            totalDeployed: deployed,
            totalDelivered: delivered,
            deliveryRate: calcRate(delivered, deployed),
            totalOpened: open,
            openRate: calcRate(open, delivered),
            clickRate: calcRate(clicked, open),
            clickThrough: calcRate(clicked, delivered),
            totalUnsubscribe: unsubscribed,
            unsubscribeRate: calcRate(unsubscribed, deployed),
            totalBounced: bounced,
            bouncedRate: calcRate(bounced, deployed)
        });

        setFilteredLinkOpens(filerLinks(linkOpens, selectedActivities));
        setFilteredEmailOpens(filerOpenEvents(emailOpens, selectedActivities, selectedWaves));
        setFilteredEngagementByDevice(filterDevices(engagementByDevice, selectedActivities));

        if (!selectedActivities.length) {
            setFilteredActivities(wavesEvents);
            setFilteredSelectedWaves(selectedWaves);
            return;
        }

        const activities = selectedActivities;
        const activityEvents = [];
        const selectedActivitiesLegend = [];

        if (activities.length > 0) {
            for (let i = 0; i < activities.length; i++) {
                const waveEvents = wavesEvents.filter(e => e.id === activities[i].waveId)[0]?.events;
                if (!waveEvents) continue;

                const periods = [...new Set(waveEvents.map(obj => obj.period))];
                const waveActivities = waveEvents.filter(we => we.activityId === activities[i].value);

                const result = [];

                for (let j = 0; j < periods.length; j++) {
                    const filteredActivities = waveActivities.filter(wa => wa.period === periods[j]);
                    if (filteredActivities.length > 0) result.push(...filteredActivities);
                    else result.push({
                        period: periods[j],
                        count: 0,
                        activityId: activities[i].value
                    });
                }

                let wave = {
                    waveName: activities[i].label,
                    id: activities[i].value,
                    deploymentDate: activities[i]?.deploymentDate
                }

                selectedActivitiesLegend.push(wave);
                wave.events = result;
                activityEvents.push(wave);
            }
        }

        setFilteredActivities(activityEvents);
        setFilteredSelectedWaves(selectedActivitiesLegend);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedActivities, wavesEvents])

    const [csvFileName, setCsvFileName] = useState('');

    useEffect(() => {
        if (selectedCampaign) {
            let date = '';
            let endDate = '';

            if (selectedActivities.length === 1) {
                date += t('date:full_date', { date: new Date(selectedActivities[0].deploymentDate) });

                if (interval.value > 1) {
                    endDate += ' - ' + t('date:full_date', { date: addDays(new Date(selectedActivities[0].deploymentDate), interval.value) });
                }
            }

            if (selectedWaves.length === 1 && selectedActivities.length !== 1) {
                date += t('date:full_date', { date: new Date(selectedWaves[0].deploymentDate) });

                if (interval.value > 1) {
                    endDate += ' - ' + t('date:full_date', { date: addDays(new Date(selectedWaves[0].deploymentDate), interval.value) });
                }
            }

            if (!date) {
                date += t(`common:${interval.label}`)
            }

            setCsvFileName(`${selectedCampaign.name} ${date}${endDate}`);
        }

    }, [selectedCampaign, selectedWaves, selectedActivities, interval, t])

    useEffect(() => {
        setState({
            message: t('common:file_export_error'),
            bc_color: '#F8D7DA',
            show: showError,
            close: closeError
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showError]);

    const closeError = () => setShowError(false);

    const exportWavePerformance = (format) => {
        exportAsImage(wavePerformanceRef, format, t('wave_performance_chart'));
    }

    const exportOpenPerformance = (format) => {
        exportAsImage(openPerformanceRef, format, t('open_performance'));
    }

    const exportEngagementByDevice = (format) => {
        exportAsImage(engagementByDeviceRef, format, t('devices:engagement_by_device_platforms'));
    }

    return (
        <div className="row">
            {
                (!isEmailActivitiesLoading && emailActivities.length > 0) &&
                <div className="col-12 mb-4">
                    <ActivitiesSelector
                        activities={emailActivities}
                        setSelectedActivities={setSelectedActivities}
                        selectedWaves={selectedWaves} />
                </div>

            }
            {
                isEmailActivitiesLoading &&
                <div className="col-12 mb-4">
                    <div className="d-flex bc-white p-4 box-shadow border-radius flex-wrap">
                        <div className="d-flex align-items-center w-100 placeholder-glow" style={{ height: 42 }}>
                            <div className="col-3">
                                <span className="c-grey-300 w-50 border-radius placeholder"></span>
                            </div>

                            <div className="c-grey-300 col-9 border-radius placeholder" style={{ height: 26 }}></div>
                        </div>
                    </div>
                </div>
            }
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="deployed"
                    title={t('deployed')}
                    value={filteredEmailStatistics?.totalDeployed?.toLocaleString("en-US") ?? 0}
                    description={t('deployed_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="deliveryRate"
                    title={t("delivery_rate")}
                    value={`${filteredEmailStatistics?.deliveryRate ?? 0}%`}
                    value2={filteredEmailStatistics?.totalDelivered?.toLocaleString("en-US") ?? 0}
                    description={t('delivery_rate_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="openRate"
                    title={t("open_rate")}
                    value={`${filteredEmailStatistics?.openRate ?? 0}%`}
                    value2={filteredEmailStatistics?.totalOpened?.toLocaleString("en-US") ?? 0}
                    description={t('open_rate_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="clickToOpenRate"
                    title={t("click_open_rate")}
                    value={`${filteredEmailStatistics?.clickRate ?? 0}%`}
                    description={t('click_open_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="clickThroughRate"
                    title={t("click_through_rate")}
                    value={`${filteredEmailStatistics?.clickThrough ?? 0}%`}
                    description={t('click_through_rate_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="unsubscribeRate"
                    title={t("unsubscribe_rate")}
                    value={`${filteredEmailStatistics?.unsubscribeRate ?? 0}%`}
                    value2={filteredEmailStatistics?.totalUnsubscribe?.toLocaleString("en-US") ?? 0}
                    description={t('unsubscribe_rate_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xxl-3 mb-4">
                <Tile
                    name="bouncedRate"
                    title={t("bounced_rate")}
                    value={`${filteredEmailStatistics?.bouncedRate ?? 0}%`}
                    value2={filteredEmailStatistics?.totalBounced?.toLocaleString("en-US") ?? 0}
                    description={t('bounced_rate_tooltip')}
                    isLoading={isLoading} />
            </div>
            <div ref={wavePerformanceRef} className="col-12">
                <WavePerformanceChart
                    title={selectedActivities.length > 0 ? t('wave_performance:title_activity') : t('wave_performance:title')}
                    wavesEvents={filteredActivities}
                    isLoading={isWavesEventsLoading}
                    interval={interval}
                    selectedWaves={filteredSelectedWaves}
                    selectedEventType={selectedEventType}
                    setSelectedEventType={setSelectedEventType}
                    exportFile={exportWavePerformance}
                />
            </div>
            <div ref={openPerformanceRef} className="col-12">
                <OpensPerformanceChart
                    emailOpens={filteredEmailOpens}
                    isLoading={isLoading}
                    selectedWaves={filteredSelectedWaves}
                    emailStatistics={filteredEmailStatistics}
                    interval={interval}
                    exportFile={exportOpenPerformance}
                />
            </div>
            <div className="col-12 col-lg-6 mb-4">
                <OpenedLinksTable
                    data={filteredLinkOpens}
                    isLoading={linkIsLoading}
                    fileName={csvFileName}
                    setIsInView={setLinksInView}
                />
            </div>
            <div ref={engagementByDeviceRef} className="col-12 col-lg-6 mb-4">
                <EngagementByDeviceChart
                    data={filteredEngagementByDevice?.eventsByBrowser}
                    dataExport={filteredEngagementByDevice?.events}
                    isLoading={engagementByDeviceIsLoading}
                    tooltip={t('devices:engagement_by_device_platforms_tooltip')}
                    fileName={csvFileName}
                    setIsInView={setDevicesInView}
                    exportFile={exportEngagementByDevice}
                />
            </div>

            <div className="col-12">
                <div className="bc-white p-4 box-shadow border-radius">
                    <Locations
                        data={filteredOpensByLocation}
                        isLoading={isOpensByLocationLoading}
                        fileName={csvFileName}
                        setIsInView={setLocationsInView} />
                </div>
            </div>
        </div>
    );
}

export default EmailsTab;