const ErrorMessageTitle = ({ errorMassage, setShowError, backgroundColor }) => {
    return (<>
        <div className={`${backgroundColor} p-3 d-flex justify-content-between fw-semi-bold`}>
            <div className="c-dark-red">
                {errorMassage}
            </div>
            <div className="cursor-pointer"
                onClick={() => setShowError(false)}>
                <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
            </div>
        </div>
    </>);
}

export default ErrorMessageTitle;