import { useTranslation } from "react-i18next";
import isValidDate from "../../helpers/date/isValidDate";

const DateRange = ({ dateRange, isLoading }) => {
    const { t } = useTranslation(['common']);

    return (<>
        {!isLoading && dateRange?.length > 0 && !!dateRange[0] && !!dateRange[1] && isValidDate(new Date(dateRange[0])) &&
            <span className="text-md text-nowrap py-1 px-2 bc-white c-brand-dark-blue fw-bold rounded-md ms-2">
                {t('short_date', { date: new Date(dateRange[0]) })}
                &nbsp;-&nbsp;
                {t('short_date', { date: new Date(dateRange[1]) })}
            </span>
        }

        {isLoading && <>
            <span className="placeholder-glow ms-2">
                <span className="placeholder c-grey-400 rounded-4 px-2 w-100">
                    placeholder content
                </span>
            </span>
        </>
        }
    </>
    );
}

export default DateRange;