import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveLine } from "@nivo/line";

import ChartSkeleton from "../atoms/ChartSkeleton";

import chartTheme from "../../helpers/constants/chartTheme";
import formatNumber from "../../helpers/formatting/formatNumber";
import formatLargeNumber from "../../helpers/formatting/formatLargeNumber";
import { CHART_LEGEND_MAX_LENGTH, MAX_MOBILE_SCREEN_WIDTH, CHART_LEGEND_MAX_LENGTH_MOBILE, MAX_TABLET_HORIZONTAL_SCREEN_WIDTH } from "../../helpers/constants/constants";
import truncateString from "../../helpers/formatting/truncateString";
import useScreenSize from "../../helpers/hooks/useScreenSize";

const WaveLineChart = ({
    filteredDataSet,
    isLoading,
    min,
    max,
    gridYValues,
    interval }) => {

    const { t } = useTranslation();
    const screnSize = useScreenSize();
    const [showMobileTicks, setShowMobileTicks] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', handleScreenWidth);
        handleScreenWidth();
        return () => {
            document.removeEventListener('resize', handleScreenWidth);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredDataSet])

    const handleScreenWidth = () => {
        if (window.innerWidth < MAX_MOBILE_SCREEN_WIDTH) {
            return setShowMobileTicks(true);
        }

        setShowMobileTicks(false);
    }

    const getTooltipRange = (item, data) => {
        const transform9AmPm = (amPm) => {
            if (amPm === "AM")
                return "PM";
            return "AM";
        }

        const xValue = data?.slice?.points?.find(p => p.serieId === item.id)?.data?.x
        const xValueInt = parseInt(xValue);
        if (interval.value === 1) {
            const amPm = xValue.slice(-2);
            const to = interval.range + xValueInt;
            return `${xValueInt} ${amPm} - ${to > 12 ? 3 : to} ${xValueInt === 9 ? transform9AmPm(amPm) : amPm}`;
        }

        let to = interval.range + xValueInt - 1;

        if (to >= interval.value) {
            to = interval.value;
        }

        if (interval.value === 7 || xValueInt === interval.value)
            return `${t('common:day')} ${xValueInt}`;
        return `${t('common:day')} ${xValueInt} - ${t('common:day')} ${to}`;
    }

    const getTooltipDate = (item, data) => {
        let deploymentDate;

        if (interval.first) {
            deploymentDate = new Date(item.deploymentDate);
        } else {
            const today = new Date();
            today.setDate(today.getDate() - interval.value);
            deploymentDate = today;
        }

        if (interval.value === 1)
            return t('date:day_month_year', { date: new Date(deploymentDate) });

        const xValue = parseInt(data?.slice?.points?.find(p => p.serieId === item.id)?.data?.x);
        let to = interval.range;

        if (interval.value === 7 || to >= interval.value || xValue === interval.value)
            return t('date:day_month_year', { date: new Date(deploymentDate.setDate(deploymentDate.getDate() + (xValue - 1))) });

        let difference = xValue - 1;
        let fromDate = new Date(deploymentDate);
        fromDate.setDate(deploymentDate.getDate() + difference);

        let toDate = new Date(fromDate);

        if (interval.value - xValue < interval.range) {
            toDate.setDate(fromDate.getDate() + interval.value - xValue);
        } else {
            toDate.setDate(fromDate.getDate() + interval.range - 1);
        }

        to = deploymentDate.setDate(deploymentDate.getDate() + to - 1);

        return `${t('date:day_month_year', { date: fromDate })}
         - ${t('date:day_month_year', { date: toDate })}`
    }

    const tooltipRender = (data) => {
        if (!data)
            return;

        return (
            <div className="bc-white border-radius box-shadow chart-tooltip-lg">
                <div className="p-3">
                    {
                        filteredDataSet.map((item, index) => {
                            return (
                                <div key={`wave-line-chart-item-${index}`}>
                                    {index === 0 &&
                                        <span className="c-black-100 fw-light">
                                            {getTooltipRange(item, data)}
                                        </span>}
                                    <div className="d-flex justify-content-between pt-2">
                                        <div className="d-flex align-items-center">
                                            <span className="dot me-2" style={{ backgroundColor: item?.color }}></span>
                                            <span className="text-truncate">{truncateString(item?.waveName, screnSize.width < MAX_TABLET_HORIZONTAL_SCREEN_WIDTH ? CHART_LEGEND_MAX_LENGTH_MOBILE : CHART_LEGEND_MAX_LENGTH)}</span>
                                        </div>
                                        <div>
                                            {formatNumber(data?.slice?.points?.find(p => p.serieId === item.id)?.data?.y)}
                                        </div>
                                    </div>
                                    <div className="c-black-100 fw-light text-sm ps-3">
                                        {getTooltipDate(item, data)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    return (<>
        {!isLoading && filteredDataSet.length > 0 && <>
            {<div style={{ height: 280 }}>
                <ResponsiveLine
                    data={filteredDataSet}
                    style={{ maxWidth: '100%' }}
                    margin={{ top: 20, right: 25, bottom: 40, left: 52 }}
                    xScale={{ type: 'point' }}
                    colors={filteredDataSet.map(m => m.color)}
                    yScale={{
                        type: 'linear', min: min, max: max
                    }}
                    gridYValues={gridYValues}
                    enableGridY={true}
                    theme={chartTheme}
                    axisLeft={{
                        yFormat: ">-.2f",
                        tickSize: 0,
                        tickPadding: 8,
                        tickRotation: 0,
                        tickValues: gridYValues,
                        legend: '',
                        format: function (value) {
                            return formatLargeNumber(value);
                        }
                    }}
                    yFormat=".0s"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 18,
                        tickValues: showMobileTicks && filteredDataSet?.length > 0 ? [filteredDataSet[0]?.data[0]?.x, filteredDataSet[0]?.data[filteredDataSet[0]?.data?.length - 1]?.x] : null,
                        format: function (value) {
                            let pointsCount = Math.floor(interval.value / interval.range) * interval.range + 1;

                            if (pointsCount === +value) {
                                value = interval.value;
                            }

                            if (value === "9 PM") {
                                value = "12 AM";
                            }

                            return interval.value > 1 ? `${t('common:day')} ${value}` : value;
                        }
                    }
                    }
                    maxWidth={'100%'}
                    lineWidth={4}
                    enableGridX={false}
                    curve="monotoneX"
                    enableSlices="x"
                    sliceTooltip={tooltipRender}
                    enablePoints={false}
                    motionConfig="gentle"
                />
            </div>
            }
        </>}
        {!isLoading && filteredDataSet.length === 0 &&
            <div className="position-relative" style={{ height: 280 }}>
                <div
                    style={{ top: '50%' }}
                    className="d-flex flex-column align-items-center justify-content-center position-absolute start-50 translate-middle">
                    <img
                        alt="no-results-found"
                        src={`${process.env.PUBLIC_URL}/images/empty/line.svg`} />
                    <span className="d-block text-md py-2 fw-bold">{t("common:no_info")}</span>
                </div>
            </div>
        }

        {
            isLoading && <ChartSkeleton />
        }
    </>
    );
}

export default WaveLineChart;