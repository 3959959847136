import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import OpenedLinksTableSkeleton from "../../../../components/atoms/OpenedLinksTableSkeleton";

import getTooltipLocation from "../../../../helpers/responsive/getTooltipLocation";
import { useInView } from "react-intersection-observer";

import { CSVLink } from "react-csv";

const OpenedLinksTable = ({ data, isLoading, fileName, setIsInView }) => {
    const { t } = useTranslation(['email']);

    const csvLink = useRef();

    const { ref, inView } = useInView({ threshold: 0 });
    useEffect(() => {
        setIsInView(inView);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView])

    const headers = [
        {
            label: t('link_name'),
            key: "linkName"
        },
        {
            label: t('common:clicks'),
            key: "clicks"
        }
    ];

    const onExportClick = () => {
        if (data.length > 0) {
            csvLink.current.link.click();
        }
    }

    return (
        <div className="bc-white py-4 ps-4 pe-2 rounded-4" ref={ref} style={{ maxWidth: '100%' }}>
            {!isLoading && <>
                <div className="row g-0">
                    <div className="col-12 d-flex justify-content-between text-no-wrap" style={{ height: 40 }}>
                        <div className="d-flex align-items-center">
                            <div className="fw-semi-bold">{t('opened_links')}</div>
                            <img
                                alt="info-circle"
                                className="px-1 opened_links_tooltip-anchor"
                                src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                            <Tooltip anchorSelect={`.opened_links_tooltip-anchor`} place={getTooltipLocation()}>{t('opened_links_tooltip')}</Tooltip>
                        </div>

                        {
                            data?.length > 0 &&
                            <button
                                className="btn-transparent d-flex align-items-center"
                                disabled={isLoading}
                                onClick={() => onExportClick()}>
                                <img
                                    alt="download"
                                    className={`pe-2`}
                                    style={{ width: 25 }}
                                    src={`${process.env.PUBLIC_URL}/images/icons/${isLoading ? 'loader' : 'download'}.svg`} />
                                <span className="c-dark-blue d-none d-md-block">{t('common:export')}</span>
                            </button>
                        }
                    </div>
                    {
                        data?.length > 0 &&
                        <div className="d-flex">
                            <div className="col-6 fw-light ps-2 py-2 my-1">
                                {t('link_name')}
                            </div>
                            <div className="col-6 fw-light text-end pe-4 py-2 my-1">
                                {t('common:clicks')}
                            </div>
                        </div>
                    }
                </div>
                {data?.length > 0 &&
                    <div className="overflow-x-hidden pe-2 col-12" style={{ height: '230px' }}>
                        {data.map((value, index) => {
                            return (
                                <div className={`row g-0 py-2 my-1 ${index === 0 && "border-top-grey-200"} border-bottom-grey-200`}
                                    key={'open-link-' + index}>
                                    <div className="col-8 ps-2">{value.linkName}</div>
                                    <div className="col-4 text-end pe-2">{value.clicks.toLocaleString("en-US")}</div>
                                </div>
                            );
                        })}
                    </div>
                }

                {
                    data?.length === 0 &&
                    <div className="position-relative" style={{ height: '282px' }}>
                        <div
                            style={{ top: '50%' }}
                            className="d-flex flex-column align-items-center justify-content-center position-absolute start-50 translate-middle">
                            <img
                                alt="no-results-found"
                                src={`${process.env.PUBLIC_URL}/images/empty/links.svg`} />
                            <span className="d-block text-md py-2 fw-bold">{t("common:no_info")}</span>
                        </div>
                    </div>
                }

                <div style={{ height: 48 }}>
                    {data?.length > 0 &&
                        <div className="row py-2 my-1 ps-2 pe-4">
                            <div className="col-6">
                                {t('total')}
                            </div>
                            <div className="col-6 text-end">
                                {data.reduce((sum, item) => sum + item?.clicks, 0).toLocaleString("en-US")}
                            </div>
                        </div>
                    }
                </div>
            </>}

            {isLoading &&
                <OpenedLinksTableSkeleton />
            }

            <CSVLink
                data={data}
                filename={`${t('opened_links')} ${t('common:report')} ${fileName}.csv`}
                className="hidden"
                ref={csvLink}
                headers={headers}
                enclosingCharacter=""
                target="_blank" />
        </div >
    );
}

export default OpenedLinksTable;