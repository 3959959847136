import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import Arrow from "../atoms/Arrow";

const DateSelectDropdown = ({ dateOptions, selectedOption, setSelectedOption }) => {

    const { t } = useTranslation('common');

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectOption = (option) => {
        if (!compareOptions(selectedOption, option)) {
            setSelectedOption(option);
        }

        setIsOpen(false);
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    const compareOptions = (option1, option2) => {
        return option1?.value === option2?.value && option1?.first === option2?.first;
    }

    return (
        <div className="d-flex align-items-center justify-content-end mb-4">
            <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
                <div className="dropdown-toggle c-black-100 bc-grey-200 px-2" onClick={() => setIsOpen(!isOpen)}>
                    <span className="pe-2">{t(selectedOption?.label)}</span>
                    <Arrow turnOn={isOpen} />
                </div>
                <ul className="dropdown-menu px-2 py-1">
                    {dateOptions.map((option, index) => (
                        <li
                            key={option.label}
                            className={`py-2 me-3 dropdown-item px-1 ${compareOptions(selectedOption, option) && 'active'} ${index === 5 && 'border-thick-top-grey-200'} `}
                            onClick={() => selectOption(option)}>
                            <div className="row g-0 px-1">
                                <div className="col-9 c-black-100">
                                    {t(option.label)}
                                </div>
                                <div className="col-3 d-flex align-items-center justify-content-end">
                                    {compareOptions(selectedOption, option) &&
                                        <img
                                            alt="selected"
                                            style={{ height: 16, width: 16 }}
                                            src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                    }
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default DateSelectDropdown;