import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import WarningBanner from "../../components/atoms/WarningBanner";
import SearchPanel from "./components/tabs/SearchPanel";
import DateSelectDropdown from "../../components/molecules/DateSelectDropdown";
import EmailsTab from "./components/tabs/EmailsTab";
import ErrorMessageTitle from "../../components/molecules/ErrorMessageTitle";

import AccountsService from "../../services/accounts-service";
import CampaignsService from "../../services/campaigns-service";
import WavesService from "../../services/waves-service";

import Footer from "../../core/Footer";
import { MAX_TABLET_SCREEN_WIDTH, EMAIL_REPORTING_DATA_KEY, FIRST_REDIRECT_KEY } from "../../helpers/constants/constants";
import extendedDateOptions from "../../helpers/constants/extendedDateOptions";

const accountsService = AccountsService();
const campaignsService = CampaignsService();
const wavesService = WavesService();

const EmailsReportingLayoutPage = ({ showLoading, setShowLoading }) => {

    const { t } = useTranslation('common');

    const [accounts, setAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState(accounts);
    const [selectedAccount, setSelectedAccount] = useState();

    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState();
    const [appliedCampaign, setAppliedCampaign] = useState([]);

    const [waves, setWaves] = useState([]);
    const [selectedWaves, setSelectedWaves] = useState([]);
    const [appliedWaves, setAppliedWaves] = useState([]);

    const [showPanel, setShowPanel] = useState(true);
    const [showError, setShowError] = useState(false);

    const [selectedOption, setSelectedOption] = useState();
    const [searchPanelLoading, setSearchPanelLoading] = useState(true);

    const [animation, setAnimation] = useState('');

    useEffect(() => {
        const animate = () => {
            setAnimation('fade-out');
            setTimeout(() => { setAnimation(''); setShowLoading(false); }, 1500)
        }

        const redirect = window.localStorage.getItem(FIRST_REDIRECT_KEY);
        if (!redirect) {
            window.localStorage.setItem(FIRST_REDIRECT_KEY, 1);
            setTimeout(() => animate(), 1500);
        } else setShowLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        accountsService.init();
        campaignsService.init();
        wavesService.init();

        let prevOptions = window.localStorage.getItem(EMAIL_REPORTING_DATA_KEY);
        if (prevOptions) {
            prevOptions = JSON.parse(prevOptions);
            setSelectedOption(prevOptions?.interval);
        } else setSelectedOption(extendedDateOptions[2]);

        setSearchPanelLoading(true);

        Promise.all([
            accountsService.get(),
            campaignsService.get(),
        ]).then(
            ([
                accounts,
                campaigns
            ]) => {
                if (accounts?.data) {
                    setAccounts(accounts.data.sort((a, b) => a.name.localeCompare(b.name)));
                    setFilteredAccounts(accounts.data);
                }

                if (accounts?.data && campaigns?.data) {
                    setCampaigns(campaigns.data);
                    wavesService.get(campaigns.data.map(campaign => campaign.id))
                        .then((response) => {
                            setWaves(response?.data);
                            if (prevOptions) {
                                setSelectedCampaign(prevOptions.campaign);
                                setSelectedWaves(prevOptions.waves);
                                setAppliedWaves(prevOptions.waves);
                                setSelectedAccount(prevOptions.account);
                                setAppliedCampaign(prevOptions.campaign);
                            }
                        });
                }
            }
        ).finally(() => setTimeout(() => setSearchPanelLoading(false), 500));

        return () => {
            accountsService.dispose();
            campaignsService.dispose();
            wavesService.dispose();
        };
    }, []);

    const onSearch = (value) => {
        const filtered = accounts.filter(function (accounts) {
            return accounts?.name?.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredAccounts([...filtered]);
    }

    const onApply = () => {
        if (window.screen.width <= MAX_TABLET_SCREEN_WIDTH)
            setShowPanel(false);
        setShowError(false);
        setAppliedWaves(selectedWaves);
        setAppliedCampaign(selectedCampaign);
    }

    useEffect(() => {
        if (!selectedWaves || selectedWaves.length <= 0 || !selectedOption) return;
        window.localStorage.setItem(EMAIL_REPORTING_DATA_KEY,
            JSON.stringify({
                account: selectedAccount,
                campaign: selectedCampaign,
                waves: selectedWaves,
                interval: selectedOption
            }));
        // eslint-disable-next-line
    }, [appliedWaves]);

    return (
        <>
            {showLoading &&
                <div className={`height-100 w-100 bc-grey-200 loader ${animation}`}>
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <img
                            alt="dcm-logo"
                            role="button"
                            style={{ height: 200 }}
                            src={`${process.env.PUBLIC_URL}/images/animations/dcm-loader.svg`} />
                    </div>
                </div>
            }

            <div className="d-flex align-items-start">
                <div className={`overlay bc-white ${showPanel ? '' : 'd-none'}`}>
                    <div className="height-100 px-2">
                        <SearchPanel
                            isLoading={searchPanelLoading}
                            campaigns={campaigns}
                            selectedAccount={selectedAccount}
                            setSelectedAccount={setSelectedAccount}
                            filteredAccounts={filteredAccounts}
                            setSelectedCampaign={setSelectedCampaign}
                            selectedCampaign={selectedCampaign}
                            waves={waves}
                            selectedWaves={selectedWaves}
                            appliedWaves={appliedWaves}
                            setSelectedWaves={setSelectedWaves}
                            toggle={setShowPanel}
                            onSearch={onSearch}
                            showError={setShowError}
                            onApply={onApply}
                        />
                    </div>
                </div>
                <div className="w-100 height-md-100 d-flex flex-column" style={{ overflowY: "auto", overflowX: "hidden" }}>
                    <div className="container-fluid bc-grey-200 flex-grow-1 mb-4 px-4 page-max-width">
                        <div className="row">
                            <div className="col-12"> <WarningBanner /></div>
                            {showError &&
                                <div className="col-12">
                                    <ErrorMessageTitle
                                        errorMassage={t("max_waves_error")}
                                        showError={showError}
                                        setShowError={setShowError}
                                        backgroundColor={"bc-light-yellow"}
                                    />
                                </div>
                            }
                            <div className="col-9 pt-3">
                                <button className="bc-grey-200 border-0 d-flex align-items-center"
                                    style={{ height: 31 }}
                                    onClick={() => setShowPanel(!showPanel)}>
                                    <img alt="menu" src={`${process.env.PUBLIC_URL}/images/icons/menu.svg`} />
                                    <span className="d-none c-dark-blue fw-semi-bold d-md-inline ps-2">
                                        {appliedWaves.length === 0 ? t("wave_name") : `${appliedCampaign.name} ${t('email:selected')} ${appliedWaves.length} ${t('email:waves')}`}
                                    </span>
                                </button>
                            </div>
                            <div className="col-3 pt-3">
                                <DateSelectDropdown
                                    dateOptions={extendedDateOptions}
                                    selectedOption={selectedOption}
                                    setSelectedOption={setSelectedOption} />
                            </div>
                        </div>

                        <EmailsTab
                            selectedWaves={appliedWaves}
                            interval={selectedOption}
                            selectedCampaign={selectedCampaign}
                        />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default EmailsReportingLayoutPage;