import formatNumber from "../../helpers/formatting/formatNumber";
import { BAR_LINE_CHART_BAR_COLORS, CHART_LEGEND_MAX_LENGTH, CHART_LEGEND_MAX_LENGTH_MOBILE, MAX_TABLET_HORIZONTAL_SCREEN_WIDTH } from "../../helpers/constants/constants";
import truncateString from "../../helpers/formatting/truncateString";
import useScreenSize from "../../helpers/hooks/useScreenSize";

const CustomTooltipRow = ({ color, name, count, date }) => {
    const screnSize = useScreenSize();

    return (<>
        {date &&
            <div className="col-12">
                <div className="d-flex justify-content-between pt-2">
                    <div className="d-flex align-items-center">
                        {color !== BAR_LINE_CHART_BAR_COLORS[3] &&
                            <span className="dot" style={{ background: color }}></span>
                        }
                        {color === BAR_LINE_CHART_BAR_COLORS[3] &&
                            <img
                                alt="dot"
                                style={{ height: 12, width: 12 }}
                                src={`${process.env.PUBLIC_URL}/images/icons/ellipse.svg`} />
                        }
                        <span className="text-truncate c-grey-800 ps-2">{truncateString(name, screnSize.width < MAX_TABLET_HORIZONTAL_SCREEN_WIDTH ? CHART_LEGEND_MAX_LENGTH_MOBILE : CHART_LEGEND_MAX_LENGTH)}</span>
                    </div>
                    <div>{formatNumber(count)}</div>
                </div>
                <div className="c-black-100 fw-light text-sm ps-3">{date}</div>
            </div>
        }
    </>);
}

export default CustomTooltipRow;