import { jwtDecode } from "jwt-decode";
import {
    VIDEO_REPORTING_DATA_KEY,
    EMAIL_REPORTING_DATA_KEY,
    LANDING_REPORTING_DATA_KEY,
    ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
    FIRST_REDIRECT_KEY,
    LANGUAGE_KEY
} from "../../helpers/constants/constants";

const AuthHandler = () => {
    const AUTH_CHANGE_EVENT = 'auth';
    const AUTH_ROUTE_PATH = '/secure';

    const getSavedAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY);

    const reset = () => {
        window.localStorage.removeItem(ACCESS_TOKEN_KEY);
        window.localStorage.removeItem(REFRESH_TOKEN_KEY);
        window.localStorage.removeItem(EMAIL_REPORTING_DATA_KEY);
        window.localStorage.removeItem(LANDING_REPORTING_DATA_KEY);
        window.localStorage.removeItem(VIDEO_REPORTING_DATA_KEY);
        window.localStorage.removeItem(FIRST_REDIRECT_KEY);
        window.localStorage.removeItem(LANGUAGE_KEY);
        window.dispatchEvent(new Event(AUTH_CHANGE_EVENT));
    }

    const retrieve = () => {
        let token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
        let refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);

        let params = (new URL(document.location)).searchParams;
        let accessTokenParam = params.get("accessToken");
        let refreshTokenParam = params.get("refreshToken");

        if ((!token || !refreshToken) || (accessTokenParam && refreshTokenParam)) {
            if (refreshTokenParam) window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshTokenParam);
            if (accessTokenParam) {
                window.localStorage.setItem(ACCESS_TOKEN_KEY, accessTokenParam);
                window.history.pushState({}, document.title, window.location.pathname);
            } else {
                if (window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
                return;
            }
        }
    }

    const isAuthenticated = () => !!getSavedAccessToken() && !!jwtDecode(getSavedAccessToken());

    const getAuth = () => ({
        isAuthenticated: isAuthenticated()
    });

    const getCurrentCustomer = () => {
        var token = getSavedAccessToken();
        if (!token) return;

        var decodedToken = jwtDecode(token);
        return decodedToken?.currentcustomerid;
    }

    const getCurrentLanguage = () => {
        var token = getSavedAccessToken();
        if (!token) return;

        var decodedToken = jwtDecode(token);
        return decodedToken?.language;
    }

    const subscribe = (listener, silent = true) => {
        if (!listener) return () => { };
        const callback = () => listener(getAuth());
        window.addEventListener(AUTH_CHANGE_EVENT, callback);
        if (!silent) callback();
        return () => window.removeEventListener(AUTH_CHANGE_EVENT, callback);
    }

    return {
        isAuthenticated,
        reset,
        retrieve,
        getAuth,
        subscribe,
        getCurrentCustomer,
        getCurrentLanguage
    };
}

export default AuthHandler;