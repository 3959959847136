import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

import ExportFile from "../../../../components/molecules/ExportFile";
import ChartSkeleton from "../../../../components/atoms/ChartSkeleton";
import BarLineChart from "../../../../components/organisms/BarLineChart";

import calcRate from "../../../../helpers/calculations/calcRate";
import generateIntervals from "../../../../helpers/formatting/generateIntervals";
import getTooltipLocation from "../../../../helpers/responsive/getTooltipLocation";
import {
    BAR_LINE_CHART_ALPHA,
    BAR_LINE_CHART_DEFAULT_GRID,
    BAR_LINE_CHART_BAR_COLORS,
    CHART_LEGEND_MAX_LENGTH
} from "../../../../helpers/constants/constants";

const OpensPerformanceChart = ({ emailOpens, isLoading, selectedWaves, emailStatistics, interval, exportFile }) => {
    const { t } = useTranslation(['email']);

    const [openVsOpenRate, setOpensVsRate] = useState([]);
    const [min, setMin] = useState(BAR_LINE_CHART_DEFAULT_GRID[0]);
    const [max, setMax] = useState(BAR_LINE_CHART_DEFAULT_GRID[5]);
    const [gridValues, setGridValues] = useState(BAR_LINE_CHART_DEFAULT_GRID);

    useEffect(() => {
        if (!(emailOpens?.length > 0)) return;

        const openVsOpenRate = emailOpens.map((o, index) => {
            let result = {};
            result['x'] = o.from;
            result['v6'] = (o?.v0 ?? 0) + (o?.v1 ?? 0) + (o?.v2 ?? 0) + (o?.v3 ?? 0);
            result['v4'] = emailStatistics?.totalDelivered === 0 ? 0 : calcRate(result['v6'], emailStatistics?.totalDelivered) + BAR_LINE_CHART_ALPHA;
            result['v5'] = getTooltipRange(o.from);
            result['position'] = {
                first: index === 0,
                second: index === 1,
                last: (emailOpens.length - 1) === index

            };

            if (o?.v0 || o?.v0 === 0) {
                result['v0'] = o?.v0;
                result['v0c'] = BAR_LINE_CHART_BAR_COLORS[0];
                result['v0r'] = getTooltipDate(o?.v0d, o.from);
                result['v0n'] = o?.v0n;
            }
            if (o?.v1 || o?.v1 === 0) {
                result['v1'] = o?.v1;
                result['v1c'] = `repeating-linear-gradient(300deg, ${BAR_LINE_CHART_BAR_COLORS[1]}, ${BAR_LINE_CHART_BAR_COLORS[0]} 25.3%)`;
                result['v1r'] = getTooltipDate(o?.v1d, o.from);
                result['v1n'] = o?.v1n;
            }
            if (o?.v2 || o?.v2 === 0) {
                result['v2'] = o?.v2;
                result['v2c'] = BAR_LINE_CHART_BAR_COLORS[2];
                result['v2r'] = getTooltipDate(o?.v2d, o.from);
                result['v2n'] = o?.v2n;
            }
            if (o?.v3 || o?.v3 === 0) {
                result['v3'] = o?.v3;
                result['v3c'] = BAR_LINE_CHART_BAR_COLORS[3];
                result['v3r'] = getTooltipDate(o?.v3d, o.from);
                result['v3n'] = o?.v3n;
            }
            return result;
        });

        const combinedArrayTotal = openVsOpenRate.map(e => e?.v6);
        const intervals = generateIntervals([...combinedArrayTotal, 0]);

        setMin(Math.min(...intervals));
        setMax(Math.max(...intervals) * 1.1);
        setGridValues(intervals);
        setOpensVsRate(openVsOpenRate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailOpens, emailStatistics])

    const getTooltipRange = (item) => {
        const transform9AmPm = (amPm) => {
            if (amPm === "AM")
                return "PM";
            return "AM";
        }

        const xValue = item;
        const xValueInt = parseInt(xValue);
        if (interval.value === 1) {
            const amPm = xValue.slice(-2);
            const to = interval.range + xValueInt;
            return `${xValueInt} ${amPm} - ${to > 12 ? 3 : to} ${xValueInt === 9 ? transform9AmPm(amPm) : amPm}`;
        }

        let to = interval.range + xValueInt - 1;

        if (to >= interval.value) {
            to = interval.value;
        }

        if (interval.value === 7 || xValueInt === interval.value)
            return `${t('common:day')} ${xValueInt}`;
        return `${t('common:day')} ${xValueInt} - ${t('common:day')} ${to}`;
    }

    const getTooltipDate = (date, value) => {
        let deploymentDate;

        if (interval.first) {
            deploymentDate = new Date(date);
        } else {
            const today = new Date();
            today.setDate(today.getDate() - interval.value);
            deploymentDate = today;
        }

        if (interval.value === 1)
            return t('date:day_month_year', { date: new Date(deploymentDate) });

        const xValue = parseInt(value);
        let to = interval.range;

        if (interval.value === 7 || to >= interval.value || xValue === interval.value)
            return t('date:day_month_year', { date: new Date(deploymentDate.setDate(deploymentDate.getDate() + (xValue - 1))) });

        let difference = xValue - 1;
        let fromDate = new Date(deploymentDate);
        fromDate.setDate(deploymentDate.getDate() + difference);

        let toDate = new Date(fromDate);

        if (interval.value - xValue < interval.range) {
            toDate.setDate(fromDate.getDate() + interval.value - xValue);
        } else {
            toDate.setDate(fromDate.getDate() + interval.range - 1);
        }

        to = deploymentDate.setDate(deploymentDate.getDate() + to - 1);

        return `${t('date:day_month_year', { date: fromDate })}
         - ${t('date:day_month_year', { date: toDate })}`
    }

    const exportChart = (format) => {
        exportFile(format);
    }

    return (
        <div className="bc-white p-4 rounded-4 mb-4 exportable">
            <div className="row justify-content-between g-0">
                <div className="col-12 d-flex align-items-center justify-content-between" style={{ height: 40 }}>
                    {!isLoading &&
                        <>
                            <div className="text-nowrap">
                                <span className="c-dark-blue fw-semi-bold">{t("open_performance")}</span>
                                <img
                                    data-html2canvas-ignore
                                    alt="info-circle"
                                    className={`px-1 tooltip-anchor-opens-performance-chart`}
                                    src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                                <Tooltip className="text-wrap" anchorSelect={`.tooltip-anchor-opens-performance-chart`} place={getTooltipLocation()}>
                                    {t("open_performance_tooltip")}
                                </Tooltip>
                            </div>

                            <div className="d-flex align-items-center">
                                {emailStatistics?.totalDelivered > 0 && openVsOpenRate?.length > 0 &&
                                    <div className="d-none d-md-flex px-2 pe-md-4">
                                        <span className="pe-2">
                                            <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/chart.svg`} />
                                        </span>
                                        <span className="pe-3">
                                            {t('open_count')}
                                        </span>
                                        <span className="pe-2">
                                            <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/diagram.svg`} />
                                        </span>
                                        <span>
                                            {t('open_rate')}
                                        </span>
                                    </div>
                                }

                                {!!emailStatistics?.totalDelivered &&
                                    <span data-html2canvas-ignore>
                                        <ExportFile
                                            exportFile={exportChart} />
                                    </span>
                                }
                            </div>
                        </>
                    }

                    {
                        isLoading &&
                        <div className="d-flex align-items-center placeholder-glow w-50">
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                        </div>
                    }
                </div>
                <div className="col-12 d-flex flex-wrap">

                    {emailStatistics?.totalDelivered > 0 && openVsOpenRate?.length > 0 &&
                        <div className="d-flex d-md-none mb-2">
                            <span className="pe-2">
                                <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/chart.svg`} />
                            </span>
                            <span className="pe-3">
                                {t('open_count')}
                            </span>
                            <span className="pe-2">
                                <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/diagram.svg`} />
                            </span>
                            <span>
                                {t('open_rate')}
                            </span>
                        </div>
                    }

                    {!isLoading && emailStatistics?.totalDelivered > 0 && openVsOpenRate?.length > 0 && selectedWaves?.sort((a, b) => a.waveName.localeCompare(b.waveName))?.map((waves, index) =>
                    (
                        <div key={`selected-waves-list-${index}`}
                            style={{ height: 32, flexBasis: waves?.waveName?.length > CHART_LEGEND_MAX_LENGTH ? '50%' : 'auto' }}
                            className={`legend-item text-truncate tooltip-anchor-opens-legend-${index} cursor-default d-flex py-1 align-items-center ${index + 1 !== selectedWaves.length && 'pe-3'}`}>
                            {index !== 3 && index !== 1 &&
                                <div className="dot flex-shrink-0" style={{ background: openVsOpenRate[0][`v` + index + 'c'] }}></div>
                            }
                            {index === 1 &&
                                <img
                                    alt="dot"
                                    style={{ height: 12, width: 12 }}
                                    src={`${process.env.PUBLIC_URL}/images/icons/striped-dot.svg`} />
                            }
                            {index === 3 &&
                                <img
                                    alt="dot"
                                    style={{ height: 12, width: 12 }}
                                    src={`${process.env.PUBLIC_URL}/images/icons/ellipse.svg`} />
                            }
                            <span className="ms-2 text-truncate">{waves?.waveName}</span>

                            {
                                interval.first && <span className="text-nowrap">:&nbsp;{t('common:short_date', { date: new Date(waves?.deploymentDate) })}</span>
                            }

                            {
                                waves?.waveName.length > CHART_LEGEND_MAX_LENGTH &&
                                <Tooltip className="legend-tooltip" anchorSelect={`.tooltip-anchor-opens-legend-${index}`} place="top">{waves?.waveName}</Tooltip>
                            }
                        </div>
                    ))}

                    {
                        isLoading &&
                        <div className="d-flex align-items-center placeholder-glow w-50" style={{ height: 32 }}>
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                            <div className="placeholder w-25 c-grey-300 rounded-4 px-3 me-3"></div>
                        </div>
                    }
                </div>

            </div>
            {!isLoading && emailStatistics?.totalDelivered > 0 && openVsOpenRate?.length > 0 &&
                <BarLineChart
                    scale={emailStatistics?.totalDelivered / 100}
                    data={openVsOpenRate}
                    min={min}
                    max={max}
                    gridValues={gridValues}
                    interval={interval} />
            }
            {!isLoading && !emailStatistics?.totalDelivered &&
                <div className="position-relative" style={{ height: 280 }}>
                    <div
                        style={{ top: '35%' }}
                        className="d-flex flex-column align-items-center justify-content-center position-absolute start-50 translate-middle">
                        <img
                            alt="no-results-found"
                            src={`${process.env.PUBLIC_URL}/images/empty/bar.svg`} />
                        <span className="d-block text-md py-2 fw-bold">{t("common:no_info")}</span>
                    </div>
                </div>
            }
            {isLoading && <ChartSkeleton
                addAxisRight={true} />}
        </div>
    );
}

export default OpensPerformanceChart;