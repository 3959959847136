import React from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { default as ReactSelect, components } from "react-select";
import getTooltipLocation from "../../helpers/responsive/getTooltipLocation";

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <img alt="close" role="button" src="/images/icons/x.svg" style={{ height: 24, width: 24 }} />
        </components.ClearIndicator>);
}

const Option = ({ getValue, ...props }) => {
    return (
        <div>
            <components.Option {...props}
                className={`d-flex align-items-center ${getValue().length === 4 && !props.isSelected && 'disabled'}`}>
                <input
                    className="form-check-input checkbox mt-0"
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <label className="ps-2">{props.label}</label>
            </components.Option>
        </div>
    );
};

const MenuList = ({ selectProps, ...props }) => {
    const { onInputChange, inputValue, onMenuInputFocus } = selectProps;
    const { t } = useTranslation('email');

    const ariaAttributes = {
        "aria-autocomplete": "list",
        "aria-label": selectProps["aria-label"],
        "aria-labelledby": selectProps["aria-labelledby"]
    };

    return (
        <div>
            {props?.options?.reduce((a, b) => a + b.options.length, 0) > 7 &&
                <div className="d-flex py-2">
                    <input
                        autoCorrect="off"
                        autoComplete="off"
                        spellCheck="false"
                        type="text"
                        className="dcm-input"
                        value={inputValue}
                        name="search-input"
                        onChange={(e) =>
                            onInputChange(e.currentTarget.value, {
                                action: "input-change"
                            })
                        }
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            e.target.focus();
                        }}
                        onTouchEnd={(e) => {
                            e.stopPropagation();
                            e.target.focus();
                        }}
                        onFocus={onMenuInputFocus}
                        placeholder={t('search_by_activity')}
                        {...ariaAttributes}
                        aria-label="search" />
                    {
                        inputValue &&
                        <button type="button" className="btn bg-transparent p-0" style={{ marginLeft: "-25px", zIndex: 100 }}>
                            <img
                                alt="close"
                                className="px-1"
                                onClick={() => onInputChange('', {
                                    action: "input-change"
                                })}
                                src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                        </button>
                    }
                </div>
            }

            <components.MenuList {...props} selectProps={selectProps} />
        </div>
    );
};

const NoOptionsMessage = ({ selectProps, ...props }) => {
    const { t } = useTranslation();

    return (<div className="text-center">{t('common:no_results')}</div>)
}

const ValueContainer = ({ children, getValue, selectProps, ...props }) => {
    const { t } = useTranslation();

    let valueLength = getValue().length;
    let containerText;

    if (valueLength === 0) containerText = `${t('common:select')} ${t('email:activities_plural')}`;

    if (valueLength > 0) {
        containerText = `${valueLength} ${valueLength !== 1 ? t('email:activities_plural') : t('email:activities_singular')} ${t('common:selected')}`;
        if (valueLength === 4) {
            containerText += ' (max 4)';
        }
    }

    return (
        <components.ValueContainer {...props} selectProps={selectProps}>
            {containerText}
            {React.Children.map(children, child => {
                return child
            })}
        </components.ValueContainer>
    );
};

const formatGroupLabel = (data) => (
    <div className="d-flex justify-content-between">
        <span>{data.label}</span>
        <span>{data.options.length}</span>
    </div>
);

const ActivitiesSelector = ({ activities, setSelectedActivities, selectedWaves }) => {
    const { t } = useTranslation(['email']);
    const [state, setState] = useState({ optionSelected: null });
    const [options, setOptions] = useState([]);

    const containerRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const onDomClick = (e) => {
        let menu = containerRef?.current?.querySelector(".activities__menu");

        if (
            !containerRef?.current?.contains(e.target) ||
            !menu ||
            !menu?.contains(e.target)
        ) {
            setIsFocused(false);
            setInputValue("");
        } else {
            setIsFocused(true);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", onDomClick);

        return () => {
            document.removeEventListener("mousedown", onDomClick);
        };
    }, []);

    useEffect(() => {
        if (!activities.length) return;

        const groupedOptions = [];
        const activitiesOptions = activities.map(activity => ({
            value: activity.activityId,
            label: activity.activityName,
            waveId: activity.waveId,
            deploymentDate: activity.deploymentDate
        }));

        for (let i = 0; i < selectedWaves.length; i++) {
            const waveGroup = {
                label: selectedWaves[i].waveName,
                options: activitiesOptions.filter(ao => ao.waveId === selectedWaves[i].id)
            }
            groupedOptions.push(waveGroup);
        }

        setOptions(groupedOptions);
        setState({
            optionSelected: null
        });
        setSelectedActivities([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activities])

    const handleChange = (selected) => {
        if (selected.length > 4) return;

        setState({
            optionSelected: selected
        });

        setSelectedActivities(selected);
    };

    return (
        <div className="d-flex bc-white p-4 box-shadow border-radius flex-wrap">
            <div className="col-12 col-lg-3 d-flex align-items-center" style={{ height: 42 }}>
                <span className="dark-blue text-truncate">{t('select_activity')}</span>
                <img
                    alt="info-circle"
                    className="px-1 tooltip-anchor-select-activities"
                    src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                <Tooltip anchorSelect=".tooltip-anchor-select-activities" place={getTooltipLocation()}>{t('select_activity_description')}</Tooltip>
            </div>

            <div className="col-12 col-lg-9 mt-2 mt-lg-0" ref={containerRef}>
                <ReactSelect
                    className="activities-container"
                    classNamePrefix="activities"
                    options={options}
                    isMulti
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    hideSelectedOptions={false}
                    isSearchable={false}
                    inputValue={inputValue}
                    isClearable={true}
                    formatGroupLabel={formatGroupLabel}
                    onMenuInputFocus={() => setIsFocused(true)}
                    onInputChange={(value, action) => { if (action.action === "input-change") setInputValue(value); }}
                    components={{
                        MenuList,
                        Option,
                        ValueContainer,
                        ClearIndicator,
                        NoOptionsMessage
                    }}
                    onChange={handleChange}
                    value={state.optionSelected}
                    {...{
                        menuIsOpen: isFocused || undefined,
                        isFocused: isFocused || undefined
                    }}
                />
            </div>
        </div>
    );
}

export default ActivitiesSelector;