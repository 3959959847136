const extendedDateOptions = [
    { label: "first_day", value: 1, range: 3, first: true },
    { label: "first_7_days", value: 7, range: 1, first: true },
    { label: "first_30_days", value: 30, range: 4, first: true },
    { label: "first_60_days", value: 60, range: 7, first: true },
    { label: "first_90_days", value: 90, range: 13, first: true },
    { label: "last_day", value: 1, range: 3, first: false },
    { label: "last_7_days", value: 7, range: 1, first: false },
    { label: "last_30_days", value: 30, range: 4, first: false },
    { label: "last_60_days", value: 60, range: 7, first: false },
    { label: "last_90_days", value: 90, range: 13, first: false},
];

export default extendedDateOptions;