const filterLinks = (links, filters) => {
    if(!links?.length) return [];
    if(filters?.length > 0)
        links = links.filter(link => {
            return filters.some(filter =>  filter?.value === link?.activityId);
        });
 
    let linkNames = links.map(p => p?.linkName);
    linkNames = [...new Set(linkNames)];
    linkNames = linkNames.filter(element => element !== undefined);
    return linkNames.map(linkName => {
        return { linkName : linkName,
            clicks: links
                .filter(item => item?.linkName === linkName)
                .reduce((sum, item) => sum + item?.count, 0)
        };
    }).sort((a, b) => (a.clicks > b.clicks ? -1 : 1));
}
export default filterLinks;