import axios from "axios";
import qs from 'qs';

function VideosService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const get = () => axios
        .get('/videos', config())
        .catch(error => error);

    const getStats = (videoId) => axios
        .get('/videos/stats', {
            params: {
                id: videoId
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    const getProgressStats = (videoId, from, interval, first) => axios
        .get('/videos/progress', {
            params: {
                id: videoId,
                from: from,
                interval: interval,
                first: first
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    const getAccounts = () => axios
        .get('/videos/accounts', config())
        .catch(error => error);

    const getCampaigns = () => axios
        .get('/videos/campaigns', config())
        .catch(error => error);

    const getTotalPublished = (videoId) => axios
        .get('/videos/published-views', {
            params: {
                id: videoId
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);


    const getDeploymentDate = (videoId) => axios
        .get('/videos/deployed-date', {
            params: {
                id: videoId
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    const getViews = (videoId, from, interval, first) => axios
        .get('/videos/views', {
            params: {
                id: videoId,
                from: from,
                interval: interval,
                first: first
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    return {
        init,
        dispose,
        get,
        getStats,
        getProgressStats,
        getAccounts,
        getCampaigns,
        getTotalPublished,
        getDeploymentDate,
        getViews
    };
}

export default VideosService;