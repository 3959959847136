import { createContext, useState } from 'react';

export const UniqueRecipientsContext = createContext();

export const UniqueRecipientsContextProvider = props => {
    const [showUniqueRecipients, setShowUniqueRecipients] = useState(true);

    return (
        <UniqueRecipientsContext.Provider value={[showUniqueRecipients, setShowUniqueRecipients]}>
            {props.children}
        </UniqueRecipientsContext.Provider>
    );
};