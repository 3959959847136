import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import '../../node_modules/moment/locale/fr';
import header_en from "../locales/en/header.json";
import header_fr from "../locales/fr/header.json";
import common_en from "../locales/en/common.json";
import common_fr from "../locales/fr/common.json";
import panel_en from "../locales/en/panel.json";
import panel_fr from "../locales/fr/panel.json";
import video_en from "../locales/en/video.json";
import video_fr from "../locales/fr/video.json";
import errors_en from "../locales/en/errors.json";
import errors_fr from "../locales/fr/errors.json";
import footer_en from "../locales/en/footer.json";
import footer_fr from "../locales/fr/footer.json";
import terms_en from "../locales/en/terms.json";
import terms_fr from "../locales/fr/terms.json";
import email_en from "../locales/en/email.json";
import email_fr from "../locales/fr/email.json";
import wave_performance_en from "../locales/en/wave_performance.json";
import wave_performance_fr from "../locales/fr/wave_performance.json";
import date_en from "../locales/en/date.json";
import date_fr from "../locales/fr/date.json";
import locations_en from "../locales/en/locations.json";
import locations_fr from "../locales/fr/locations.json";
import landing_en from "../locales/en/landing.json";
import landing_fr from "../locales/fr/landing.json";
import devices_en from "../locales/en/devices.json";
import devices_fr from "../locales/fr/devices.json";
import { LANGUAGE_KEY } from "../helpers/constants/constants";

const resources = {
    en: {
        header: header_en,
        common: common_en,
        panel: panel_en,
        video: video_en,
        errors: errors_en,
        footer: footer_en,
        terms: terms_en,
        email: email_en,
        wave_performance: wave_performance_en,
        date: date_en,
        locations: locations_en,
        landing: landing_en,
        devices: devices_en
    },
    fr: {
        header: header_fr,
        common: common_fr,
        panel: panel_fr,
        video: video_fr,
        errors: errors_fr,
        footer: footer_fr,
        terms: terms_fr,
        email: email_fr,
        wave_performance: wave_performance_fr,
        date: date_fr,
        locations: locations_fr,
        landing: landing_fr,
        devices: devices_fr
    }
};

i18n.use(initReactI18next).init({
    resources: resources,
    lng: localStorage.getItem(LANGUAGE_KEY) || "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
        format: function (value, format, lng) {
            if(value instanceof Date) {
                var date = moment(value);
                date.locale(lng);
                return date.format(format);
            }
            return value;
        }
    },
});

export default i18n;