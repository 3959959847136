import axios from "axios";
import qs from 'qs';

function EmailActivitiesService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const get = (waveId) => axios
        .get('/activities', {
            params: {
                waveId: waveId
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    return {
        init,
        dispose,
        get
    };
}

export default EmailActivitiesService;