// Email Event Types
export const EMAIL_SENT = 20;
export const EMAIL_SENT_FAILED = 21;
export const EMAIL_OPENED = 23;
export const EMAIL_UNSUBSCRIBED = 24;
export const EMAIL_LINK_CLICKED = 27;

// Landing Event Types
export const LANDING_VISIT = 100;
export const LANDING_PERFORMED_ACTION = 103;
export const LANDING_PAGE_LEAVE = 104;

// Screen width
export const MAX_MOBILE_SCREEN_WIDTH = 576;
export const MAX_TABLET_SCREEN_WIDTH = 768;
export const MAX_TABLET_HORIZONTAL_SCREEN_WIDTH = 992;
export const MAX_DESKTOP_SCREEN_WIDTH = 1400;

// Local storage key
export const EMAIL_REPORTING_DATA_KEY = 'email_data';
export const LANDING_REPORTING_DATA_KEY = 'landing_data';
export const VIDEO_REPORTING_DATA_KEY = 'video_data';
export const ACCESS_TOKEN_KEY = 'access-token';
export const REFRESH_TOKEN_KEY = 'refresh-token';
export const FIRST_REDIRECT_KEY = 'first-redirect';
export const LANGUAGE_KEY = 'language';

// Colors
export const BAR_LINE_CHART_BAR_COLORS = ["#EBA059", "#FFEEC2", "#F1C85E", "#FFE6CF"];
export const BAR_LINE_CHART_LINE_COLOR = '#1B598E';

// Constants
export const BAR_LINE_CHART_ALPHA = 1.01;
export const BAR_LINE_CHART_DEFAULT_GRID = [0, 500, 1000, 1500, 2000, 2500];

// Line chart constants
export const CHART_MARGIN_LG = 40;
export const CHART_MARGIN_XL = 55;

export const CHART_MAX = 10000;
export const CHART_MAX_MD = 100000;

export const CHART_INTERVAL_6_HOURS = 0.25;
export const CHART_INTERVAL_DAY = 1;
export const CHART_INTERVAL_MONTHS = 20;
export const CHART_INTERVAL_YEAR = 21;

export const CHART_LEGEND_MAX_LENGTH = 35;
export const CHART_LEGEND_MAX_LENGTH_MOBILE = 25;

// File export constants
export const JPEG = '.jpeg';
export const PNG = '.png';
export const CSV = '.csv';

export const PIE_CHART_COLORS = ['#1B598E', '#35A2CD', '#8CD0EB', '#C1EDFF', '#EBA059', '#FFC107', '#D29047', '#ADB54F'];