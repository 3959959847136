import { createContext, useState } from 'react';

export const WarningContext = createContext();

export const WarningContextProvider = props => {
    const [state, setState] = useState({
        message: '',
        bc_color: '',
        show: false,
        close: () => { }
    });

    return (
        <WarningContext.Provider value={[state, setState]}>
            {props.children}
        </WarningContext.Provider>
    );
};