import { useEffect, useState, useRef, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Map, { NavigationControl, Source, Layer } from 'react-map-gl';
import getTooltipLocation from "../../helpers/responsive/getTooltipLocation";
import { Tooltip } from "react-tooltip";
import { CSVLink } from "react-csv";

import { useInView } from "react-intersection-observer";

import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { formatNumber, formatDecimal } from "../../helpers/formatting/formatNumber";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Locations = ({ data, isLoading, fileName, setIsInView }) => {
    const { t } = useTranslation(['locations']);

    const { ref, inView } = useInView({ threshold: 0 });
    useEffect(() => {
        setIsInView(inView);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView])

    const csvLink = useRef();
    const maxZoom = 8;
    const zoomStep = 2;

    const containerRef = useRef(null);
    const mapRef = useRef(null);
    const fullscreenMapRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [lng, setLng] = useState(-79.387054);
    const [lat, setLat] = useState(43.642567);
    // eslint-disable-next-line
    const [zoom, setZoom] = useState(3);
    const [searchValue, setSearchValue] = useState("");

    var GeoJSON = require('geojson');

    const [locations, setLocations] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);

    const emptyLocations = [...Array(10).keys()];

    const [geoData, setGeoData] = useState();

    const headers = [
        {
            label: t('city'),
            key: "city"
        },
        {
            label: t('state'),
            key: "state"
        },
        {
            label: t('country'),
            key: "country"
        },
        {
            label: t('opens'),
            key: "count"
        },
        {
            label: '%',
            key: "percent"
        }
    ];

    useEffect(() => {
        const result = GeoJSON.parse(data, { Point: ['latitude', 'longitude'] });
        setGeoData(result);

        if (!data?.length) {
            setLocations([]);
            return;
        }

        let grouped = data.reduce((acc, location) => {
            let key = `${location.city}, ${location.state}, ${location.country}`;
            if (!acc[key]) {
                acc[key] = {
                    name: key,
                    city: location.city,
                    state: location.state,
                    country: location.country,
                    latitude: location.latitude,
                    longitude: location.longitude,
                    count: 0
                };
            }
            acc[key].count += location.opensCount;
            return acc;
        }, {});

        let locationsArray = Object.values(grouped);
        locationsArray.sort((a, b) => b.count - a.count);

        let highestOpens = locationsArray[0];
        setLng(highestOpens.longitude);
        setLat(highestOpens.latitude);

        let totalOpens = locationsArray.reduce((total, location) => total + location.count, 0);

        setLocations(
            locationsArray.map((location) => ({
                ...location,
                percent: formatDecimal(((location.count / totalOpens) * 100), 2) + '%'
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        setFilteredLocations(
            locations.filter(location =>
                location.city?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                location.state?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                location.country?.toLowerCase()?.includes(searchValue.toLowerCase())
            )
        );

    }, [searchValue, locations])

    function NavigateButton() {
        const onClick = () => {
            setModalOpen(!modalOpen);
        };

        return <img
            alt="fullscreen"
            onClick={onClick}
            className="cursor-pointer"
            style={{ position: 'absolute', marginTop: 10, marginRight: 10, right: 0, height: 29, width: 29 }}
            src={`${process.env.PUBLIC_URL}/images/icons/fullscreen.svg`} />;
    }

    const clusterLayer = {
        id: 'clusters',
        type: 'circle',
        source: 'opens',
        filter: ['has', 'point_count'],
        layout: {
        },
        paint: {
            'circle-color': ['step', ['get', 'point_count'], '#35A2CD', 750, '#35A2CD'],
            'circle-radius': ['step', ['get', 'sum'], 10, 100, 12, 1000, 14, 10000, 18, 100000, 22, 1000000, 22]
        }
    };

    const clusterCountLayer = {
        id: 'cluster-count',
        type: 'symbol',
        source: 'opens',
        filter: ['has', 'point_count'],
        layout: {
            'text-field': ["get", "sum"],
            'text-font': ["Open Sans Regular", "Arial Unicode MS Regular"],
            'text-size': 10
        },
        paint: {
            "text-color": "#ffffff"
        }
    };

    const unclusteredPointLayer = {
        id: 'unclustered-point',
        type: 'circle',
        source: 'opens',
        filter: ['!', ['has', 'point_count']],
        paint: {
            'circle-color': '#35A2CD',
            'circle-radius': 4
        }
    };


    const onMapClick = (e, ref) => {
        let zoom = e.target.transform.tileZoom + zoomStep <= maxZoom ? e.target.transform.tileZoom + maxZoom : zoomStep;

        if (zoom) {
            ref?.current?.flyTo({ center: [e.lngLat.lng, e.lngLat.lat], zoom: e.target.transform.tileZoom + zoomStep, curve: 1, duration: 1000 });
            setLng(e.lngLat.lng);
            setLat(e.lngLat.lat);
        }
    }

    const goTo = (location) => {
        mapRef?.current?.flyTo({ center: [location?.longitude, location?.latitude], zoom: maxZoom, curve: 1, duration: 2000 });
    }

    const LocationsMap = useMemo(() => {
        return <Map reuseMaps
            attributionControl={true}
            initialViewState={
                { longitude: lng, latitude: lat, zoom: zoom }
            }
            minZoom={zoomStep}
            maxZoom={maxZoom}
            maxPitch={0}
            minPitch={0}
            dragRotate={false}
            boxZoom={false}
            pitchWithRotate={false}
            touchZoomRotate={false}
            doubleClickZoom={false}
            interactiveLayerIds={["clusters"]}
            onClick={e => onMapClick(e, mapRef)}
            mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
            ref={mapRef}>
            <NavigateButton />
            <NavigationControl showCompass={false} position="bottom-right" />
            <Source
                id="opens"
                type="geojson"
                data={geoData}
                cluster={true}
                clusterProperties={{
                    sum: ["+", ["get", "opensCount", ["properties"]]],
                }}
                clusterMaxZoom={14}
                clusterRadius={50}
            >
                {geoData && <Layer {...clusterLayer} />}
                {geoData && <Layer {...clusterCountLayer} />}
                {geoData && <Layer {...unclusteredPointLayer} />}
            </Source>
        </Map>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoData, lat, lng])

    const LocationsMapFullscreen = useMemo(() => {
        return <Map reuseMaps
            attributionControl={true}
            initialViewState={
                { longitude: lng, latitude: lat, zoom: zoom }
            }
            minZoom={zoomStep}
            maxZoom={maxZoom}
            maxPitch={0}
            minPitch={0}
            dragRotate={false}
            boxZoom={false}
            pitchWithRotate={false}
            touchZoomRotate={false}
            doubleClickZoom={false}
            interactiveLayerIds={["clusters"]}
            onClick={e => onMapClick(e, fullscreenMapRef)}
            mapStyle={process.env.REACT_APP_MAPBOX_STYLE}
            ref={fullscreenMapRef}>
            <NavigateButton />
            <NavigationControl showCompass={false} position="bottom-right" />
            <Source
                id="opens"
                type="geojson"
                data={geoData}
                cluster={true}
                clusterProperties={{
                    sum: ["+", ["get", "opensCount", ["properties"]]],
                }}
                clusterMaxZoom={14}
                clusterRadius={50}
            >
                {geoData && <Layer {...clusterLayer} />}
                {geoData && <Layer {...clusterCountLayer} />}
                {geoData && <Layer {...unclusteredPointLayer} />}
            </Source>
        </Map>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoData, lat, lng])

    const onExportClick = () => {
        if (locations.length > 0) {
            csvLink.current.link.click();
        }
    }

    const escapeCommas = (data) => {
        return data.map((row) => {
            let newRow = { ...row };
            for (let key in newRow) {
                if (typeof newRow[key] === 'string' && newRow[key].includes(',')) {
                    newRow[key] = `"${newRow[key]}"`;
                }
            }
            return newRow;
        });
    };

    useEffect(() => {
        if (!containerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            mapRef?.current?.resize();
        });
        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect();
    }, []);

    return (
        <>
            <div className="row mb-md-4 mb-3" ref={ref}>
                <div className="col-12 d-flex justify-content-between flex-wrap">
                    {
                        !isLoading &&
                        <div className="d-flex align-items-center justify-content-between flex-grow-1">
                            <div>
                                <span className="fw-semi-bold c-black-100 text-truncate">
                                    {t('locations:opens_by_location')}
                                </span>
                                <img
                                    alt="info-circle"
                                    className="px-1 tooltip-anchor-opens-by-location"
                                    src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                                <Tooltip anchorSelect={`.tooltip-anchor-opens-by-location`} place={getTooltipLocation()}>
                                    {t('locations:opens_by_location_description')}
                                </Tooltip>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                {
                                    !isLoading && locations.length > 0 &&
                                    <button
                                        className="btn-transparent d-flex align-items-center"
                                        disabled={isLoading}
                                        onClick={() => onExportClick()}>
                                        <img
                                            alt="download"
                                            className={`pe-2`}
                                            style={{ width: 25 }}
                                            src={`${process.env.PUBLIC_URL}/images/icons/${isLoading ? 'loader' : 'download'}.svg`} />
                                        <span className="c-dark-blue d-none d-md-block">{t('common:export')}</span>
                                    </button>
                                }
                            </div>
                        </div>
                    }

                    {
                        isLoading &&
                        <div className="d-flex align-items-center w-25 placeholder-glow">
                            <span className="c-grey-300 w-75 border-radius placeholder">&nbsp;</span>
                            <img
                                alt="info-circle"
                                className="px-1 invisible"
                                src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                        </div>
                    }

                    <div className="d-flex justify-content-between align-items-center flex-grow-sm-1">

                        {(!isLoading && locations.length > 7) &&
                            <div className="d-flex p-2 px-0 px-md-2 w-100">
                                <input
                                    autoCorrect="off"
                                    autoComplete="off"
                                    spellCheck="false"
                                    type="text"
                                    style={{ width: 295 }}
                                    className="dcm-input"
                                    value={searchValue}
                                    name="search-input"
                                    onChange={(e) => setSearchValue(e.currentTarget.value)}
                                    placeholder={t('search_by_location')}
                                    aria-label="search" />
                                {
                                    searchValue &&
                                    <button type="button" className="btn bg-transparent p-0" style={{ marginLeft: "-25px", zIndex: 100 }}>
                                        <img
                                            alt="close"
                                            className="px-1"
                                            onClick={() => setSearchValue('')}
                                            src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="row">
                <div
                    ref={containerRef}
                    className="d-flex flex-column flex-lg-row">
                    <div className="col-12 col-lg-6 map-container">
                        {LocationsMap}
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="ps-lg-5">
                            {!isLoading && data?.length > 0 &&
                                <div className="row p-2 pe-3">
                                    <div className="col-7">
                                        <span className="fw-light">{t('location')}</span>
                                    </div>
                                    <div className="col-3">
                                        <span className="fw-light">{t('opens')}</span>
                                    </div>
                                    <div className="col-2">
                                        <span className="fw-light">%</span>
                                    </div>
                                </div>
                            }
                            {
                                isLoading &&
                                <div className="row p-2 pe-3">
                                    <div className="d-flex align-items-center col-7 placeholder-glow py-1 lh-100">
                                        <span className="c-grey-300 w-25 border-radius placeholder">&nbsp;</span>
                                    </div>
                                    <div className="d-flex align-items-center col-3 placeholder-glow py-1 lh-100">
                                        <span className="c-grey-300 w-75 border-radius placeholder">&nbsp;</span>
                                    </div>
                                    <div className="d-flex align-items-center col-2 placeholder-glow py-1 lh-100">
                                        <span className="c-grey-300 w-75 border-radius placeholder">&nbsp;</span>
                                    </div>
                                </div>
                            }
                            {!isLoading && data?.length > 0 && filteredLocations?.length > 0 &&
                                <div className="overflow-x-hidden overflow-y-scroll" style={{ height: 280 }}>
                                    {
                                        filteredLocations?.map((location, index) => (
                                            <div key={`'location-list-item-${index}'`} className="row border-top-grey-200 p-2 panel-item" onClick={() => goTo(location)}>
                                                <div className="col-7">
                                                    {location.name}
                                                </div>
                                                <div className="col-3">
                                                    {formatNumber(location.count)}
                                                </div>
                                                <div className="col-2">
                                                    {location.percent}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                !isLoading && data?.length > 0 && filteredLocations?.length === 0 &&
                                <div className="overflow-x-hidden overflow-y-hidden position-relative" style={{ height: 280 }}>
                                    {
                                        emptyLocations?.map((_, index) => (
                                            <div key={`'empty-location-${index}'`} className="row border-top-grey-200 py-2 my-1">
                                                <div className="col-7">
                                                    &nbsp;
                                                </div>
                                                <div className="col-3">
                                                    &nbsp;
                                                </div>
                                                <div className="col-2">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        ))
                                    }

                                    <div className="d-flex flex-column align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
                                        <img
                                            alt="no-results-found"
                                            src={`${process.env.PUBLIC_URL}/images/no-results.svg`} />
                                        <span className="d-block text-md py-2 fw-bold">{t("common:no_results")}</span>
                                        <span className="d-block text-md fw-light px-2">{t("common:no_results_disclaimer_1")}</span>
                                        <span className="d-block text-md fw-light px-2">{t("common:no_results_disclaimer_2")}</span>
                                    </div>
                                </div>
                            }

                            {
                                isLoading &&
                                <div className="overflow-x-hidden overflow-y-hidden" style={{ height: 280 }}>
                                    {
                                        emptyLocations?.map((_, index) => (
                                            <div key={`'loading-location-${index}'`} className="row border-top-grey-200 py-2 my-1 lh-100">
                                                <div className="d-flex align-items-center col-7 placeholder-glow py-1">
                                                    <span className={`c-grey-300 ${index % 2 === 0 ? 'w-50' : 'w-25'} border-radius placeholder`}>&nbsp;</span>
                                                </div>
                                                <div className="d-flex align-items-center col-3 placeholder-glow py-1">
                                                    <span className={`c-grey-300 ${index % 2 === 0 ? 'w-50' : 'w-75'} border-radius placeholder`}>&nbsp;</span>
                                                </div>
                                                <div className="d-flex align-items-center col-2 placeholder-glow py-1">
                                                    <span className={`c-grey-300 ${index % 2 === 0 ? 'w-100' : 'w-75'} border-radius placeholder`}>&nbsp;</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>

                        {
                            !isLoading && data?.length === 0 &&
                            <div className="position-relative" style={{ height: '278px' }}>
                                <div
                                    style={{ top: '50%' }}
                                    className="d-flex flex-column align-items-center justify-content-center position-absolute start-50 translate-middle">
                                    <img
                                        alt="no-results-found"
                                        src={`${process.env.PUBLIC_URL}/images/empty/map.svg`} />
                                    <span className="d-block text-md py-2 fw-bold">{t("common:no_info")}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <Modal show={modalOpen} onHide={() => setModalOpen(false)} dialogClassName={"primaryModal"}>
                        <Modal.Body>
                            <div>
                                <div className="d-flex mb-4 justify-content-between">
                                    <span className="fw-semi-bold c-black-100 text-truncate">
                                        {t('locations:opens_by_location')}
                                        <img
                                            alt="info-circle"
                                            className="px-1"
                                            src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                                    </span>

                                    <div className="cursor-pointer"
                                        onClick={() => setModalOpen(false)}>
                                        <img alt="selected"
                                            src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                                            style={{ height: 24, width: 24 }} />
                                    </div>
                                </div>

                            </div>
                            <div id="map-fullscreen" style={{ height: '80vh' }} className="w-100">
                                {LocationsMapFullscreen}
                            </div>
                        </Modal.Body>
                    </Modal>

                    <CSVLink
                        data={escapeCommas(locations)}
                        filename={`${t('locations:opens_by_location')} ${fileName}.csv`}
                        className="hidden"
                        ref={csvLink}
                        headers={headers}
                        enclosingCharacter=""
                        target="_blank" />
                </div>
            </div>
        </>
    );
}

export default Locations;