import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import Arrow from "../atoms/Arrow";

const SelectDropdown = ({ options, selectedOption, setSelectedOption }) => {

    const { t } = useTranslation('common');

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectOption = (option) => {
        if (option.value !== selectedOption?.value) {
            setSelectedOption(option);
        }

        setIsOpen(false);
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-end mb-2">
            {selectedOption &&
                <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
                    <div className="dropdown-toggle c-black-100 fw-bold px-2" onClick={() => setIsOpen(!isOpen)}>
                        <span className="pe-2">{t(selectedOption?.label)}</span>
                        <Arrow turnOn={isOpen} />
                    </div>
                    <ul className="dropdown-menu px-1 py-1">
                        {options.map((option) => (
                            <li
                                key={option.value}
                                className={`my-2 me-3 dropdown-item px-2 ${selectedOption?.value === option.value ? 'active' : ''}`}
                                onClick={() => selectOption(option)}>
                                <div className="row g-0 px-1">
                                    <div className="col-9 c-black-100">
                                        {t(option.label)}
                                    </div>
                                    <div className="col-3 d-flex align-items-center justify-content-end">
                                        {selectedOption?.value === option.value &&
                                            <img
                                                alt="selected"
                                                style={{ height: 16, width: 16 }}
                                                src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                        }
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </div>
    );
}

export default SelectDropdown;