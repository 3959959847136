export function formatNumber(value) {
    return (value > 999 ? (value).toLocaleString('en-US') : value);
}

export function formatDecimal(num, decimalPlaces) {
    if (num < 0.01) {
        num = 0.01;
    }
    return num.toFixed(decimalPlaces);
}

export default formatNumber;