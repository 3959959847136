const OpenedLinksTableSkeleton = () => {
    return (<div style={{ height: 370 }} className="pe-3">
        <div className="placeholder-glow mb-4">
            <span className="placeholder c-grey-300 rounded-4 w-50 mb-2"></span>
        </div>
        <div className="row align-items-center">
            <div className="col-6 placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 w-100 px-3 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3 text-end pe-2">
                <div className="placeholder c-grey-300 rounded-4 w-75 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 w-75 px-3 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3 text-end pe-2">
                <div className="placeholder c-grey-300 rounded-4 w-50 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 w-100 px-3 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3 text-end pe-2">
                <div className="placeholder c-grey-300 rounded-4 w-75 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 w-75 px-3 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3 text-end pe-2">
                <div className="placeholder c-grey-300 rounded-4 w-50 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 w-100 px-3 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3 text-end pe-2">
                <div className="placeholder c-grey-300 rounded-4 w-75 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 w-75 px-3 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3 text-end pe-2">
                <div className="placeholder c-grey-300 rounded-4 w-50 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3">
                <div className="placeholder c-grey-300 rounded-4 w-100 px-3 mb-2"></div>
            </div>
            <div className="col-6 placeholder-glow mb-3 text-end pe-2">
                <div className="placeholder c-grey-300 rounded-4 w-75 mb-2"></div>
            </div>
        </div>
    </div>);
}

export default OpenedLinksTableSkeleton;