import { useContext } from 'react';
import { WarningContext } from '../../contexts/WarningContext';

const WarningBanner = () => {
    const [state,] = useContext(WarningContext);

    return (<>
        {state.show &&
            <div style={{ backgroundColor: state.bc_color }}
                className="p-3 d-flex justify-content-between align-items-center cursor-pointer"
                onClick={() => state.close()}>
                <div className="c-dark-red">
                    {state.message}
                </div>
                <div className="cursor-pointer"
                    onClick={() => state.close()}>
                    <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                </div>
            </div>
        }
    </>
    );
}

export default WarningBanner;