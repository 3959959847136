import { BrowserRouter, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { useState } from "react";
import i18n from "./core/i18n";

import Header from "./core/Header";
import EmailsReportingLayoutPage from "./pages/Dashboard/EmailsReportingLayoutPage";
import NotFoundPage from "./pages/Secure/NotFoundPage";
import ServerErrorPage from "./pages/Secure/ServerErrorPage";
import ForbiddenPage from "./pages/Secure/ForbiddenPage";
import UnauthorizedPage from "./pages/Secure/UnauthorizedPage";
import SessionExpiredPage from "./pages/Secure/SessionExpiredPage";
import VideoReportingLayoutPage from "./pages/VideoReporting/VideoReportingLayoutPage";
import AuthRoute from "./guards/AuthRoute";
import LandingReportingLayoutPage from "./pages/LandingReporting/LandingReportingLayoutPage";
import { UniqueRecipientsContextProvider } from "./contexts/UniqueRecipientsContextProvider";

import { WarningContextProvider } from "./contexts/WarningContext";
import LogoutPage from "./pages/Secure/LogoutPage";

function App() {
  const [showLoading, setShowLoading] = useState(true);
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <UniqueRecipientsContextProvider>
          <WarningContextProvider>
            <div className="app">
              <div className="header border-bottom header-height">
                <Header showLoading={showLoading}></Header>
              </div>
              <div className="main bc-grey-200">
                <Routes>
                  <Route path="/forbidden" element={<ForbiddenPage />} />
                  <Route path="/secure" element={<UnauthorizedPage />} />
                  <Route path="/server-error" element={<ServerErrorPage />} />
                  <Route path="/session-expired" element={<SessionExpiredPage />} />
                  <Route path="/logout" element={<LogoutPage />} />
                  <Route path="/" element={<AuthRoute />}>
                    <Route path="/video-reporting" element={<VideoReportingLayoutPage />} />
                    <Route path="/web-reporting" element={<LandingReportingLayoutPage />} />
                    <Route path="/" element={<EmailsReportingLayoutPage showLoading={showLoading} setShowLoading={setShowLoading}/>} />
                  </Route>
                  <Route path="*" exact element={<NotFoundPage />} />
                </Routes>
              </div>
            </div>
          </WarningContextProvider>
        </UniqueRecipientsContextProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
