import axios from "axios";
import qs from 'qs';

function AccountsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const get = (isLanding = false) => axios
        .get('/accounts', {
            params: {
                landing: isLanding
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        }, config())
        .catch(error => error);

    return {
        init,
        dispose,
        get
    };
}

export default AccountsService;