import { useTranslation } from "react-i18next";
import BarChart from "../../../components/organisms/BarChart";

import {defaultState, defaultStateMobile} from "../../../helpers/constants/barChartConstants";

const ViewProgressChart = ({ stats, isLoading, exportFile, currentRange }) => {

    const { t } = useTranslation('video');

    const getFilteredData = () => {
        let filteredStats = [];
        filteredStats.push(stats.at(0));
        filteredStats.push(stats.at(2));
        filteredStats.push(stats.at(5));
        filteredStats.push(stats.at(9));
        return window.innerWidth < 768 ? filteredStats : stats;
    }

    const getDefaultState = () => {
        return window.innerWidth < 768 ? defaultStateMobile : defaultState;
    }

    return (
        <>
            {
                <BarChart
                    headerText={t('view_progress')}
                    data={stats ? getFilteredData() : getDefaultState()}
                    index="percent"
                    keys={['views', 'uniqueViews']}
                    colors={['#f4d37e', '#497aa5']}
                    name="view-progress"
                    description={t('view_progress_description')}
                    tooltipDescription={t('progress')}
                    isLoading={isLoading}
                    exportFile={exportFile}
                    currentRange={currentRange} />
            }
        </>
    );
}

export default ViewProgressChart;