import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import LandingTab from "./components/LandingTab";
import LandingSearchPanel from "./components/LandingSearchPanel";

import LandingService from "../../services/landing-service";
import AccountsService from "../../services/accounts-service";
import CampaignsService from "../../services/campaigns-service";

import Footer from "../../core/Footer";
import DateRange from "../../components/atoms/DateRange";
import WarningBanner from "../../components/atoms/WarningBanner";
import DateSelectDropdown from "../../components/molecules/DateSelectDropdown";

import { LANDING_REPORTING_DATA_KEY } from "../../helpers/constants/constants";
import extendedDateOptions from "../../helpers/constants/extendedDateOptions";

const accountsService = AccountsService();
const campaignsService = CampaignsService();
const landingService = LandingService();
const LandingReportingLayoutPage = () => {

    const { t } = useTranslation('common');

    const [accounts, setAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState(accounts);
    const [selectedAccount, setSelectedAccount] = useState();

    const [showPanel, setShowPanel] = useState(true);

    const [selectedOption, setSelectedOption] = useState();
    const [searchPanelLoading, setSearchPanelLoading] = useState(true);

    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState();

    const [landings, setLandings] = useState([]);

    const [selectedLanding, setSelectedLanding] = useState();
    const [dateRange, setDateRange] = useState([]);
    const [isDateRangeLoading, setIsDateRangeLoading] = useState(true);

    useEffect(() => {
        accountsService.init();
        campaignsService.init();
        landingService.init();

        let prevOptions = window.localStorage.getItem(LANDING_REPORTING_DATA_KEY);
        if (prevOptions) {
            prevOptions = JSON.parse(prevOptions);
            setSelectedOption(prevOptions?.interval);
        } else setSelectedOption(extendedDateOptions[2]);

        Promise.all([
            accountsService.get(true),
            campaignsService.get(true),
        ]).then(
            ([
                accounts,
                campaigns
            ]) => {
                if (accounts?.data) {
                    setAccounts(accounts.data);
                    setFilteredAccounts(accounts.data);
                }

                if (accounts?.data && campaigns?.data) {
                    setCampaigns(campaigns.data);
                    landingService.get(campaigns.data.map(campaign => campaign.id))
                        .then((response) => setLandings(response?.data));
                    if (prevOptions) {
                        setSelectedCampaign(prevOptions.campaign);
                        setSelectedLanding(prevOptions.landing);
                        setSelectedAccount(prevOptions.account);
                    }
                }
            }).finally(() => setTimeout(() => setSearchPanelLoading(false), 500));

        return () => {
            accountsService.dispose();
            campaignsService.dispose();
            landingService.dispose();
        };
    }, []);

    useEffect(() => {
        if (!selectedLanding || !selectedOption) return;
        window.localStorage.setItem(LANDING_REPORTING_DATA_KEY,
            JSON.stringify({
                account: selectedAccount,
                campaign: selectedCampaign,
                landing: selectedLanding,
                interval: selectedOption
            }));
        setDateRange([]);
        // eslint-disable-next-line
    }, [selectedLanding, selectedOption]);

    const onSearch = (value) => {
        const filtered = accounts.filter(function (accounts) {
            return accounts?.name?.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredAccounts([...filtered]);
    }

    return (
        <div className="d-flex align-items-start">
            <div className={`overlay bc-white ${showPanel ? '' : 'd-none'}`}>
                <div className="height-100 px-2">
                    <LandingSearchPanel
                        isLoading={searchPanelLoading}
                        filteredAccounts={filteredAccounts}
                        selectedAccount={selectedAccount}
                        campaigns={campaigns}
                        setSelectedCampaign={setSelectedCampaign}
                        selectedCampaign={selectedCampaign}
                        landings={landings}
                        selectedLanding={selectedLanding}
                        setSelectedLanding={setSelectedLanding}
                        toggle={setShowPanel}
                        setSelectedAccount={setSelectedAccount}
                        onSearch={onSearch}
                    />
                </div>
            </div>
            <div className="w-100 height-md-100 d-flex flex-column" style={{ overflowY: "auto", overflowX: "hidden" }}>
                <div className="container-fluid bc-grey-200 flex-grow-1 mb-4 px-4 page-max-width">
                    <div className="row">
                        <div className="col-12"><WarningBanner /></div>
                        <div className="col-8 pt-3">
                            <button className="bc-grey-200 border-0 d-flex align-items-center"
                                style={{ height: 31 }}
                                onClick={() => setShowPanel(!showPanel)}>
                                <img alt="menu" src={`${process.env.PUBLIC_URL}/images/icons/menu.svg`} />
                                <span className="d-none c-dark-blue fw-semi-bold d-md-inline ps-2">
                                    {selectedLanding ? selectedLanding?.name : t("landing_page_name")}
                                </span>
                                <DateRange dateRange={dateRange} isLoading={isDateRangeLoading} />
                            </button>
                        </div>
                        <div className="col-4 pt-3">
                            <DateSelectDropdown
                                dateOptions={extendedDateOptions}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption} />
                        </div>
                        <div className="col-12">
                            <LandingTab
                                selectedLanding={selectedLanding}
                                interval={selectedOption}
                                dateRange={dateRange}
                                setDateRange={setDateRange}
                                setIsDateRangeLoading={setIsDateRangeLoading}
                            />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default LandingReportingLayoutPage;